import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import T from "../../Helpers/Translations";
import $ from "jquery";

class SideMenu extends Component {
  state = { process: true };

  renderLoader() {
    setTimeout(
      function () {
        this.setState({ process: false });
      }.bind(this),
      3000
    );
    // this.setState({process:false})
    return (
      <div className="the-loader">
        <Loader
          type="Triangle"
          color="#00adb4"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  }

  render() {
    let lang = $("html").attr("lang").toUpperCase();
    let t = T[0];

    let cc = this.state.process ? "hide-it" : "app-sidebar__inner";

    return (
      <div id="side-bar" className="app-sidebar sidebar-shadow">
        <div className="app-header__logo">
          <div className="logo-src"></div>
          <div className="header__pane ml-auto">
            <div>
              <button
                type="button"
                className="hamburger close-sidebar-btn hamburger--elastic"
                data-class="closed-sidebar"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button
              type="button"
              className="hamburger hamburger--elastic mobile-toggle-nav"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
        <div className="app-header__menu">
          <span>
            <button
              type="button"
              className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
            >
              <span className="btn-icon-wrapper">
                <i className="fa fa-ellipsis-v fa-w-6"></i>
              </span>
            </button>
          </span>
        </div>
        <div className="scrollbar-sidebar">
          {this.state.process ? this.renderLoader() : ""}
          <div className={cc}>
            <ul className="vertical-nav-menu">
              <li className="app-sidebar__heading">
                <Link to={"/dashboard"}>
                  <i className="metismenu-icon pe-7s-display1"></i>
                  {t.Dashboard[lang]}
                </Link>
              </li>
              <li>
                <Link to="#" onClick={(e) => e.preventDefault()}>
                  <i className="metismenu-icon pe-7s-user"></i>
                  {t.Profile[lang]}
                  <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                </Link>
                <ul>
                  <li>
                    <Link to={"/profile"}>
                      <i className="metismenu-icon"></i>
                      {t.ManageProfile[lang]}
                    </Link>
                  </li>
                  {/* <li>
                                <Link to="/payment">
                                    <i className="metismenu-icon">
                                    </i>{t.Payment[lang]}
                                </Link>
                            </li>
                            <li>
                                <Link to="/subcriptions">
                                    <i className="metismenu-icon">
                                    </i>{t.Subscriptions[lang]}
                                </Link>
                            </li> */}
                </ul>
              </li>
              <li>
                <Link to={"/cards"}>
                  <i className="metismenu-icon pe-7s-albums"></i>
                  {t.ManageCards[lang]}
                  <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                </Link>
                <ul>
                  <li>
                    <Link to={"/create-card"}>
                      <i className="metismenu-icon"></i>
                      {t.CreateNewCard[lang]}
                    </Link>
                  </li>
                  <li>
                    <Link to={"/cards"}>
                      <i className="metismenu-icon"></i>
                      {t.ViewAllCards[lang]}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default SideMenu;
