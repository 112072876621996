import Layout from './Containers/Layout';
import Dashboard from './Components/Dashboard';
import Logout from './Components/Auth/Logout';

// import LostPassword from './Components/Auth/LostPassword';
// import Users from './Components/User/Users';
// import EditUser from './Components/User/EditUser';
// import UserDetail from './Components/User/UserDetail';
// import CreateUser from './Components/User/CreateUser';
// import Profile from './Components/User/UserProfile';

import Cards from './Components/Card/Cards';
import Profile from './Components/User/Profile';
import Payment from './Components/User/Payment';
import Subcriptions from './Components/User/Subcriptions';
import CreateCard from './Components/Card/CreateCard';
import EditCard from './Components/Card/EditCard';
import ViewCard from './Components/CardView/Index';
import Cardsurvey from './Components/Cardsurvey';
// import Config from './Components/Configuration/Config';


export default [
  
  { path: "/", exact: true, name: "Home", Component: Layout },
  { path: "/dashboard", name: "Dashboard", Component: Dashboard },
  { path: "/logout", name: "Logout", Component: Logout },

  // { path: "/users", name: "Users", Component: Users },
  // { path: "/user/create", name: "CreateUser", Component: CreateUser },
  // { path: "/user/edit", name: "EditUser", Component: EditUser },
  // { path: "/user/detail/", name: "UserDetail", Component: UserDetail },

  { path: "/cards", name: "Cards", Component: Cards },
  {path: "/card-survey/:slug", name: "Cardssurvey", Component: Cardsurvey },
  { path: "/profile", name: "Profile", Component: Profile },
  { path: "/payment", name: "Edit Payment", Component: Payment },
  { path: "/subcriptions", name: "Profile", Component: Subcriptions },
  { path: "/create-card", name: "CreateCard", Component: CreateCard },
  { path: "/edit-card/:cardid", name: "EditCard", Component: EditCard },
  { path: "/:slug", name: "ViewCard", Component: ViewCard },
   
  // { path: "/my-profile", name: "Profile", Component: Profile },
  // { path: "/configuration", name: "Config", Component: Config },
];