import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import APIModel from "../../Models/APIModel";
import alertify from "alertifyjs";
import T from "../../Helpers/Translations";
import countries from "../../Store/countries";

import * as actionCreater from "../../Store/Actions/UserActions";
import { connect } from "react-redux";

const data = [
  { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
  { name: "Page C", uv: 2000, pv: 6800, amt: 2290 },
  { name: "Page D", uv: 4780, pv: 7908, amt: 2000 },
  { name: "Page E", uv: 2890, pv: 9800, amt: 2181 },
  { name: "Page F", uv: 1390, pv: 3800, amt: 1500 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 }
];

const data2 = [
  { name: "Page A", uv: 5400, pv: 5240, amt: 1240 },
  { name: "Page B", uv: 7300, pv: 4139, amt: 3221 },
  { name: "Page C", uv: 8200, pv: 7980, amt: 5229 },
  { name: "Page D", uv: 6278, pv: 4390, amt: 3200 },
  { name: "Page E", uv: 3189, pv: 7480, amt: 6218 },
  { name: "Page D", uv: 9478, pv: 6790, amt: 2200 },
  { name: "Page E", uv: 1289, pv: 1980, amt: 7218 },
  { name: "Page F", uv: 3139, pv: 2380, amt: 5150 },
  { name: "Page G", uv: 5349, pv: 3430, amt: 3210 }
];

class Subcriptions extends Component {
  constructor() {
    super();
    this.inputRefs = {};
    this.state = {
      coupon_code: "",
      choosen_coupon: "",
      language: "",
      currencySymbol: "",
      token: "",
      monthly: "",
      yearly: "",
      plan: "",
      host: APIModel.HOST,
      nextstep: false,
      coupons: [],
      coupon_data: {},
      selectedIlCountry: false
    };
  }

  sendCoupon = async () => {
    if (this.state.plan == "" && this.state.coupon_code == "") {
      let lang = $("html")
        .attr("lang")
        .toUpperCase();
      if (lang == "EN") {
        alertify.error("Please Select a Plan First");
        return false;
      }
      if (lang == "HE") {
        alertify.error("לא בחרת עדיין תוכנית");
        return false;
      }
      if (lang == "AR") {
        alertify.error("لم تحدد أي خطة بعد.");
        return false;
      }
    }

    // if (this.state.coupon_code != "") {
    //   const c = this.c_code();
    //   if (c == 0) {
    //     alertify.error("Invalid Coupon Code");
    //     return false;
    //   }
    // }

    if (this.state.choosen_coupon) {
      await this.getCouponDetails(this.state.choosen_coupon)
    }

    this.nextStep(this.state.choosen_coupon)
  };

  nextStep = (coupon_code) => {



    let lang = $("html")
      .attr("lang")
      .toUpperCase();

    let kup = 0;
    this.state.coupons.map((obj, i) => {

      if (coupon_code == obj.random_number) {
        kup = this.state.coupons[i];
      }
    });
    if (kup != 0) {
      this.setState({ coupon_data: kup });
      let coupon = kup

      if (coupon.price == 0) {
        return this.activateCard(coupon_code, coupon.type)
      } else {
        this.setState({ nextstep: true })

        this.onChangeTwoStep(false)

        setTimeout(() => {
          document.getElementById('nextstep').scrollIntoView({ behavior: 'smooth' })
        }, 500)
      }
    }
    else {
      console.log("Coupon Is null")
      this.setState({ nextstep: true })

      this.onChangeTwoStep(false)

      setTimeout(() => {
        document.getElementById('nextstep').scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }

  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<div>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  continuePay = () => {
    let lang = $("html")
      .attr("lang")
      .toUpperCase();

    let userPay = {}
    userPay.email = this.state.userpay_email;
    userPay.phone = this.state.userpay_phone;
    userPay.name = this.state.userpay_name;
    userPay.company = this.state.userpay_company;
    userPay.country = this.state.userpay_country;

    if (userPay["email"] && userPay["phone"] && userPay["name"] && userPay["country"]) {

      if (this.validateEmail(userPay["email"])) {
        localStorage.setItem(
          "payUser",
          JSON.stringify(userPay)
        );

        this.props.history.push("/payment/" + this.state.choosen_coupon);
      } else {
        if (lang == "EN") {
          alertify.error("Email is badly format");
        } else if (lang == "HE") {
          alertify.error('הדוא"ל הוא בפורמט רע');
        } else if (lang == "AR") {
          alertify.error("تنسيق البريد الإلكتروني سيء");
        }
      }
    } else {
      if (lang == "EN") {
        alertify.error("Fill in the fields marked *");
      } else if (lang == "HE") {
        alertify.error("מלא את השדות המסומנים *");
      } else if (lang == "AR") {
        alertify.error("املأ الحقول المميزة *");
      }
    }
  }

  getCouponDetails = async (coupon_no) => {
    let lang = $("html")
      .attr("lang")
      .toUpperCase();

    let kup = 0;
    this.state.coupons.map((obj, i) => {

      if (coupon_no == obj.random_number) {
        kup = this.state.coupons[i];
      }
    });

    if (kup != 0) {
      this.setState({ coupon_data: kup });
      let coupon = kup
      let expired = Date.parse(coupon.expiry_date);
      let today = Date.now();

      if (coupon.user_limits && coupon.total_usage == coupon.user_limits) {
        if (lang == "EN") {
          alertify.error("Coupon has reached its usage limit");
        } else if (lang == "HE") {
          alertify.error("לא בחרת עדיין תוכניתהקופון הגיע למגבלת השימוש בו");
        } else if (lang == "AR") {
          alertify.error("وصلت القسيمة إلى حد استخدامها");
        }
        document.getElementById("coupon_code").value = ""
        return false;
      } else if (expired < today) {
        if (lang == "EN") {
          alertify.error("Coupon has bin expired");
        } else if (lang == "HE") {
          alertify.error("פג תוקפו של הקופון");
        } else if (lang == "AR") {
          alertify.error("القسيمة انتهت صلاحيتها");
        }
        // document.getElementById("coupon_code").value = ""
        return false;
      }

      return kup
    } else {
      if (lang == "EN") {
        alertify.error("Invalid Coupon code");
      } else if (lang == "HE") {
        alertify.error("קוד שובר לא חוקי");
      } else if (lang == "AR") {
        alertify.error("رقم قسيمه غير صالح");
      }
      // document.getElementById("coupon_code").value = ""
      return false;
    }
  };

  activateCard = (coupon_no, type) => {
    let card_activate = localStorage.getItem("card_activate");
    let card = JSON.parse(card_activate);
    let card_id = card

    let lang = $("html")
      .attr("lang")
      .toUpperCase();
    return axios
      .post(this.state.host + "admin/payment/pay/create?card_id=" + card_id + "&coupon_no=" + coupon_no + "&subs=" + type, {}, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + this.state.token
        }
      })
      .then((response) => {
        // let message = response.data.message;
        if (lang == "EN") {
          alertify.success("Card is activated successfully");
        }
        if (lang == "HE") {
          alertify.success("הכרטיס הופעל בהצלחה");
        }
        if (lang == "AR") {
          alertify.success("تم تفعيل البطاقة بنجاح");
        }
        // alertify.success(message);
        let easycardUser = localStorage.getItem("easycardUser");
        let userProfile = JSON.parse(easycardUser);
        let currentBalance = userProfile["amount"];
        userProfile["amount"] = this.state.coupon_data.price;

        localStorage.setItem(
          "easycardUser",
          JSON.stringify(userProfile)
        );
        localStorage.removeItem("card_activate");
        this.props.history.push("/cards");
      })
      .catch((error) => this.globalErrorHandler(error, true))
      .finally();
  }

  globalErrorHandler = (error) => {
    const { response } = error;
    let message;
    if (response) {
      message = response.data.message;
    } else {
      message = error.toString();
    }
    alertify.error(message);
  };

  c_code = () => {
    let k = 0;
    this.state.coupons.map((obj, i) => {

      if (this.state.coupon_code == obj.random_number) {
        k = 1;
      }
    });

    return k;
  };

  checkCoupon = async (e) => {
    e.preventDefault()
    let couponInfo = await this.getCouponDetails(this.state.coupon_code)

    if (couponInfo) {
      this.setState({
        couponData: couponInfo,
        choosen_coupon: this.state.coupon_code,
      });

      let el = { target: { id: 0 } }
      el.target.id = couponInfo.type
      this.selected(el)
      this.onChangeTwoStep(false)
    }
  }

  onChangeTwoStep = async (e) => {
    let target = {}
    let value

    if (e != false) {
      target = e.target
      value = target.value
    } else {
      value = this.state.userpay_country
      target.name = "userpay_country"
    }

    if (target.name == "userpay_phone") {
      value = target.value.replace(/\D+/g, "")
    }

    if (target.name == "userpay_country") {
      let stat = 0
      let sumToPay = 0
      let vatILn = 0
      let vatIL = 0
      let priceSub = localStorage.getItem('priceSub')
      sumToPay = this.state.coupon_data.price ? this.state.coupon_data.price : priceSub

      if (value == "Israel") {
        vatILn = ((parseFloat(sumToPay) / 100) * 17)
        vatIL = parseFloat(vatILn.toFixed(1))
        stat = vatIL
      }

      this.setState({
        userpay_vat_show: true,
        userpay_vat_bill: stat,
        userpay_vat_total: sumToPay,
      })
    }

    this.setState({
      [target.name]: value
    });
  }

  onChange = async (e) => {
    let target = e.target

    this.setState({
      [target.name]: target.value.trim()
    });

    // if(target.value.length==8) {
    //   let couponInfo = await this.getCouponDetails(target.value)

    //   if(couponInfo) {
    //     this.setState({
    //       [target.name]: target.value,
    //       couponData: couponInfo,
    //     });

    //     let el = {target: {id:0}}
    //     el.target.id = couponInfo.type
    //     this.selected(el)
    //   }
    // } else {
    //   this.setState({
    //     [target.name]: target.value
    //   });
    // }
  };

  selected = e => {
    let lang = $("html")
      .attr("lang")
      .toUpperCase();
    $(".noPlan").css("display", "none");
    $(".selectplan").css("display", "block");
    if (lang == "EN") {
      if (e.target.id == 'yearly') {
        $(".selectplan").html("Your selected plan is :  Annually");
      }
      else {
        $(".selectplan").html("Your selected plan is : " + e.target.id);
      }
    }

    if (lang == "HE") {
      if (e.target.id == 'yearly') {
        $(".selectplan").html("החבילה שבחרת: שנתית");
      }
      else {
        $(".selectplan").html("החבילה שבחרת:חודשית");
      }
    }
    if (lang == "AR") {
      if (e.target.id == 'yearly') {
        $(".selectplan").html("خطتك المختارة هي: سنويا");
      }
      else {
        $(".selectplan").html("خطتك المختارة هي: شهريا");
      }
    }
    localStorage.removeItem('sub');
    localStorage.setItem('sub', e.target.id)

    let priceSub
    if (e.target.id == 'yearly') {
      priceSub = this.state.yearly
    } else {
      priceSub = this.state.monthly
    }
    localStorage.setItem("priceSub", priceSub)

    this.setState({ plan: e.target.id });
    let id = e.target.id;
    //debugger;
    $(".subscriptionText").css("color", "#44cbc5");
    $(".card-header").css("background-color", "#44cbc5");
    $("." + id + "Heading").css("background-color", "#20badc");
    $("." + id + "Text").css("color", "#2184ff");
    if (id == 'monthly') {
      $('#cardmonthly').css("border", "2px dashed", "important");
      $('#card-yearly').css("border", "none");
    }
    else if (id == 'yearly'){
      $('#card-yearly').css("border", "2px dashed", "important");
      $('#cardmonthly').css("border", "none");
    }

  };

  componentDidMount() {
    this.getpackagedetails();
    this.getCoupons();
    alertify.set("notifier", "position", "top-right");

    this.setState({
      userpay_email: this.props.user.email,
      userpay_phone: this.props.user.phone,
      userpay_name: this.props.user.name,
      userpay_company: "",
      userpay_country: "",
    })

    if (this.props.user.language == "HE") {
      this.setState({ selectedIlCountry: true, userpay_country: "Israel" })
    }
  }

  componentWillMount() {
    let lan = this.props.user.language;
    let t = this.props.user.auth.access_token;

    this.setState({ language: lan });
    this.setState({ token: t });
  }
  currencySymbol(lan) {
    if (lan == "English") {
      return "$";
    }
    if (lan == "Hebrew") {
      return 'ש"ח';
    }
    if (lan == "Arabic") {
      return "$";
    }
  }

  getCoupons() {
    axios
      .get(APIModel.HOST + "admin/payment/coupon", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + this.state.token
        }
      })
      .then(response => {
        this.setState({ coupons: response.data.data });
      });
  }

  getpackagedetails() {
    let language;
    if (this.state.language == "EN") {
      language = "English";
    }
    if (this.state.language == "HE") {
      language = "Hebrew";
    }
    if (this.state.language == "AR") {
      language = "Arabic";
    }
    axios
      .post(
        APIModel.HOST +
        "admin/payment/multidata/package/getpackages?column_name=language&value=" +
        language,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + this.state.token
          }
        }
      )
      .then(response => {
        if (response.data.data.length == 0) {
          let z = this.currencySymbol(language);
          this.setState({ monthly: 0 });
          this.setState({ yearly: 0 });
          this.setState({ currencySymbol: z });
        } else {
          let z = this.currencySymbol(language);
          this.setState({ monthly: response.data.data[1].price });
          this.setState({ yearly: response.data.data[0].price });
          this.setState({ currencySymbol: z });
        }
      });
  }

  render() {
    let context = this
    let lang = $("html")
      .attr("lang")
      .toUpperCase();
    let t = T[0];

    return (
      <div className="card">
        <div className="card-body">
          <h4 className="text-center">{t.Selectaplan[lang]}</h4>
          <br />
          <Row className="text-center justify-content-center">
            <Col md="4">
              <div className="card" id="cardmonthly">
                {/* <div className="card-header d-block p-2 subcription-head-eng text-white montlyHeading"> */}
                <div className="card-header d-block p-2 subcription-head-heb text-white monthlyHeading">
                  <h3>{t.monthly[lang]}</h3>
                </div>
                <div className="card-body subscriptionText subcription-body-heb monthlyText">
                  <h1 className="subcription-pkg m-0">
                    {lang == "EN" ? <sub style={{ float: "right", top: "50px", right: "85px" }}>{this.state.currencySymbol}</sub> : " "}
                    {lang == "HE" ? <sub style={{ float: "left", top: "50px", left: "70px" }}>{this.state.currencySymbol}</sub> : " "}
                    {lang == "AR" ? <sub style={{ float: "right", top: "50px", right: "85px" }}>{this.state.currencySymbol}</sub> : " "}

                    {this.state.monthly}
                  </h1>
                  <p>/{t.permonth[lang]}</p>
                </div>
                <div className="card-footer d-block">
                  <button className="btn btn-success p-1 w-50">
                    <h5 onClick={this.selected} id="monthly" className="selectBtn">
                      {t.Select[lang]}
                    </h5>
                  </button>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="card card-yearly">
                <div className="card-header d-block p-2 subcription-head-heb text-white yearlyHeading">
                  <h3>{t.annually[lang]}</h3>
                </div>
                <div className="card-body subscriptionText subcription-body-heb yearlyText">
                  <h1 className="subcription-pkg m-0">
                    {lang == "EN" ? <sub style={{ float: "right", top: "50px", right: "85px" }}>{this.state.currencySymbol}</sub> : " "}
                    {lang == "HE" ? <sub style={{ float: "left", top: "50px", left: "70px" }}>{this.state.currencySymbol}</sub> : " "}
                    {lang == "AR" ? <sub style={{ float: "right", top: "50px", right: "85px" }}>{this.state.currencySymbol}</sub> : " "}
                    {this.state.yearly}
                  </h1>
                  <p>/{t.peryear[lang]}</p>
                </div>
                <div className="card-footer d-block">
                  <button className="btn btn-success p-1 w-50">
                    <h5 onClick={this.selected} id="yearly" className="selectBtn">
                      {t.Select[lang]}
                    </h5>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="col-md-10 offset-md-2">
              <div>
                <br />
                <h5 className="noPlan">{t.You[lang]}</h5>
                <h5 className="selectplan" style={{ display: "none" }}>
                  {" "}
                </h5>
              </div>
            </div>
          </Row>
          <Row>
            <Col>
              <form method="POST" action="">
                <div className="form-group mt-5">
                  <div className="col-md-10  offset-md-2">
                    <div>
                      <h4 className="d-inline mr-3">{t.EnterCouponCode[lang]}</h4>
                      <input
                        type="text"
                        data-tip="Required"
                        onChange={this.onChange}
                        value={this.state.coupon_code}
                        name="coupon_code"
                        id="coupon_code"
                        placeholder={t.EnterCouponCode[lang]}
                        className="form-control col-md-4 d-inline"
                      />
                      <button
                        className="btn btn-success btn-lg"
                        style={{ marginTop: -5, marginLeft: 10 }}
                        onClick={this.checkCoupon}
                      >
                        {lang == "EN" && "Apply"}
                        {lang == "HE" && "להגיש מועמדות"}
                        {lang == "AR" && "تطبيق"}
                      </button>
                      {this.state.couponData &&
                        <div>
                          {lang == "EN" && "Your price is"}{lang == "HE" && "המחיר שלך הוא"}{lang == "AR" && "سعرك هو"}: <b>{this.state.couponData.price} {this.state.couponData.currency} ({this.state.couponData.type})</b><br></br>
                          {lang == "EN" && "Your coupon is"}{lang == "HE" && "הקופון שלך הוא"}{lang == "AR" && "قسيمتك هي"}: <b>{this.state.couponData.random_number}</b>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </form>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <a
                className="btn btn-success btn-lg"
                onClick={this.sendCoupon}
                // to={/payment/ + this.state.coupon_code}
                style={{ color: "white" }}
              >
                {lang == "EN" && "Continue"}
                {lang == "HE" && "המשך"}
                {lang == "AR" && "يكمل"}
                {/* {t.Completepayment[lang]} */}
              </a>
            </Col>
          </Row>
          <Row id="nextstep" style={{ paddingTop: 50 }}>
            {this.state.nextstep &&
              <Col>
                <form method="POST" action="" className="col-md-8 offset-md-3">
                  <Row className="form-group mt-5">
                    <div className="col-md-3">
                      <h4 className="d-inline">{t.UserPayName[lang]}*</h4>
                    </div>
                    <div className="col-md-9">
                      <input
                        type="text"
                        data-tip="Required"
                        onChange={this.onChangeTwoStep}
                        value={this.state.userpay_name}
                        name="userpay_name"
                        className="form-control col-md-8 d-inline"
                        autoFocus={this.state.nextstep == true ? true : false}
                      />
                    </div>
                  </Row>
                  <Row className="form-group mt-5">
                    <div className="col-md-3">
                      <h4 className="d-inline">{t.UserPayCompany[lang]}</h4>
                    </div>
                    <div className="col-md-9">
                      <input
                        type="text"
                        onChange={this.onChangeTwoStep}
                        value={this.state.userpay_company}
                        name="userpay_company"
                        className="form-control col-md-8 d-inline"
                      />
                    </div>
                  </Row>
                  <Row className="form-group mt-5">
                    <div className="col-md-3">
                      <h4 className="d-inline">{t.UserPayEmail[lang]}*</h4>
                    </div>
                    <div className="col-md-9">
                      <input
                        type="email"
                        data-tip="Required"
                        onChange={this.onChangeTwoStep}
                        value={this.state.userpay_email}
                        name="userpay_email"
                        className="form-control col-md-8 d-inline"
                      />
                    </div>
                  </Row>
                  <Row className="form-group mt-5">
                    <div className="col-md-3">
                      <h4 className="d-inline">{t.UserPayPhone[lang]}*</h4>
                    </div>
                    <div className="col-md-9">
                      <input
                        type="text"
                        data-tip="Required"
                        onChange={this.onChangeTwoStep}
                        value={this.state.userpay_phone}
                        name="userpay_phone"
                        id="userpay_phone"
                        className="form-control col-md-8 d-inline"
                      />
                    </div>
                  </Row>
                  <Row className="form-group mt-5">
                    <div className="col-md-3">
                      <h4 className="d-inline">{t.UserPayCountry[lang]}*</h4>
                    </div>
                    <div className="col-md-9">
                      <select data-tip="Required" name="userpay_country" className="form-control col-md-8 d-inline" onChange={this.onChangeTwoStep} defaultValue={this.state.selectedIlCountry ? "Israel" : ""}>
                        <option value="" selected={context.state.selectedIlCountry ? false : true}>{t.UserPayCountry[lang]}</option>
                        {countries.sort(function (a, b) {
                          let sort_a = lang == "HE" ? a.il : a.en
                          let sort_b = lang == "HE" ? b.il : b.en
                          if (sort_a < sort_b) { return -1; }
                          if (sort_a > sort_b) { return 1; }
                          return 0; //sort alphabetically
                        }).map(function (item, key) {
                          return (
                            <option key={key} value={item.en} selected={context.state.selectedIlCountry ? true : false}>{lang == "HE" ? item.il : item.en}</option>
                          )
                        })
                        }
                      </select>
                    </div>
                  </Row>
                  <Row className="form-group mt-5">
                    <div className="col-md-3">
                      <h4 className="d-inline">{t.UserPayVAT[lang]}</h4>
                    </div>
                    <div className="col-md-9" style={{ fontSize: 20, fontWeight: "bold" }}>
                      <div className="row">
                        {/* <div className="col-md-4"></div> */}
                        <div className="col-md-8 d-inline pr-0">{this.state.userpay_vat_bill} {lang == "HE" ? t.UserPayILS[lang] : "$"}</div>
                      </div>
                    </div>
                  </Row>
                  <Row className="form-group mt-2">
                    <div className="col-md-3">
                      <h4 className="d-inline">{t.UserPayTotal[lang]}</h4>
                    </div>
                    <div className="col-md-9" style={{ fontSize: 20, fontWeight: "bold" }}>
                      <div className="row">
                        {/* <div className="col-md-4"></div> */}
                        <div className="col-md-8 d-inline pr-0">{this.state.userpay_vat_total} {lang == "HE" ? t.UserPayILS[lang] : "$"}</div>
                      </div>
                    </div>
                  </Row>
                </form>
              </Col>

            }
          </Row>
          {this.state.nextstep &&
            <>
              <Row>
                <Col className="text-center">
                  <Link
                    className="btn btn-success btn-lg"
                    onClick={this.continuePay}
                  // to={/payment/ + this.state.coupon_code}
                  >
                    {t.Completepayment[lang]}
                  </Link>
                </Col>
              </Row>
            </>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user
  };
};

const mapDispatchToProps = () => {
  return {
    get: (token, id) => actionCreater.getProfile(token, id),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subcriptions);