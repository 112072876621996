export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";

export const GET_CONFIGURATIONS = "GET_CONFIGURATIONS";
export const EDIT_CONFIGURATION = "EDIT_CONFIGURATION";

export const GET_USERS = "GET_USERS";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const USER_DETAIL = "USER_DETAIL";
export const EDIT_USER_PROFILE = "EDIT_USER_PROFILE";

export const GET_PROFILE = "GET_PROFILE";
export const GET_CARDS = "GET_CARDS";
export const GET_CARD = "GET_CARD";
export const CREATE_CARD = "CREATE_CARD";
export const EDIT_CARD = "EDIT_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const CHANGE_STATUS = "CHANGE_STATUS";

export const GET_META_DATA = "GET_META_DATA";
export const GET_SLUGS = "GET_SLUGS";
export const GET_EVENT_STATS = "GET_EVENT_STATS";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_USER_PER_COUNTRY = "GET_USER_PER_COUNTRY";

export const REGISTER = "REGISTER";

export const GET_PRICES = "GET_PRICES";
export const GET_TESTIMONIALS = "GET_TESTIMONIALS";
export const RE_ENTERPASSWORD = "RE_ENTERPASSWORD";
