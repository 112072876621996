let lang = {};

lang.UserPayName = { EN: "Full name", HE: "שם מלא", AR: "الاسم الكامل" };
lang.UserPayEmail = { EN: "Email", HE: "דוא'ל", AR: "بريد الالكتروني" };
lang.UserPayPhone = { EN: "Phone", HE: "מספר טלפון נייד", AR: "هاتف" };
lang.UserPayCompany = { EN: "Company", HE: "חברה", AR: "شركة" };
lang.UserPayCountry = { EN: "Country", HE: "מדינה", AR: "دولة" };
lang.UserPayPrice = { EN: "Price", HE: "מחיר", AR: "السعر" };
lang.UserPayIncludeVat = {
  EN: "Include VAT",
  HE: 'כלול מע"מ',
  AR: "تشمل ضريبة القيمة المضافة",
};
lang.UserModalTitle = {
  EN: "Congratulations!!!",
  HE: "ברכות!!!",
  AR: "مبروك!!!",
};
lang.UserModalT1 = {
  EN: "Your new Easy Card is activated and ready to use. Now you could start sharing your cards with customers and colleagues.",
  HE: "כרטיס ה EASY CARD עם לקוחות וקולגות עכשיו תוכל להתחיל לשתף את הכרטיס שלך .לשימוש שלך הופעל ומוכן.",
  AR: "لقد تم تنشيط بطاقة Easy Card والزملاء يمكنك الآن البدء في مشاركة بطاقتك مع العملاء .وهي الآن في جاهزة للاستخدام الجديدة الخاصة بك.",
};

lang.UserModalT2 = {
  EN: "Note: if you hold an Android device you could use our auto reply application that will help you spread your card!!",
  HE: " שתעזור לך להפיץ את כרטיסך להשתמש באפליקצית המענה האוטומטי שלנו שים לב, אם ברשותך מכשיר אנדרואיד ,תוכל!!",
  AR: "وتيرة انتشار بطاقتك يمكنك استخدام تطبيق الرد التلقائي الذي سيزيد من ملاحظة إذا كان لديك جهاز يعمل بنظام أندرويد!!",
};
lang.UserModalT3 = {
  EN: "Go to ctrlreply.com and download the Controlled Reply application today!",
  HE: "בקר ב ctrlreply.com והורד את יישום Controlled Reply היום",
  AR: "اذهب إلى ctrlreply.com  وقم بتنزيل تطبيق الرد Controlled Reply اليوم!",
};
lang.UserModalT4 = {
  EN: "Use the following coupon",
  HE: "השתמש בקופון הבא",
  AR: "استخدم القسيمة التالية",
};
lang.UserModalT5 = {
  EN: "and enjoy the best price!",
  HE: "ותוכל להנות כבר ותוכל להנות כבר!! ",
  AR: "واستمتع بأفضل الأسعار!",
};
lang.UserPayTotal = { EN: "Total", HE: "סך הכל", AR: "Total" };
lang.UserPayILS = { EN: "ILS", HE: 'ש"ח', AR: "ILS" };
lang.UserPayVAT = { EN: "VAT", HE: 'מע"מ', AR: "VAT" };
lang.GoIt = { EN: "Got it", HE: "המשך", AR: "حسنا" };
lang.UserModalTitleError = { EN: "Oops", HE: "Oops", AR: "Oops" };
lang.UserModalT1Error = {
  EN: "It seems like we are having a problem processing your payment.",
  HE: "נראה שיש לנו בעיה בעיבוד התשלום שלך.",
  AR: "يبدو أننا نواجه مشكلة في معالجة دفعتك.",
};
lang.UserModalT2Error = {
  EN: "Please check your payment method and try again.",
  HE: "בדוק את אמצעי התשלום שלך ונסה שוב.",
  AR: "يرجى التحقق من طريقة الدفع الخاصة بك وحاول مرة أخرى.",
};
lang.UserModalT3Error = {
  EN: "For any assistance you can also contact ",
  HE: "לכל עזרה ניתן לפנות גם ",
  AR: "للحصول على أي مساعدة يمكنك أيضا الاتصال",
};

//Sidebar
lang.Dashboard = { EN: "Dashboard", HE: "דף הבית", AR: "لوحة التحكم" };
lang.Profile = { EN: "Profile", HE: "פרופיל", AR: "الصفحة الشخصية" };
lang.ManageProfile = {
  EN: "Manage Profile",
  HE: "נהל פרופיל",
  AR: "إدارة الصفحة الشخصية",
};
lang.Payment = { EN: "Payment", HE: "תשלום", AR: "دفع" };
lang.Subscriptions = { EN: "Subscriptions", HE: "מנויים", AR: "الاشتراكات" };
lang.ManageCards = {
  EN: "Manage Cards",
  HE: "נהל כרטיסים",
  AR: "إدارة البطاقات",
};

lang.cardname = {
  EN: "Card name",
  HE: "שם הכרטיס",
  AR: "اسم البطاقة",
};
lang.CreateNewCard = {
  EN: "Create New Card",
  HE: "צור כרטיס חדש",
  AR: "إنشاء بطاقة جديدة",
};
lang.ViewAllCards = {
  EN: "View All Cards",
  HE: "צפה בכל הכרטיסים",
  AR: "عرض كل البطاقات",
};
// lang. = { "EN" : "", "HE": "", "AR": ""};

// Validation
lang.NANA = {
  EN: "Numbers are not allowed.",
  HE: "אסור להשתמש במספרים.",
  AR: "لا يُسمح بالأرقام.",
};

lang.curl = {
  EN: "URL",
  HE: "כתובת אתר",
  AR: "عنوان url",
};

lang.astate = {
  EN: "state",
  HE: "מצב",
  AR: "الوضع",
};

lang.astatus = {
  EN: "status",
  HE: "סטטוס",
  AR: "الحالة",
};

lang.asubscription = {
  EN: "subscription",
  HE: "מנוי",
  AR: "الاشتراك",
};

lang.aActions = {
  EN: "Actions",
  HE: "פעולות",
  AR: "لإجراءات",
};

lang.aActive = {
  EN: "Active",
  HE: "פעיל",
  AR: "نشط",
};

lang.aInActive = {
  EN: "InActive",
  HE: "לא פעיל",
  AR: "غير نشط",
};

lang.apublished = {
  EN: "published",
  HE: "פורסם",
  AR: "منشور",
};

lang.aunpublished = {
  EN: "unpublished",
  HE: "לא מפורסם",
  AR: "غير منشور",
};

lang.anosubscription = {
  EN: "no subscription",
  HE: "אין מנוי",
  AR: "لا يوجد اشتراك",
};

lang.aEdit = {
  EN: "Edit",
  HE: "ערוך",
  AR: "تعديل",
};

lang.aactivate = {
  EN: "activate",
  HE: "הפעל",
  AR: "تفعيل",
};

lang.aView = {
  EN: "View",
  HE: "צפה",
  AR: "عرض",
};

lang.ONA = {
  EN: "Only Numbers Allowed.",
  HE: "רק מספרים המותרים",
  AR: "فقط الأرقام المسموح بها",
};
lang.VURL = {
  EN: "Please use a valid url.",
  HE: "אנא השתמש בכתובת אתר חוקית.",
  AR: "الرجاء استخدام عنوان URL صالح.",
};

lang.footerallrights = {
  EN: "all rights reserved",
  HE: "כל הזכויות שמורות",
  AR: "جميع الحقوق محفوظة",
};

lang.footercopyright = {
  EN: "Copyright",
  HE: "זכויות יוצרים",
  AR: "حقوق النشر",
};

//Profile

lang.ActiveCards = {
  EN: "Active Cards",
  HE: "כרטיסים פעילים",
  AR: "بطاقات نشطة",
};
lang.TotalCards = {
  EN: "Total Cards",
  HE: "סה" + "כ כרטיסים",
  AR: "مجموع البطاقات",
};

lang.YOUSTILL = {
  EN: "YOU STILL DON’T HAVE ANY CARD FOR YOUR ACCOUNT, START CREATING YOUR FIRST CARD. ",
  HE: "אין לך עדיין כרטיס בחשבון, התחל ליצור את כרטיסך הראשון. ",
  AR: "لا زلت لا تتوفر على أي بطاقة في حسابك، ابدأ عبر إنشاء أول بطاقة خاصة بك",
};

lang.create = {
  EN: "Create",
  HE: "צור",
  AR: "إنشاء",
};

lang.logout = {
  EN: "logout",
  HE: "התנתק",
  AR: "تسجيل الخروج",
};

lang.View = {
  EN: "View",
  HE: "צפה",
  AR: "عرض",
};

lang.Prev = {
  EN: "Prev",
  HE: "הקודם",
  AR: "السابق",
};

//paymentpages
lang.Selectaplan = {
  EN: "Select a plan",
  HE: "בחר תוכנית",
  AR: "حدد أحد الخطط",
};

lang.monthly = {
  EN: "monthly",
  HE: "חודשי",
  AR: "شهريا",
};

lang.annually = {
  EN: "annually",
  HE: "שנתי",
  AR: "سنويا",
};

lang.permonth = {
  EN: "per month",
  HE: "לחודש",
  AR: "في الشهر",
};

lang.You = {
  EN: "You haven't Selected any plan yet.",
  HE: "לא בחרת עדיין תוכנית",
  AR: "لم تحدد أي خطة بعد.",
};

lang.peryear = {
  EN: "per year",
  HE: "לשנה",
  AR: "في السنة",
};

lang.Select = {
  EN: "Select",
  HE: "בחר",
  AR: "حدد",
};

lang.Yourselectedplanis = {
  EN: "Your selected plan is:",
  HE: "התוכנית הנבחרת שלך היא",
  AR: "الخطة المحددة هي: شهريا",
};

lang.EnterCouponCode = {
  EN: "Enter Coupon Code",
  HE: "הכנס קוד קופון",
  AR: "أدخل رمز القسيمة",
};

lang.Completepayment = {
  EN: "Complete payment",
  HE: "השלם רכישה",
  AR: "إكمال عملية الدفع",
};

//credir card

lang.Creditcarddetails = {
  EN: "Credit card details",
  HE: "פרטי כרטיס אשראי",
  AR: "تفاصيل بطاقة الائتمان",
};

lang.Creditcardnumber = {
  EN: "Credit card number",
  HE: "מספר כרטיס אשראי",
  AR: "رقم بطاقة الائتمان",
};

lang.Creditenteryourcreditcardnumber = {
  EN: "enter your credit card number",
  HE: "הכנס את מספר כרטיס האשראי",
  AR: "أدخل رقم بطاقة الائتمان الخاصة بك",
};

lang.expirydate = {
  EN: "expiry date",
  HE: "תוקף",
  AR: "تاريخ انتهاء الصلاحية",
};

lang.cardname = {
  EN: "name",
  HE: "שם",
  AR: "الإسم",
};

lang.cardemail = {
  EN: "email",
  HE: "דוא" + "ל",
  AR: "البريد الإلكتروني",
};

lang.cardphone = {
  EN: "phone",
  HE: "טלפון",
  AR: "رقم الهاتف",
};

lang.cardmobile = {
  EN: "mobile",
  HE: "נייד",
  AR: "رقم المحمول",
};

lang.cardcvv = {
  EN: "cvv",
  HE: "3 ספרות בגב הכרטיס",
  AR: "رمز التحقق من البطاقة (CVV)",
};

lang.cardBycompleting = {
  EN: "By completing your order, you agree to our ",
  HE: "על ידי השלמת ההזמנה שלך אתה מסכים לשלנו",
  AR: "باستكمال طلبك ، فإنك توافق على",
};

lang.cardBycompleting1 = {
  EN: "Terms and Conditions",
  HE: "תנאים",
  AR: "الأحكام والشروط",
};

lang.cardBycompleting3 = {
  EN: "and our",
  HE: "ושלנו",
  AR: "و لنا",
};

lang.cardBycompleting2 = {
  EN: "Privacy Policy",
  HE: "מדיניות פרטיות",
  AR: "سياسة خاصة",
};
lang.cardPaymentMethods = {
  EN: "Payment Methods",
  HE: "אמצעי תשלום",
  AR: "طرق الدفع",
};

lang.cardPay = {
  EN: "Pay & Activate Card",
  HE: "שלם",
  AR: "ادفع",
};

lang.cardsecured = {
  EN: "Secured with SSL Satisfaction Guaranteed",
  HE: "מאובטח ע" + "י SSL Satisfaction Guaranteed",
  AR: "مؤمن عبر بروتوكول SSL. الرضا مضمون.",
};
//end

lang.Next = {
  EN: "Next",
  HE: "הבא",
  AR: "التالي",
};

lang.Name = { EN: "Name", HE: "שם", AR: "الإسم" };
lang.Name_p = {
  EN: "Please Enter Your Name",
  HE: "מלא את שמך",
  AR: "الرجاء كتابة الإسم",
};
lang.Name_v = {
  EN: "The Name Field is Required.",
  HE: "שדה השם נדרש.",
  AR: "حقل الاسم مطلوب.",
};

lang.req = {
  EN: "Required",
  HE: "חובה",
  AR: "مطلوب",
};

lang.Position = { EN: "Position", HE: "תפקיד", AR: "المنصب" };
lang.Position_p = {
  EN: "Please Enter Position / Role",
  HE: "אנא הכנס מיקום / תפקיד",
  AR: "الرجاء إدخال الوظيفة / الدور",
};
lang.Specialization = { EN: "Specialization", HE: "התמחות", AR: "الاختصاص" };
lang.Specialization_p = {
  EN: "Please Enter Specialization",
  HE: "אנא הזן התמחות.",
  AR: "يرجى إدخال التخصص.",
};
lang.SelectLanguage = {
  EN: "Select Language",
  HE: "בחר שפה",
  AR: "حدد اللغة",
};
lang.PleaseSelectaLanguage = {
  EN: "Please Select a Language.",
  HE: "אנא בחר שפה.",
  AR: "يرجى تحديد لغة.",
};
lang.ChooseYourLanguage = {
  EN: "Choose Your Language",
  HE: "בחר את השפה שלך",
  AR: "اختر لغتك",
};
lang.About = { EN: "About", HE: "עליי", AR: "عني" };
lang.Email = { EN: "Email", HE: 'דוא"ל', AR: "البريد الإلكتروني" };
lang.Email_p = {
  EN: "Please Enter Email Address",
  HE: 'אנא הכנס כתובת דוא"ל',
  AR: "الرجاء إدخال عنوان البريد الإلكتروني",
};
lang.Password = { EN: "Password", HE: "ססמא", AR: "كلمة المرور" };
lang.Password_v = {
  EN: "Password should be min 6 and max 8 characters.",
  HE: "הסיסמה צריכה להיות לפחות 6 ומקסימום 8 תווים.",
  AR: "يجب أن تكون كلمة المرور 6 دقائق و 8 أحرف كحد أقصى.",
};
lang.Password_p = {
  EN: "Please Enter Password",
  HE: "הכנס ססמא",
  AR: "الرجاء كتابة كلمة المرو",
};
lang.Password_p2 = {
  EN: "Please Enter Password Again",
  HE: "הכנס ססמא שוב",
  AR: "الرجاء كتابة كلمة المرور مجددا",
};
lang.Password_t1 = {
  EN: "Fill the password only if you wish to change the current one. For security reasons the system will log out and you will need to login again with the new password",
  HE: "מלא את הססמא רק במידה ואתה מעוניין לשנות את הססמא הנוכחית שלך. לצרכי אבטחה המערכת תבצע התנתקות ותצטרך לבצע כניסה מחדש עם הססמא שהגדרת.מסיבות אבטחה המערכת תתנתק ותצטרך להתחבר שוב באמצעות הסיסמה החדשה",
  AR: "اكتب كلمة المرور فقط إذا كنت تريد تغيير كلمة المرور القائمة. لدواعي أمنية، سيتم تسجيل خروجك وستحتاج إلى تسجيل الدخول مرة أخرى باستخدام كلمة المرور الجديدة.",
};
lang.Password_t2 = {
  EN: "For security reasons the system will log out and you will need to login again with the new password",
  HE: "מסיבות אבטחה המערכת תתנתק ותצטרך להתחבר שוב באמצעות הסיסמה החדשה",
  AR: "لأسباب أمنية ، سيقوم النظام بتسجيل الخروج وستحتاج إلى تسجيل الدخول مرة أخرى باستخدام كلمة المرور الجديدة",
};
lang.Password = { EN: "Password", HE: "סיסמה", AR: "كلمه السر" };
lang.RenterPassword = {
  EN: "Renter Password",
  HE: "אמת ססמא",
  AR: "أعد كتابة كلمة المرور",
};
lang.Phone = { EN: "Phone", HE: "טלפון", AR: "رقم الهاتف" };
lang.Phone_p = {
  EN: "Please Enter Phone Number",
  HE: "אנא הכנס מספר טלפון",
  AR: "الرجاء إدخال رقم الهاتف",
};
lang.AlternativeNumber = {
  EN: "Alternative Number",
  HE: "מספר נוסף",
  AR: "الرقم البديل",
};
lang.AlternativeNumber_p = {
  EN: "Please Enter Alternative Phone Number",
  HE: "אנא הזן מספר טלפון חלופי",
  AR: "الرجاء إدخال رقم الهاتف البديل",
};
lang.OfficeNumber = { EN: "Office Number", HE: "משרד", AR: "رقم المكتب" };
lang.OfficeNumber_p = {
  EN: "Please Enter Office Phone Number",
  HE: "אנא הזן את מספר הטלפון של המשרד",
  AR: "الرجاء إدخال رقم هاتف المكتب",
};
lang.FaxNumber = { EN: "Fax Number", HE: "פקס", AR: "رقم الفاكس" };
lang.FaxNumber_p = {
  EN: "Please Enter Fax Number",
  HE: "אנא הכנס מספר פקס",
  AR: "الرجاء إدخال رقم الفاكس",
};
lang.WhatsappNumber = {
  EN: "Whatsapp Number",
  HE: "ווטסאפ",
  AR: "رقم الواتساب",
};
lang.WhatsappNumber_p = {
  EN: "Please Enter Whatsapp Phone Number",
  HE: "אנא הכנס מספר טלפון Whatsapp",
  AR: "الرجاء إدخال رقم هاتف Whatsapp",
};
lang.Address = { EN: "Address", HE: "כתובת", AR: "عنوان" };
lang.Website = { EN: "Website", HE: "אתר", AR: "موقع الكتروني" };
lang.Website_p = {
  EN: "Please Enter Website Url",
  HE: "אנא הכנס את כתובת האתר",
  AR: "الرجاء إدخال عنوان الموقع",
};
lang.Waze = { EN: "Waze", HE: "ווייז", AR: "Waze" };
lang.Waze_p = {
  EN: "Please Enter Waze Url",
  HE: "אנא הכנס את Waze Url",
  AR: "الرجاء إدخال Waze Url",
};
lang.Facebook = { EN: "Facebook", HE: "פייסבוק", AR: "Facebook" };
lang.Facebook_p = {
  EN: "Please Enter Facebook Url",
  HE: "אנא הכנס את כתובת האתר של פייסבוק",
  AR: "الرجاء إدخال Facebook Url",
};

lang.Tiktok = { EN: "TikTok", HE: "טיקטוק", AR: "TikTok"};
lang.Tiktok_p = {
  EN: "Please Enter tiktok Url",
  HE: "אנא הכנס את כתובת האתר של פייסבוק",
  AR: "الرجاء إدخال TikTok Url",
};

lang.Linkdin = { EN: "Linkedin", HE: "לינקדאין", AR: "Linkedin" };
lang.Linkdin_p = {
  EN: "Please Enter Linkdin Url",
  HE: "אנא הכנס את כתובת האתר של לינקדין",
  AR: "الرجاء إدخال رابط الدين",
};
lang.Twitter = { EN: "Twitter", HE: "טוויטר", AR: "Twitter" };
lang.Twitter_p = {
  EN: "Please Enter Twitter Url",
  HE: "אנא הכנס את כתובת האתר של טוויטר",
  AR: "الرجاء إدخال رابط تويتر",
};
lang.GooglePlus = { EN: "Skype", HE: "סקייפ", AR: "Skype" };
lang.GooglePlus_p = {
  EN: "Please Enter Skype Id",
  HE: "אנא הכנס את כתובת האתר של Skype",
  AR: "الرجاء إدخال رابط + Skype",
};
lang.Instagram = { EN: "Instagram", HE: "אינסטגרם", AR: "Instagram" };
lang.Instagram_p = {
  EN: "Please Enter Instagram Url",
  HE: "אנא הכנס את כתובת האתר של Instagram",
  AR: "الرجاء إدخال عنوان Instagram",
};
lang.Youtube = { EN: "Youtube", HE: "יוטיוב", AR: "Youtube" };
lang.Youtube_p = {
  EN: "Please Enter Youtube Url",
  HE: "אנא הכנס את כתובת האתר של YouTube",
  AR: "الرجاء إدخال يوتيوب رابط",
};
lang.Avatar = { EN: "Avatar", HE: "אווטאר", AR: "الصورة الرمزية" };
lang.Avatar_p = {
  EN: "Please use a jpg, png, jpeg format for image",
  HE: "אנא השתמש בפורמט jpg, png, jpeg לתמונה",
  AR: "يرجى استخدام صيغة jpg ، png ، jpeg للصورة",
};
lang.Update = { EN: "Update", HE: "עדכן", AR: "تحديث" };

// Dashboard
lang.AnalyticsDashboard = {
  EN: "Analytics Dashboard",
  HE: "דף הבית וסטטיסטיקות",
  AR: "لوحة التحكم التحليلية",
};
lang.DashboardSubheading = {
  EN: "View Your Stats History with us for all of your cards.",
  HE: "צפה בהיסטוריית כל הכרטיסים שלך",
  AR: "عرض السجل الخاص بالإحصائيات المتعلقة بكل بطاقاتك.",
};
lang.ActiveSubscriptions = {
  EN: "Active Subscriptions",
  HE: "מנויים פעילים",
  AR: "الاشتراكات النشطة",
};
lang.ExpiredSubscriptions = {
  EN: "Expired Subscriptions",
  HE: "מנויים פגי תוקף",
  AR: "الاشتراكات المنتهية صلاحيتها",
};

// Create New Card
lang.CreateANewCard = {
  EN: "Create A New Card",
  HE: "צור כרטיס חדש",
  AR: "نشاء بطاقة جديدة",
};
lang.CreateANewCard_p = {
  EN: "Many fields are toggleable so try out different ways to setup your card.",
  HE: "ניתן להחליף שדות רבים אז נסה דרכים שונות להתקנת הכרטיס.",
  AR: "العديد من الحقول قابلة للتبديل ، لذا جرب طرقًا مختلفة لإعداد بطاقتك.",
};
lang.BasicInfo = { EN: "Basic Info", HE: "מידע בסיסי", AR: "معلومات أساسية" };
lang.ContactInfo = {
  EN: "Contact Info",
  HE: "פרטים ליצירת קשר",
  AR: "معلومات الاتصال",
};
lang.SocialInfo = {
  EN: "Social Info",
  HE: "מידע חברתי",
  AR: "معلومات اجتماعية",
};
lang.CardInfo = { EN: "Card Info", HE: "פרטי כרטיס", AR: "معلومات البطاقة" };
lang.CardContent = {
  EN: "Card Content",
  HE: "תוכן הכרטיס",
  AR: "محتوى البطاقة",
};
lang.CardLinks = { EN: "Card Links", HE: "קישורי כרטיס", AR: "روابط بطاقة" };

lang.CardTitle = { EN: "Card Title", HE: "שם הכרטיס", AR: "عنوان البطاقة" };
lang.selectaurlforyourcard = {
  EN: "select a url for your card",
  HE: "בחר כתובת לכרטיס שלך",
  AR: "حدد عنوان url لبطاقتك",
};
lang.CardUrl = { EN: "Card Url", HE: "כתובת הכרטיס", AR: "عنوان url للبطاقة" };
lang.VideoLink = { EN: "Video Link", HE: "קישור לסרטון", AR: "رابط الفيديو" };
lang.AboutUs = { EN: "About Us", HE: "אודותינו", AR: "معلومات عنا" };
lang.AboutUs_p = {
  EN: "Please Enter About Us",
  HE: "הכנס טקסט אודותינו",
  AR: "الرجاء إدخال معلومات عنا",
};
lang.OurVision = { EN: "Our Vision", HE: "החזון ", AR: "رؤيتنا" };
lang.OurVision_p = {
  EN: "Please Enter Your Vision",
  HE: "הכנס טקסט החזון שלנו",
  AR: "الرجاء إدخال رؤيتك",
};
lang.OurApplication = {
  EN: "Our Application",
  HE: "האפליקציה שלנו",
  AR: "تطبيقنا",
};
lang.OurApplication_p = {
  EN: "Please Enter Your Application",
  HE: "הכנס טקסט לגבי האפליקציה",
  AR: "الرجاء إدخال تطبيقك",
};
lang.ApplicationLink = {
  EN: "Application Link",
  HE: "קישור ליישום",
  AR: "رابط التطبيق",
};
lang.ProfilePicture = {
  EN: "Profile Picture",
  HE: "תמונת פרופיל",
  AR: "الصوره الشخصيه",
};
lang.Uploadafile = { EN: "Upload a file", HE: "להעלות קובץ", AR: "تحميل ملف" };
lang.BusinessImage = {
  EN: "Business Image",
  HE: "תמונה עסקית",
  AR: "صورة العمل",
};
lang.BackgroundImage = {
  EN: "Background Image",
  HE: "תמונה עסקית",
  AR: "صورة العمل",
};
lang.GalleryImage = {
  EN: "Gallery Image",
  HE: "תמונות גלריה",
  AR: "الصورة من ألبوم الصور",
};
lang.Chooseimages = {
  EN: "Choose images",
  HE: "בחר תמונות",
  AR: "اختر الصور",
};
lang.AdditionalCardLinks = {
  EN: "Additional Card Links",
  HE: "קישורי כרטיס נוספים",
  AR: "روابط البطاقة الإضافية",
};
lang.LinkTitle = { EN: "Link Title", HE: "כותרת קישור", AR: "عنوان الرابط" };
lang.CardLink = { EN: "Card Link", HE: "קישור כרטיס", AR: "رابط البطاقة" };
lang.SuccessStories = {
  EN: "Success Stories",
  HE: "סיפורי הצלחה",
  AR: "قصص النجاح",
};
lang.SuccessStories_p = {
  EN: "Please Enter Our Story",
  HE: "הכנס טקסט ספור הצלחה",
  AR: "الرجاء إدخال قصصن",
};
lang.ActivityHour = {
  EN: "Activity Hour",
  HE: "שעות פעילות",
  AR: "ساعة النشاط",
};
lang.SelectADay = { EN: "Select A Day", HE: "בחר יום", AR: "اختر يوم" };
lang.Monday = { EN: "Monday", HE: "יום שני", AR: "الإثنين" };
lang.Tuesday = { EN: "Tuesday", HE: "יום שלישי", AR: "الثلاثاء" };
lang.Wednesday = { EN: "Wednesday", HE: "יום רביעי", AR: "الأربعاء" };
lang.Thursday = { EN: "Thursday", HE: "יום חמישי", AR: "الخميس" };
lang.Friday = { EN: "Friday", HE: "יום שישי", AR: "يوم الجمعة" };
lang.Saturday = { EN: "Saturday", HE: "יום שבת", AR: "يوم السبت" };
lang.Sunday = { EN: "Sunday", HE: "יום ראשון", AR: "الأحد" };
lang.From = { EN: "From", HE: "מ", AR: "من عند" };
lang.To = { EN: "To", HE: "ל", AR: "إلى" };
lang.Articles = { EN: "Articles", HE: "מאמרים", AR: "المقالات" };
lang.ArticleTitle = {
  EN: "Article Title",
  HE: "כותרת המאמר",
  AR: "عنوان المقال",
};
lang.ArticleLink = { EN: "Article Link", HE: "קישור למאמר", AR: "رابط المقال" };
lang.Employees = { EN: "Employees", HE: "צוות", AR: "الموظفين" };
lang.EmployeeTitle = {
  EN: "Employee Title",
  HE: "שם עובד",
  AR: "عنوان الموظف",
};
lang.EmployeeLink = {
  EN: "Employee Link",
  HE: "קישור עובדים",
  AR: "رابط الموظف",
};
lang.Recommendations = { EN: "Recommendations", HE: "המלצות", AR: "التوصيات" };
lang.RecommendationTitle = {
  EN: "Recommendation Title",
  HE: "כותרת ההמלצה",
  AR: "عنوان التوصية",
};
lang.RecommendationLink = {
  EN: "Recommendation Link",
  HE: "קישור להמלצות",
  AR: "رابط التوصيات",
};

lang.RecommendationLink1 = {
  EN: "The link should be to your facebook page reviews url, i.e https://www.facebook.com/pg/some.page/reviews",
  HE: "הקישור צריך להיות לעמוד הביקורות בדף הפייסבוק שלך למשל:https://www.facebook.com/pg/some.page/reviews",
  AR: "يجب أن يكون عنوان url خاص بالمراجعات على صفحة الفيسبوك الخاصة بك، أي كما يلي https://www.facebook.com/pg/some.page/reviews",
};
lang.Previous = { EN: "Previous", HE: "קודם", AR: "السابق" };
lang.Next = { EN: "Next", HE: "הבא", AR: "التالى" };
lang.Save = { EN: "Save", HE: "לשמור", AR: "حفظ" };

// Edit Card
lang.EditCard = { EN: "Edit Card", HE: "ערוך כרטיס", AR: "تحرير البطاقة" };

// lang. = { "EN" : "", "HE": "", "AR": ""};
// lang. = { "EN" : "", "HE": "", "AR": ""};

lang.Generalinformation = {
  EN: "General information",
  HE: "מידע כללי",
  AR: "معلومات عامة",
};

lang.Generalinformation1 = {
  EN: "Begin by selecting the language for your easy card.",
  HE: "התחל בבחירת השפה של הכרטיס שלך.",
  AR: "ابدأ عبر تحديد لغة بطاقة Easy Card.",
};

lang.Generalinformation2 = {
  EN: "Check the availability of urls for your easy card.",
  HE: "בדוק את הזמינות של הקישור ל Easy Card  שלך.",
  AR: "تحقق من توفر عناوين url لبطاقة Easy Card.",
};

lang.Generalinformation3 = {
  EN: "You can change the language orientation from the profile section anytime by going to the ",
  HE: "באפשרותך לשנות את כיוון השפה ממדור הפרופיל בכל עת על ידי מעבר אל",
  AR: "يمكنك تغيير اتجاه اللغة من قسم ملف التعريف في أي وقت بالانتقال إلى",
};

lang.Generalinformationprofilesection = {
  EN: "profile section",
  HE: "קטע פרופיל",
  AR: "قسم الملف الشخصي",
};

lang.Generalinformatioandselectingyourlanguage = {
  EN: "and selecting your language.",
  HE: ".ובחירת השפה שלך",
  AR: "واختيار لغتك",
};

lang.headerdesign = {
  EN: "Header Design",
  HE: "עיצוב הכותרת",
  AR: "تصميم رأس البطاقة",
};

lang.addup = {
  EN: "Add up images to make your easy card more appealing.",
  HE: "הוסף תמונות כדי לקבל כרטיס יותר אטרקטיבי",
  AR: "أضف بعض الصور لجعل بطاقة Easy Card أكثر جاذبية.",
};

lang.blogo = {
  EN: "Business Logo",
  HE: "לוגו של העסק",
  AR: "شعار العمل",
};

lang.recomendedres = {
  EN: "Recommended Resolution",
  HE: "רזולוציה מומלצת",
  AR: "الدقة الموصى بها",
};

lang.Pixels = {
  EN: "Pixels",
  HE: "פיקסלים",
  AR: "وحدات البكسل",
};

lang.Bussinessimage = {
  EN: "Business Image",
  HE: "תמונת רקע לעסק",
  AR: "صورة العمل",
};

lang.Uploadfile = {
  EN: "Upload a file",
  HE: "טען קובץ",
  AR: "تحميل ملف",
};

lang.Uploadfile = {
  EN: "No file chosen",
  HE: "לא נבחר קובץ",
  AR: "لم يتم اختيار أي ملف",
};

lang.ProfilePicture = {
  EN: "Profile Picture",
  // HE: "תתמונות פרופיל",
  HE: "תמונת פרופיל",
  AR: "الصورة الشخصية",
};

lang.Backgroundcolor = {
  EN: "Background color",
  HE: "צבע הרקע",
  AR: "لون الخلفية",
};

lang.Select = {
  EN: "Select",
  HE: "בחר",
  AR: "حدد",
};

lang.ContactInformation = {
  EN: "Contact Information",
  HE: "פרטי איש הקשר",
  AR: "معلومات الاتصال",
};

lang.provide = {
  EN: "Provide contact Information and tell a little about yourself or your company.",
  HE: "מלא פרטי איש הקשר וספר מעט על עצמך ו\\או על החברה שלך.",
  AR: "قم بتزويد معلومات الاتصال وتحدث قليلا عن نفسك أو عن شركتك",
};

lang.Background = {
  EN: "Background",
  HE: "רקע",
  AR: "الخلفية",
};

lang.Fontcolor = {
  EN: "Font color",
  HE: "צבע פונט",
  AR: "لون الخط",
};

lang.Buttonsselection = {
  EN: "Buttons selection",
  HE: "בחירת כפתורים",
  AR: "اختيار الأزرار",
};

lang.Buttonsselection1 = {
  EN: "A button can remain hidden if you switch it off. Switch it on anytime you want to be on your easy card",
  HE: "כפתור יכול להשאר חבוי אם הוא כבוי. הדלק אותו כשתרצה שיופיע בכרטיס ה Easy Card  שלך.",
  AR: "سيبقى الزر مخفيا إذا أطفأته. يمكنك إشعاله في أي وقت إذا كنت تريده أن يظهر في بطاقة Easy Card.",
};

lang.Buttonsselection2 = {
  EN: "Select the buttons you would like to view on your easy card.",
  HE: "בחר את הכפתורים שתרצה שיופיעו בכרטיס שלך.",
  AR: "سيبقى الزر مخفيا إذا أطفأته. يمكنك إشعاله في أي وقت إذا كنت تريده أن يظهر في بطاقة Easy Card.",
};
lang.Buttonsselection3 = {
  EN: "At least 3 buttons should remain on. You can choose upto 8 buttons from the following.",
  HE: 'מינימום 3 כפתורים יישארו דלוקים ואתה יכול לבחור עד סה"כ 8 כפתורים מהרשימה בהמשך.',
  AR: "يجب أن تبقى 3 أزرار مشتعلة على الأقل. يمكنك اختيار حتى 8 أزرار مما يلي.",
};

lang.Buttonsbackgroundcolor = {
  EN: "Buttons background color",
  HE: "צבע רקע לכפתורים",
  AR: "لون خلفية الأزرار",
};

lang.iconbackcolor = {
  EN: "Icons background color",
  HE: "צבע רקע לאייקונים",
  AR: "لون خلفية الأيقونات",
};

lang.iconcolor = {
  EN: "Icons color",
  HE: "צבע האייקונים",
  AR: "لون الأيقونات",
};

lang.icontextcolor = {
  EN: "Icons text color",
  HE: "צבע הטקסט של האייקון",
  AR: "لون نص الأيقونات",
};

lang.CardSectionsInformation = {
  EN: "Card Sections Information",
  HE: "מידע לגבי האזורים בכרטיס",
  AR: "معلومات حول أقسام البطاقة",
};

lang.CardSectionsInformation1 = {
  EN: "Anytime you want to hide a field you can simply switch it off.",
  HE: "ברגע שתרצה להעלים שדה מהכרטיס פשוט כבה אותו.",
  AR: "إذا كنتم تريدون إخفاء حقل معين، يمكن إطفاءه أو إيقاف تشغيله بكل سهولة.",
};

lang.CardSectionsInformation2 = {
  EN: "Fields that are empty will not show up on the card regardless if they are switched on or not.",
  HE: "שדות שישארו ריקים לא יופיעו בכרטיס לא משנה אם הם דלוקים או כבויים.",
  AR: " كل الحقول الفارغة لن تظهر في البطاقة سواء كانت مشغلة أم لا.",
};

lang.googleplayurl = {
  EN: "Google play store URL",
  HE: "קישור לאפליקציה ב Google Play",
  AR: "عنوان url الخاص بمتجر جوجل بلاي",
};

lang.ApplestoreURL = {
  EN: "Apple store URL",
  HE: "קישור לאפליקציה ב Apple Store",
  AR: "",
};

lang.OurStories = {
  EN: "Our Stories",
  HE: "סיפורי הצלחה",
  AR: "قصصنا",
};

lang.Staff = {
  EN: "Staff",
  HE: "צוות",
  AR: "الموظفين",
};

lang.Stafflink = {
  EN: "Links to additional employees easy cards",
  HE: "קישור לכרטיסי Easy Card של עובדים נוספים",
  AR: "روابط لبطاقات Easy Cards لموظفين إضافيين",
};

lang.leaveamessage = {
  EN: "Leave A Message",
  HE: "השאר הודעה",
  AR: "اترك رسالة",
};

lang.leaveamessage = {
  EN: "Leave A Message",
  HE: "השאר הודעה",
  AR: "اترك رسالة",
};

lang.leaveamessage1 = {
  EN: "This will be the email that messages will be sent to",
  HE: 'זה יהיה הדוא"ל שהודעות יישלחו אליו',
  AR: "سيكون هذا هو البريد الإلكتروني الذي ستُرسَل إليه الرسائل",
};

lang.cntentbackcolor = {
  EN: "content background color",
  HE: "צבע רקע של התוכן",
  AR: "لون خلفية المحتوى",
};

lang.cntentbtnkcolor = {
  EN: "content button color",
  HE: "צבע כפתור התוכן",
  AR: "لون الزر الخاص بالمحتوى",
};

lang.texticoncolor = {
  EN: "Text/Icon color",
  HE: "צבע הטקסטסמל",
  AR: "لون النص/الأيقونة",
};

lang.CardContent = {
  EN: "Card Content",
  HE: "תוכן לכרטיס",
  AR: "محتوى البطاقة",
};

lang.CardContent1 = {
  EN: "You can add upto 5 images in your gallery.",
  HE: "אתה יכולה להוסיף עד 5 תמונות מהגלריה שלך.",
  AR: "يمكنك إضافة حتى 5 صور من ألبوم الصور لديك.",
};

lang.CardContent2 = {
  EN: "Use only youtube embeded link for the video section.",
  HE: "השתמש רק בקישור של יוטיוב לאזור של הוידאו.",
  AR: "استخدم رابط يوتوب الضمني فقط في القسم الخاص بالفيديو",
};

lang.maxfile = {
  EN: "Max file size: 5mb, accepted: jpg|gif|png",
  HE: "גודל קובץ מקסימלי : 5MB   מתקבל: jpg|gif|png",
  AR: "حجم الملف الأقصى: 5 ميجابايت، الصيغ المقبولة:  jpg|gif|png",
};

lang.videourl = {
  EN: "Just copy the code of the video which comes after https://www.youtube.com/watch?v= and paste it here.",
  HE: "העתק רק את הקוד של הוידאו שמופיע אחרי https://www.youtube.com/watch?v=והדבק אותו כאן.",
  AR: "قم فقط بنسخ الكود الخاص بالفيديو والذي يأتي بعد  https://www.youtube.com/watch?v= ثم الصقه هنا.",
};

lang.allcard = {
  EN: "All card fields, color, text and images can be managed here you can change all things according to your satisfaction.",
  HE: "כל השדות של הכרטיס, הצבעים, הטקסטים והתמונות ניתנים לניהול ועריכה, כך שתוכל לשנות הכל לשביעות רצונך.",
  AR: "يمكنك تغيير وإدارة كل الحقول الخاصة بالبطاقة واللون والنص والصور حتى تتناسب مع ذوقك الخاص.",
};

lang.pleaseselectlanguage = {
  EN: "please select language",
  HE: "בחר שפה",
  AR: "لرجاء تحديد اللغة",
};

lang.pleasefillcardtitle = {
  EN: "please fill card title ",
  HE: "אנא מלא שם לכרטיס",
  AR: "الرجاء ملء الحقل الخاص بعنوان البطاقة",
};
lang.pleasefillcardurl = {
  EN: "please fill card url",
  HE: "אנא מלא כתובת לכרטיס",
  AR: "الرجاء ملء الحقل الخاص بعنوان url الخاص بالبطاقة",
};

lang.pleaseselectonly8options = {
  EN: "please select only 8 options",
  HE: "אנא בחר עד 8 אפשרויות",
  AR: "الرجاء تحديد 8 خيارات فقط",
};

lang.pleaseselectonly3options = {
  EN: "please select only 3 options",
  HE: "אנא בחר עד 3 אפשרויות",
  AR: "الرجاء تحديد 3 خيارات فقط",
};

lang.ifyouwant = {
  EN: "if you turn off this button you need to turn on another one, a minimum of 3 buttons is required",
  HE: "מינימום של 3 כפתורים נדרש, אם תכבה את הבחירה הזאת תצטרך לבחור אחד אחר.",
  AR: "في حالة إيقاف هذا الزر، يجب تشغيل زر آخر. يجب تشغيل 3 أزرار على الأقل",
};

lang.validurl = {
  EN: "Please enter valid url",
  HE: "אנא הזן כתובת תקינה",
  AR: "الرجاء إدخال عنوان url صالح",
};

lang.profiletext = {
  EN: "This language selection will effect the appearance of the site and profile pages",
  HE: "בחירת השפה תשפיע על כל דפי האתר",
  AR: "سيؤثر اختيار اللغة على طريقة عرض الموقع والصفحات الشخصية",
};

lang.whatwillitaffect = {
  EN: "what will it affect",
  HE: "על מה זה ישפיע",
  AR: "ماذا سيؤثر",
};

lang.next = { EN: "Next", HE: "הבא", AR: "التالي" };
lang.prev = { EN: "Prev", HE: "קודם", AR: "السابق" };
lang.Leaveusamessage = {
  EN: "Leave us a message",
  HE: "השאר הודעה",
  AR: "اترك لنا رسالة",
};
//Contact us
lang.contactus = {
  EN: "Contact Us",
  HE: "צור קשר",
  AR: "اتصل بنا",
};

lang.contactusfname = {
  EN: "First name",
  HE: "שם פרטי",
  AR: "الإسم الشخصي",
};

lang.contactuslname = {
  EN: "Last name",
  HE: "שם משפחה",
  AR: "الإسم العائلي",
};

lang.contactusEmail = {
  EN: "Email",
  HE: "דוא" + "ל",
  AR: "البريد الإلكتروني",
};

lang.contactusphonenumber = {
  EN: "Phone number",
  HE: "מספר טלפון",
  AR: "رقم الهاتف",
};

lang.Leaveusamessage = {
  EN: "Leave us a message",
  HE: "השאר הודעה",
  AR: "اترك لنا رسالة",
};

lang.Sendmessage = {
  EN: "Send message",
  HE: "שלח הודעה",
  AR: "أرسل الرسالة",
};

lang.Contactusvia = {
  EN: "Contact us via",
  HE: "צור איתנו קשר באמצעות:",
  AR: "اتصل بنا عبر",
};

lang.hint = {
  EN: "what will it affect",
  HE: "על מה זה ישפיע",
  AR: "ماذا سيؤثر",
};

lang.Survey = {
  EN: "Survey",
  HE: "סקר",
  AR: "استطلاع",
};

lang.Enter_Survey_Title = {
  EN: "Enter Survey Title ",
  HE: "הזן כותרת לסקר",
  AR: "أدخل عنوان الاستطلاع",
};

lang.Enter_Option = {
  EN: "Enter Option",
  HE: "הזן אפשרות",
  AR: "أدخل الخيار",
};

lang.Please_enter_survey_title = {
  EN: "Please enter survey title ",
  HE: "אנא הזן כותרת לסקר",
  AR: "الرجاء إدخال عنوان الاستطلاع",
};

lang.please_use_complete = {
  EN: "please use complete url ",
  HE: " השתמש בקישור מלא למשל",
  AR: "الرجاء استخدام عنوان URL ",
};

lang.Generate_QR_Code = {
  EN: "Share QR Code",
  HE: "שתף קוד QR",
  AR: "توليد رمز الاستجابة السريعة",
};

lang.Scan_QR_Code = {
  EN: "Scan QR Code",
  HE: "סרוק קוד QR",
  AR: "مسح رمز الاستجابة السريعة"
  // HE: "לסרוק קוד QR",
  // AR: "مسح رمز الاستجابة السريعة",
};

lang.QR_code_hint_bottom = {
  EN: "QR code will be created per your card URL and will appear in your card",
  HE: "קוד QR  ייווצר לפי הקישור לכרטיסך ויופיע בכרטיסך",
  AR: "سيتم إنشاء رمز الاستجابة السريعة وفقا لعنوان URL الخاص ببطاقتك وسيظهر في بطاقتك",
};

lang.Enter_coupone_code = {
  EN: "Enter coupone code",
  HE: "הכנס קוד קופון",
  AR: "أدخل رمز القسيمة",
};

lang.Apply = {
  EN: "Apply",
  HE: "בדוק תקינות",
  AR: "استعمل",
};

lang.Your_price_is = {
  EN: "Your price is:",
  HE: "המחיר שלך הוא",
  AR: "سعرك هو:",
};

lang.Your_coupone_is = {
  EN: "Your coupone is:",
  HE: "הקופון שלך הוא",
  AR: "قسيمتك هي:",
};

lang.Continue = {
  EN: "Continue",
  HE: "המשך",
  AR: "المواصلة",
};

lang.Full_Name = {
  EN: "Full Name",
  HE: "שם מלא",
  AR: "الإسم الكامل",
};

lang.Company = {
  EN: "Company",
  HE: "המשך",
  AR: "الشركة",
};

lang.Email_address = {
  EN: "Email address",
  HE: "דוא'ל",
  AR: "عنوان البريد الإلكتروني",
};

lang.Mobile_number = {
  EN: "Mobile number:",
  HE: "מספר טלפון נייד",
  AR: "رقم الهاتف المحمول",
};

lang.Tax = {
  EN: "Tax:",
  HE: "מעמ",
  AR: "الضريبة",
};

lang.Total = {
  EN: "Total:",
  HE: "סך הכל",
  AR: "المجموع:",
};

lang.Complete_purchase = {
  EN: "Complete purchase:",
  HE: "השלם רכישה",
  AR: "إكمال عملية الشراء",
};

lang.Fill_marked_fields = {
  EN: "Fill in the marked fields",
  HE: "מלא את השדות המסומנים",
  AR: "املأ الخانات البارزة",
};

lang.Votes = {
  EN: "Votes",
  HE: "הצבעות",
  AR: "التصويتات",
};

lang.Options = {
  EN: "Options",
  HE: "אפשרויות",
  AR: "الخيارات",
};

lang.Card_Survey_Graph = {
  EN: "Card Survey Graph",
  HE: "גרף הסקר בכרטיס",
  AR: "رسم بياني لاستطلاع البطاقة",
};

lang.No_Vote_made_against_this_survey = {
  EN: "No Vote made against this survey",
  HE: "לא בוצעו הצבעות לסקר הזה",
  AR: "لم يتم إجراء أي تصويت ضد هذا الاستطلاع",
};

lang.Confirmation_message = {
  EN: "Your new Easy Card is activated and ready to use. Now you could start sharing your card with customers and colleagues. Note: if you hold an Android device you could use our auto reply application that will help you spread your card!! Go to ctrlreply.com and download the Controlled Reply application today! Use the following coupon WelPromCo!@123Q and enjoy the best price! Got it (button - centered)",
  HE: "ברכות !!!כרטיס ה EASY CARD שלך הופעל ומוכן לשימוש. עכשיו תוכל להתחיל לשתף את הכרטיס שלך עם לקוחות וקולגות.שים לב, אם ברשותך מכשיר אנדרואיד, תוכל להשתמש באפליקצית המענה האוטומטי שלנו שתעזור לך להפיץ את כרטיסך!!בקר ב ctrlreply.com  והורד את אפליקציית Controlled Reply  היום.השתמש בקופון הבא: WelPromCo!@123Q ותוכל להנות כבר היום ממחיר מוזל!!המשך",
  AR: "مبروك!!!قد تم تنشيط بطاقةEasy Cardجديدة الخاصة بك وهي الآنالجاهزة للاستخدامجاهزة للاستخدامطاقتك مع العملاء والزملاء.احظة: إذا كان لديك جهاز يعململنظام أندرويد، يمكنك استخدامتطبيق الرد التلقائي الذي سيزيدمن وتيرة انتشار بطاقتك!! اذهب إل ctrlreply.com وقم زيل تطبيق الرد بتنControlled Reply اليوم! ستخدم القسيمة التاليةWelPromCo!@123Q واستمتع بأفضل الأسعار!حسن",
};
// lang.Card_Survey_Graph = {
//   EN: "Card Survey Graph",
//   HE:"גרף הסקר בכרטיס",
//   AR: "رسم بياني لاستطلاع البطاقة",
// };
// lang.No_Vote_made_against_this_survey = {
//   EN: "No Vote made against this survey",
//   HE: "לא בוצעו הצבעות לסקר הזה",
//   AR: "لم يتم إجراء أي تصويت ضد هذا الاستطلاع",
// };
lang.Payment_failure = {
  EN: "Oops...",
  HE: "הצבעות",
  AR: "التصويتات",
};

export default [lang];
