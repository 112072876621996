import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import UserAppLayout from "./Containers/Layout/UserAppLayout";
import Login from "./Components/Auth";
import Register from "./Components/Auth/Register";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import PrivateRoute from "./Components/Router/PrivateRoute";
import Forgotrenterpasword from "./Components/Auth/Forgotrenterpasword";
import Loginhebrew from "./Components/Auth/LoginHebrew";
import TermsAndConditions from "./Components/TermsAndConditions";
import LoginArabic from "./Components/Auth/LoginArabic";
import LoginEnglish from "./Components/Auth/LoginEnglish";
import Registerhebrew from "./Components/Auth/RegisterHebrew";
import RegisterArabic from "./Components/Auth/RegisterArabic";
import ForgotPasswordHebrew from "./Components/Auth/ForgotPasswordhebrew";
import Forgotpasswordarabic from "./Components/Auth/Forgotpasswordarabic";


// import "./App.css";
// import "./assets/css/style.css";
// import "./assets/css/desktop.css";
import "./assets/css/c-c.css";
import "./assets/css/animate.css";
import "./assets/css/custom.css";
import "./assets/css/custom-card.css";
import "./assets/css/rtl.css";
import "./assets/css/mobile.css";
// import "./assets/css/main.css";
// import "./assets/css/create-card.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="app-main__inner">
          <HashRouter>
            <Switch>
              <Route
                exact
                path="/terms-and-conditions"
                name="TermsAndConditions"
                component={TermsAndConditions}
              />
              <Route exact path="/login" name="Login Page" component={Login} />
              <Route
                exact
                path="/login/En"
                name="Login Page"
                component={LoginEnglish}
              />
              <Route
                exact
                path="/login/He"
                name="Login Page Hebrew"
                component={Loginhebrew}
              />
              <Route
                exact
                path="/login/Ar"
                name="Login Page Arabic"
                component={LoginArabic}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                component={Register}
              />
              <Route
                exact
                path="/register/He"
                name="register Page Hebrew"
                component={Registerhebrew}
              />
              <Route
                exact
                path="/register/Ar"
                name="register Page Arabic"
                component={RegisterArabic}
              />
              <Route
                exact
                path="/ForgotPassword"
                name="Forgot Password Page"
                component={ForgotPassword}
              />
              <Route
                exact
                path="/ForgotPassword/He"
                name="Forgot Password Page"
                component={ForgotPasswordHebrew}
              />

              <Route
                exact
                path="/ForgotPassword/Ar"
                name="Forgot Password Page"
                component={Forgotpasswordarabic}
              />
              <Route
                exact
                path="/PasswordRecovery/:token"
                name="PassWord Recovery"
                component={Forgotrenterpasword}
              />
              <PrivateRoute path="/" name="Home" component={UserAppLayout} />
            </Switch>
          </HashRouter>
        </div>
      </div>
    );
  }
}

export default App;
