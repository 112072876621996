import * as actions from "../Actions/type";
import APIModel from "../../Models/APIModel";
import axios from "axios";

const initSate = {
  user: localStorage.easycardUser ? JSON.parse(localStorage.easycardUser) : null
};

const AuthReducer = (state = initSate, action) => {
  switch (action.type) {
    case actions.LOGIN: {
      let user = { ...action.payload.user };

      user.auth = {
        ...action.payload.auth,
        accessToken: action.payload.access_token
      };

      localStorage.easycardUser = JSON.stringify(user);
      createCookie("userid", user.id, 100);

      return { ...state, user };
    }

    case actions.REGISTER: {
      let user = { ...action.payload.user };

      user.auth = {
        ...action.payload.auth,
        accessToken: action.payload.access_token
      };

      localStorage.easycardUser = JSON.stringify(user);
      return { ...state, user };
    }

    case actions.EDIT_USER_PROFILE: {
      let user = action.payload;

      if (user.id === state.user.id) {
        const fuser = { ...state.user, ...user };
        localStorage.easycardUser = null;
        localStorage.easycardUser = JSON.stringify(fuser);
        return {
          ...state,
          user: fuser
        };
      }
      return state;
    }

    case actions.LOGOUT: {
      let easycardUser = localStorage.getItem("easycardUser");
      let userProfile = JSON.parse(easycardUser);
      let user_id = userProfile["id"];
      let params = { user_id: user_id };
      let language = userProfile['language'];
      localStorage.setItem("user_lang",language);

      axios.post(APIModel.HOST + "logout", params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      });

      localStorage.easycardUser = null;
      eraseCookie("userid");
      return { ...state, user: null };
    }
    case actions.RE_ENTERPASSWORD: {
      let user = action.payload;

      return { ...state, user };
    }

    default: {
      return state;
    }
  }
};

function logout() {}
function createCookie(name, value, days) {
  var expires;

  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie =
    encodeURIComponent(name) +
    "=" +
    encodeURIComponent(value) +
    expires +
    "; path=https://dev.easy-card.co/#/";
}

function eraseCookie(name) {
  createCookie(name, "", -1);
}

export default AuthReducer;
