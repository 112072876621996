import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreater from "../../Store/Actions/CardActions";
import axios from 'axios';
import parse, { domToReact } from 'html-react-parser';
import $ from 'jquery';
import Carousel from 'nuka-carousel';
import { isMobile } from 'react-device-detect';
import MetaTags from 'react-meta-tags';
import {ReactTitle} from 'react-meta-tags';
import alertify from 'alertifyjs';
import APIModel from "../../Models/APIModel";

class Index extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            card: {},
            user: {},
            startTime: [],
            endTime: [],
            slug: "",
            process: true,
            noCard: false,
            message_name:'',
            message_phone:'',
            message_email:'',
            message_message:'',
            host:APIModel.HOST,
        };
        this.get = this.get.bind(this);
        this.onChange = this.onChange.bind(this);
    }
  

    componentWillMount() {

        let pageURL = window.location.href;
        let slug = pageURL.substr(pageURL.lastIndexOf('/') + 1);     
        this.setState({slug:slug});  

    }
       
       componentDidMount() {

        alertify.set('notifier', 'position', 'top-right');
        this.get();

    }

    componentDidUpdate(){
        this.mobileScripts();
    }

    onChange = (e) => {

        this.setState({[e.target.name]: e.target.value})
            
        }

    getImages = (key, i) => {
        let self = this;
    
        this.setState({
          image_process: true
        })
    
        if(key === 'carousel'){
          key = 'images';
          if(i){
            i.forEach(function (ii){
                axios.get(ii, {
                  responseType: 'arraybuffer',
                  headers: {
                    'Content-Type': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                    'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8'
                  }
              }).then(res => {
              let image = btoa(
                new Uint8Array(res.data)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                const posts = `data:${res.headers['content-type'].toLowerCase()};base64,${image}`;
                // self.handleDefaultImages(posts);
              });
            });
          }
        } else{
          axios.get(i, {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
              'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8'
            } 
          })
          .then(res => {
            let image = btoa(
              new Uint8Array(res.data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
              );
              const posts = `data:${res.headers['content-type'].toLowerCase()};base64,${image}`;
              self.setState({
                [`${key}`]: posts
              })
            }).finally(function(){
              self.setState({
                image_process: false
              }) 
            });
        }
    }

    get = () =>{

        let { get, errorHandler } = this.props;
        let slug = { slug : this.props.location.pathname.split('/')[1]};

            get(slug)
                .then(res => {
                    
                    if(res.data.message !== undefined){
                       
                        this.setState({
                            noCard: true
                        });
                    } else if(res.data.message === undefined){
                       
                        let card = res.data.card[0];

                        let m = JSON.parse(card.permission)
                        let c = 0;
                            (m.phone) ? c++ : void 0;
                            (m.alt_mobile) ? c++ : void 0;
                            (m.fax) ? c++ : void 0;
                            (m.office_number) ? c++ : void 0;
                            (m.whatsapp) ? c++ : void 0;
                            (m.website) ? c++ : void 0;
                            (m.waze) ? c++ : void 0;
                            (m.facebook) ? c++ : void 0;
                            (m.twitter) ? c++ : void 0;
                            (m.instagram) ? c++ : void 0;
                            (m.email) ? c++ : void 0;
                            (m.google_plus) ? c++ : void 0;
                            (m.linkdin) ? c++ : void 0;
                            (m.youtube) ? c++ : void 0;
                        
                        
                        this.setState({
                            card: card,
                            id: card.id,
                            card_title: card.card_title,
                            card_video: card.card_video,
                            card_url: card.slug,
                            about_us: JSON.parse(card.about_us),
                            our_vision: JSON.parse(card.vision),
                            profile_image: JSON.parse(card.images).profile_image,
                            business_image: JSON.parse(card.images).business_image,
                            background_image: JSON.parse(card.images).background_image,
                            images: JSON.parse(card.images).carousel,
                            our_application: JSON.parse(card.applications),
                            card_url_typo: true,
                            card_links: JSON.parse(card.card_links),
                            our_stories: JSON.parse(card.stories),
                            activity_hour: JSON.parse(card.activity_hour),
                            articles: JSON.parse(card.articles),
                            employees: JSON.parse(card.employees),
                            recommendations: JSON.parse(card.recommendations),
                            slugCan: "",
                            checked: JSON.parse(card.permission),
                            colors: JSON.parse(card._colors),
                            leave_a_message_label: JSON.parse(card._info).leave_a_message_label,
                            leave_a_message_email: JSON.parse(card._info).leave_a_message,
                            name: JSON.parse(card._info).name,
                            vcard: JSON.parse(card._info).vcard, email: JSON.parse(card._info).email, position: JSON.parse(card._info).position, specialization: JSON.parse(card._info).specialization, 
                            field_title: (card._info.length !== 0) ? Object.keys(JSON.parse(card._info).field)[0]: "About Us", 
                            field_value: (card._info.length !== 0) ? JSON.parse(card._info).field[Object.keys(JSON.parse(card._info).field)] : "",
                            phone: JSON.parse(card._contacts).phone, alt_mobile: JSON.parse(card._contacts).alt_mobile, fax: JSON.parse(card._contacts).fax, office_number: JSON.parse(card._contacts).office_number, whatsapp: JSON.parse(card._contacts).whatsapp, language: card.language,language_val: card.language,
                            website: JSON.parse(card._socials).website, waze: JSON.parse(card._socials).waze, facebook: JSON.parse(card._socials).facebook, instagram: JSON.parse(card._socials).instagram, twitter: JSON.parse(card._socials).twitter, linkdin: JSON.parse(card._socials).linkdin, google_plus: JSON.parse(card._socials).google_plus, youtube: JSON.parse(card._socials).youtube,
                            process: false,
                            button_current: c
                    });
                }
                // this.render();
               
            }).catch(errorHandler);
    };


    leaveamessage = () =>{

        if(this.state){
            
            if(this.state.message_name === "" || this.state.message_name === null){
                alertify.error("please enter name");
                return false;
            }

            if(this.state.message_message === "" || this.state.message_message === null){
                alertify.error("please enter message");
                return false;
            }

        let params = {"send_to":this.state.leave_a_message_email,"from_name":this.state.message_name,
        "from_email":this.state.message_email,"from_number":this.state.message_phone,"from_message":this.state.message_message}

        return axios.post(this.state.host+"admin/message/send", params,{
            'headers': {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              Authorization: "Bearer "+this.state.token,
            }
          })
        .then(response => {
            alertify.success(response.data.message);
        })
        .catch(this.globalErrorHandler).finally();
        }
    }

    globalErrorHandler = (error) => {
        const { response } = error;
        let message;
        if(response){
            message = response.data.message;
        }else{
            message = error.toString();
        }
    }


    desktopScripts(){
        $('html, body').css({
            overflow: 'hidden',
            height: '100%'
        });
        $('body').addClass('bgmain');
    }

    renderVideo(){
        const video = 
        <Fragment>
            { (this.state.checked.card_video) ?
            <iframe width="100%" height="350" scrolling="no"
                src={ parse(this.state.card_video) } frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
            </iframe> : '' }
        </Fragment>;
        return video;
    }

    mobileScripts(){
        if(this.state.colors){
            if(this.state.button_current){
                if(this.state.button_current === 6){
                    $('#custom-card-wrapper .col-3').css('flex','0 0 26%');
                    $('#custom-card-wrapper .col-3').css('max-width','unset');
                }
                else if(this.state.button_current === 5){
                    $('#custom-card-wrapper .col-3').css('flex','0 0 26%');
                    $('#custom-card-wrapper .col-3').css('max-width','unset');
                }
            }
            $('#custom-card-wrapper #about-us-container').css('background-color',this.state.colors.contact_background_color);
            $('#custom-card-wrapper #about-us-title').css('color',this.state.colors.contact_forground_color);
            $('#custom-card-wrapper #about-us-title').css('border-bottom','2px solid '+this.state.colors.contact_forground_color);
            $('#custom-card-wrapper #about-us-content p').css('color',this.state.colors.contact_forground_color);
            $('#custom-card-wrapper #add-contact').css('background-color',this.state.colors.buttons_background_color);
            $('#custom-card-wrapper #add-contact').css('color',this.state.colors.buttons_text_color);
            $('#custom-card-wrapper #add-contact .svg-icon').css('fill',this.state.colors.buttons_icon_fill_color);
            $('#custom-card-wrapper #share-link-background').css('background-color',this.state.colors.default_background_color);
            $('#custom-card-wrapper #share-link-background').css('background-color',this.state.colors.default_background_color);
            $('#custom-card-wrapper .logo-img').css('background-color',this.state.colors.contact_background_color);
            $('#custom-card-wrapper .about-us-section-row').css('background-color',this.state.colors.contact_background_color);
            $('#custom-card-wrapper #name1, #name2').css('color',this.state.colors.contact_forground_color);
            $('#custom-card-wrapper #designations').css('color',this.state.colors.contact_forground_color);
            $('#custom-card-wrapper #reach-us-icons').css('background-color',this.state.colors.buttons_background_color);
            $('#custom-card-wrapper .icons-div a').css('background-color',this.state.colors.buttons_icon_background_color);
            $('#custom-card-wrapper .icons-div a').css('border-color',this.state.colors.buttons_icon_fill_color);
            $('#custom-card-wrapper .icons-div a .svg-icon').css('fill',this.state.colors.buttons_icon_fill_color);
            $('#custom-card-wrapper .icons-div p').css('color',this.state.colors.buttons_text_color);
            $('#custom-card-wrapper #dropdown-background').css('background-color',this.state.colors.content_background_color);
            $('#custom-card-wrapper .resume-wrap').css('background-color',this.state.colors.content_button_background_color);
            $('#custom-card-wrapper .date button').css('background',this.state.colors.content_button_background_color);
            $('#custom-card-wrapper .info-prop-p').css('color',this.state.colors.content_text_color);
            $('#custom-card-wrapper .resume-wrap h6').css('color',this.state.colors.content_text_color);
            $('#custom-card-wrapper .resume-wrap').css('border-color',this.state.colors.content_button_background_color);
        }        
    }

    renderMobileCard(){
        let lang = this.state.language;
        if(lang === 'HE' || lang === 'AR'){
            $('html').attr('dir', 'rtl');
            $('html').attr('lang', lang);
        } else{
            $('html').attr('dir', 'ltr');
            $('html').attr('lang', lang);
        }

        let counter_button = 0;
        return(
            <div id="custom-card-wrapper">
            <MetaTags>
                {/* <meta name="description" content="Some description." />
                <meta property="og:title" content="Easy Card" />
                <meta property="og:image" content="path/to/image.jpg" /> */}
                { this.state.checked.profile_image && this.state.profile_image ?
                    <div>
                        <div className="col-xs-5 about-us-section-row-img">
                            <img src={this.state.profile_image} className="img-responsive person-img" height="150" />
                        </div>
                        <div className="col-xs-6 my-auto name-title-div">
                            <center>
                                <ReactTitle title={this.state.name}/>
                                <meta name="description" content={this.state.position}/>
                                <meta property="og:image" content={this.state.profile_image}/>
                                <p id="name1">{this.state.name}</p>
                                <p id="name2"></p>
                                <p id="designations">{this.state.position}<br />{this.state.specialization}</p>
                            </center>
                        </div>
                    </div>
                    : 
                    <div className="col-xs-12 m-auto" style={{'paddingTop': '2em'}}>
                        <center>
                            <p id="name1">{this.state.name}</p>
                            <p id="name2"></p>
                            <p id="designations">{this.state.position}<br />{this.state.specialization}</p>
                        </center>
                    </div>
                }
            </MetaTags>
                <div id="header-bg">
                    <div className="container">
                        <div className="row">
                            { (this.state.checked.background_image && (this.state.background_image !== undefined || this.state.background_image !== '')) ?
                                <img src={this.state.background_image} alt="" style={{'width': '100%', 'height': '100%'}}/>
                                : 
                                <img src="assets/images/business/1.jpg" alt="" style={{'width': '100%', 'height': '100%'}}/>
                            }
                        </div>
                    </div>
                </div>
                    
                <div id="divider"></div>
                    
                <div className="container" id="share-link-background">
                        <div className="row">
                            { this.state.checked.business_image && this.state.business_image ?
                                <div style={{'display':'contents'}}>
                                    <div className="logo-img img-properties d-flex align-items-center justify-content-center checking">
                                        <img src={this.state.business_image} className="img-responsive" />
                                    </div>
                                    <br/>
                                </div>
                                : ''
                            }
                            <div className="container about-us-section">
                                <div className="row about-us-section-row">
                                    { this.state.checked.profile_image && this.state.profile_image ?
                                        <div>
                                            <div className="col-xs-5 about-us-section-row-img">
                                                <img src={this.state.profile_image} className="img-responsive person-img" height="150" />
                                            </div>
                                            <div className="col-xs-6 my-auto name-title-div">
                                                <center>
                                                    <p id="name1">{this.state.name}</p>
                                                    <p id="name2"></p>
                                                    <p id="designations">{this.state.position}<br />{this.state.specialization}</p>
                                                </center>
                                            </div>
                                        </div>
                                        : 
                                        <div className="col-xs-12 m-auto" style={{'paddingTop': '2em'}}>
                                            <center>
                                                <p id="name1">{this.state.name}</p>
                                                <p id="name2"></p>
                                                <p id="designations">{this.state.position}<br />{this.state.specialization}</p>
                                            </center>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                
                <div id="reach-us-icons">
                        <div className="container">
                            <div className="row">
                                { (counter_button < 8 && this.state.checked.phone === true && this.state.phone !== undefined && this.state.phone !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a href={'tel:'+this.state.phone}>
                                        <svg className="svg-icon mobile-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#mobile-icon"></use>
                                        </svg>
                                    </a>
                                    <p>{(lang === 'EN') ? 'Mobile': 'נייד'}</p>
                                </div>
                                : "" }
                                { (counter_button < 8 && this.state.checked.alt_mobile === true && this.state.alt_mobile !== undefined && this.state.alt_mobile !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ }  >
                                    <a href={'tel:'+this.state.alt_mobile}>
                                        <svg className="svg-icon mobile-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#mobile-icon"></use>
                                        </svg>
                                    </a>
                                    <p>{(lang === 'EN') ? 'Alternative Mobile' : 'טלפון נוסף'}</p>
                                </div>
                                : "" }
                                { (counter_button < 8 && this.state.checked.office_number === true && this.state.office_number !== undefined && this.state.office_number !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a href={'tel:'+this.state.office_number}>
                                        <svg className="svg-icon phone-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#phone-icon"></use>
                                        </svg>
                                    </a>
                                    <p>{ (lang === 'EN') ? 'Office' : 'משרד'}</p>
                                </div>
                                : "" }
                                { (counter_button < 8 && this.state.checked.whatsapp === true && this.state.whatsapp !== undefined && this.state.whatsapp !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a href={'https://api.whatsapp.com/send?phone='+this.state.whatsapp}>
                                        <svg className="svg-icon whatsapp-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#whatsapp-icon"></use>
                                        </svg>
                                    </a>
                                    <p>WhatsApp</p>
                                </div>
                                : "" }
                                { (counter_button < 8 && this.state.checked.email === true && this.state.email !== undefined && this.state.email !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a href={'mailto:'+this.state.email}>
                                        <svg className="svg-icon envelope-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#envelope-icon"></use>
                                        </svg>
                                    </a>
                                    <p>{ (lang === 'EN') ? 'Email' : 'דוא"ל'}</p>
                                </div>
                                : "" }
                            {/* </div>
                            
                            <div id="row2-icons" className="row"> */}
                                { (counter_button < 8 && this.state.checked.waze === true && this.state.waze !== undefined && this.state.waze !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a href={this.state.waze}>
                                        <svg className="svg-icon waze-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#waze-icon"></use>
                                        </svg>
                                    </a>
                                    <p>waze</p>
                                </div>
                                : "" }
                                { (counter_button < 8 && this.state.checked.website === true && this.state.website !== undefined && this.state.website !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a target="blank" href={this.state.website}>
                                        <svg className="svg-icon globe-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#globe-icon"></use>
                                        </svg>
                                    </a>
                                    <p>{ (lang === 'EN') ? 'website' : 'אתר'}</p>
                                </div>
                                : "" }
                                { (counter_button < 8 && this.state.checked.facebook === true && this.state.facebook !== undefined && this.state.facebook !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a href={this.state.facebook}>
                                        <svg className="svg-icon facebook-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#facebook-icon"></use>
                                        </svg>
                                    </a>
                                    <p>facebook</p>
                                </div>
                                : "" }
                                { (counter_button < 8 && this.state.checked.instagram === true && this.state.instagram !== undefined && this.state.instagram !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a href={this.state.instagram}>
                                        <svg className="svg-icon instagram-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#instagram-icon"></use>
                                        </svg>
                                    </a>
                                    <p>instagram</p>
                                </div>
                                : "" }
                                { (counter_button < 8 && this.state.checked.youtube === true && this.state.youtube !== undefined && this.state.youtube !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a href={this.state.youtube}>
                                        <svg className="svg-icon youtube-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#youtube-icon"></use>
                                        </svg>
                                    </a>
                                    <p>youtube</p>
                                </div>
                                : "" }
                                { (counter_button < 8 && this.state.checked.twitter === true && this.state.twitter !== undefined && this.state.twitter !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a href={this.state.twitter}>
                                        <svg className="svg-icon twitter-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#twitter-icon"></use>
                                        </svg>
                                    </a>
                                    <p>twitter</p>
                                </div>
                                : "" }
                                { (counter_button < 8 && this.state.checked.linkdin === true && this.state.linkdin !== undefined && this.state.linkdin !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a href={this.state.linkdin}>
                                        <svg className="svg-icon linked-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#linked-icon"></use>
                                        </svg>
                                    </a>
                                    <p>linkedin</p>
                                </div>
                                : "" }
                                { (counter_button < 8 && this.state.checked.google_plus === true && this.state.google_plus !== undefined && this.state.google_plus !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a href={this.state.google_plus}>
                                        <svg className="svg-icon google_plus-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#google_plus-icon"></use>
                                        </svg>
                                    </a>
                                    <p>google+</p>
                                </div>
                                : "" }
                                { (counter_button < 8 && this.state.checked.fax === true && this.state.fax !== undefined && this.state.fax !== null) ?
                                <div className="icons-div col-3" counter={ counter_button++ } >
                                    <a href={'fax:'+this.state.fax}>
                                        <svg className="svg-icon fax-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#fax-icon"></use>
                                        </svg>
                                    </a>
                                    <p>{ (lang === 'EN') ? 'fax' : 'פקס'}</p>
                                </div>
                                : "" }
                            </div>
                            
                            <div className="reach-us-button">
                                <center>
                                    <a id="add-contact" className="btn-lg mx-auto" href={this.state.vcard}>
                                        <svg className="svg-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#add-icon"></use>
                                        </svg>
                                        {(lang === 'EN') ? "Add to contacts" : "הוסף לאנשי הקשר"}
                                    </a>
                                </center>
                            </div>
                        </div>
                    </div>
                    
                <div id="about-us-background">
                        <div className="container">
                            <div className="row">
                                <div id="about-us-container" className="col-12">
                                    <div id="about-us-content">
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mx-auto my-auto p-3">
                                            <center>
                                                <div id="background-wrapper">
                                                    <span id="about-us-title">{this.state.field_title}</span>
                                                </div>
                                                {/* <p> */}
                                                { (this.state.field_value) ? this.state.field_value.split('\n').map((item, i) => (
                                                        (item) ? <p key={i}>{item}</p> : <br />
                                                    )) : ''
                                                }
                                                    {/* {this.state.field_value} */}
                                                {/* </p> */}
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="dropdown-background">
                        <div className="container">
                            <div className="row">
                                <div id="dropdown-container" className="col-12">
                                    <div className="ftco-section ftco-no-pb col-12 p-0" id="resume-section">
                                        {(this.state.checked.about_us && (this.state.about_us.text)) ?
                                        <div className="resume-wrap">
                                            <span className="date">
                                                <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#collapse-text">
                                                    <i className="ion-2x"><img src="assets/images/digital-card-icons/About us.png"/></i>
                                                    <p className="info-prop-p dropdown-paragraph-extension">{this.state.about_us.label}</p>
                                                </button>
                                                <div id="collapse-text" className="collapse text collapse-text">
                                                    <div className="the-padder-b">
                                                    { (this.state.about_us.text) ? this.state.about_us.text.split('\n').map((item, i) => (
                                                            (item) ? <h6 key={i}>{item}</h6> : <br />
                                                        )) : ''
                                                    }
                                                        {/* <h6>{this.state.about_us.text}</h6> */}
                                                    </div>
                                                </div>
                                            </span>
                                        </div> : ''
                                        }
                                        {(this.state.checked.our_vision && (this.state.our_vision.text)) ?
                                        <div className="resume-wrap">
                                            <span className="date">
                                                <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#collapse-text1">
                                                    <i className="ion-2x"><img src="assets/images/digital-card-icons/Our vision.png"/></i>
                                                    <p className="info-prop-p dropdown-paragraph-extension">{this.state.our_vision.label}</p>
                                                </button>
                                                <div id="collapse-text1" className="collapse text collapse-text">
                                                    <div className="the-padder-b">
                                                        { (this.state.our_vision.text) ? this.state.our_vision.text.split('\n').map((item, i) => (
                                                                (item) ? <h6 key={i}>{item}</h6> : <br />
                                                            )) : ''
                                                        }
                                                        {/* <h6>{this.state.our_vision.text}</h6> */}
                                                    </div>
                                                </div>
                                            </span>
                                        </div> : ''
                                        }
                                        {(this.state.checked.our_application && (this.state.our_application.text)) ?
                                        <div className="resume-wrap">
                                            <span className="date">
                                                <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#collapse-text2">
                                                    <i className="ion-2x"><img src="assets/images/digital-card-icons/Our application.png"/></i>
                                                    <p className="info-prop-p dropdown-paragraph-extension">{this.state.our_application.label}</p>
                                                </button>
                                                <div id="collapse-text2" className="collapse text collapse-text">
                                                    <div className="the-padder-b">
                                                        { (this.state.our_application.text) ? this.state.our_application.text.split('\n').map((item, i) => (
                                                                (item) ? <h6 key={i}>{item}</h6> : <br />
                                                            )) : ''
                                                        }
                                                        {/* <h6>{this.state.our_application.text}</h6> */}
                                                        <br/>
                                                        <div className="container">
                                                            <div className="row mx-auto store-row">
                                                                { this.state.our_application.apple_url ?
                                                                <div className="col-xs-6 mx-auto">
                                                                    <div height="40px;">
                                                                        <a href={this.state.our_application.apple_url}><img src="assets/images/business/apple.png"
                                                                                height="42px" width="125" alt="apple store" /></a>
                                                                    </div>
                                                                </div> : ''
                                                                }
                                                                { this.state.our_application.google_url ?
                                                                    <div className="col-xs-6 mx-auto apple-store">
                                                                        <div height="40px;">
                                                                            <a href={this.state.our_application.google_url}><img src="assets/images/business/google.png"
                                                                                    height="42px" width="125" alt="apple store" /></a>
                                                                        </div>
                                                                    </div> : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </div> : ''
                                        }
                                        {(this.state.checked.activity_hour) ?
                                        <div className="resume-wrap">
                                            <span className="date">
                                                <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#collapse-text3">
                                                    <i className="ion-2x"><img src="assets/images/digital-card-icons/Acivity hours.png" /></i>
                                                    <p className="info-prop-p dropdown-paragraph-extension">{this.state.activity_hour[0]}</p>
                                                </button>
                                                <div id="collapse-text3" className="collapse text collapse-text">
                                                    <div className="the-padder-b">
                                                        {this.state.activity_hour.map((one, index) => (
                                                            (index !== 0) ?
                                                            <div key={index}>
                                                            { (one) ? one.split('\n').map((item, i) => (
                                                                    (item) ? <div key={i}>{item}</div> : <br />
                                                                )) : ''
                                                            }
                                                            </div>
                                                            : ''
                                                        ))}
                                                    </div>
                                                </div>
                                            </span>
                                        </div> : ''
                                        }
                                        { (this.state.checked.employees) ?
                                        <div className="resume-wrap">
                                            <span className="date">
                                                <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#collapse-text4">
                                                    <i className="ion-2x"><img src="assets/images/digital-card-icons/Staff.png"/></i>
                                                    <p className="info-prop-p dropdown-paragraph-extension">{this.state.employees[0].label}</p>
                                                </button>
                                                <div id="collapse-text4" className="collapse text collapse-text">
                                                    {this.state.employees.map((one, index) => (
                                                        (index !== 0) ?
                                                        <div className="the-padder-b" key={index}>
                                                            <h5><a href={one.link} target="blank">{one.title}</a></h5>
                                                            {/* <h6><a href={one.link} target="blank">{one.link}</a></h6> */}
                                                            <hr/>
                                                        </div> : ''
                                                    ))}
                                                </div>
                                            </span>
                                        </div> : ''
                                        }
                                        {(this.state.checked.our_stories) ?
                                        <div className="resume-wrap">
                                            <span className="date">
                                                <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#collapse-text5">
                                                    <i className="ion-2x"><img src="assets/images/digital-card-icons/Success stories.png" /></i>
                                                    <p className="info-prop-p dropdown-paragraph-extension">{this.state.our_stories[0]}</p>
                                                </button>
                                                <div id="collapse-text5" className="collapse text collapse-text">
                                                    <div className="the-padder-b">
                                                        <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                                                            <div className="carousel-inner">
                                                                {this.state.our_stories.map((one, index) => (
                                                                    (index !== 0) ?
                                                                    <div className={(index === 0) ? "carousel-item active" : "carousel-item"} key={index}>
                                                                        { (one) ? one.split('\n').map((item, i) => (
                                                                                (item) ? <h6 key={i}>{item}</h6> : <br />
                                                                            )) : ''
                                                                        }
                                                                        <hr />
                                                                        {/* <h6>{one}</h6> <hr/> */}
                                                                    </div> : ''
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </div> : ''
                                        }
                                        {(this.state.checked.recommendations && (this.state.recommendations[0].link)) ?
                                        <div className="resume-wrap">
                                            <span className="date">
                                                <a target="blank" href={this.state.recommendations[0].link} className="btn btn-info" style={{'border':'unset', 'background': 'unset', 'fontSize': '1em'}}>
                                                    <i className="ion-2x"><img src="assets/images/digital-card-icons/Recommendations.png"/></i>
                                                    <p className="info-prop-p dropdown-paragraph-extension">{this.state.recommendations[0].label}</p>
                                                </a>
                                                {/* <div id="collapse-text6" className="collapse text collapse-text">
                                                    {this.state.recommendations.map((one, index) => (
                                                        (index !== 0) ?
                                                        <div className="the-padder-b" key={index}>
                                                            <h6>{one.title}</h6>
                                                            <h6><a href={one.link}>{one.link}</a></h6>
                                                            <hr/>
                                                        </div> : ''
                                                    ))}
                                                </div> */}
                                            </span>
                                        </div> : ''
                                        }
                                        {(this.state.checked.articles) ?
                                        <div className="resume-wrap">
                                            <span className="date">
                                                <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#collapse-text7">
                                                    <i className="ion-2x"><img src="assets/images/digital-card-icons/Articles.png"/></i>
                                                    <p className="info-prop-p dropdown-paragraph-extension">{this.state.articles[0].label}</p>
                                                </button>
                                                <div id="collapse-text7" className="collapse text collapse-text">
                                                    {this.state.articles.map((one, index) => (
                                                        (index !== 0) ?
                                                        <div className="the-padder-b" key={index}>
                                                            <h5>{one.title}</h5>
                                                            <h6><a href={one.link}>{one.link}</a></h6>
                                                            <hr/>
                                                        </div>
                                                        : ''
                                                    ))}
                                                </div>
                                            </span>
                                        </div> : ''
                                        }
                                        {(this.state.checked.leave_a_message) ?
                                        <div className="resume-wrap">
                                            <span className="date">
                                                <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#collapse-text8">
                                                    <i className="ion-2x"><img src="assets/images/digital-card-icons/Leave a message.png"/></i>
                                                    <p className="info-prop-p dropdown-paragraph-extension">{(this.state.leave_a_message_label) ? this.state.leave_a_message_label : "Leave A Message"}</p>
                                                </button>
                                                <div id="collapse-text8" className="collapse text collapse-text">
                                                    <br/>
                                                    <br/>
                                                    <form>
                                                        <input type="text" className="form-control" id="message_name" name="message_name" value={this.state.message_name} onChange={this.onChange} placeholder="Full Name" required />
                                                        <br />
                                                        <input type="text" className="form-control" id="message_phone" name="message_phone" value={this.state.message_phone} onChange={this.onChange} placeholder="Phone Number" />
                                                        <br />
                                                        <input type="email" className="form-control email-area" id="message_email" name="message_email" value={this.state.message_email} onChange={this.onChange} placeholder="Email" required />
                                                        <br />
                                                        <textarea className="form-control textarea-text" id="message_message" name="message_message" rows="6" value={this.state.message_message} onChange={this.onChange} placeholder="Message"></textarea>
                                                        <br/>
                                                        <button type="button" className="btn btn-send-message" onClick={this.leaveamessage}>Send Message</button>
                                                    </form>
                                                </div>
                                            </span>
                                        </div> : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                <div id="img-slider-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    { (this.state.images !== undefined) ?
                                        <Carousel 
                                            wrapAround
                                            autoplay="true"
                                            heightMode="current"
                                        >
                                            {this.state.images.map((one, index) => (
                                                <img src={one} alt="" key={index} />
                                                
                                            ))}
                                        </Carousel>
                                    : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                <div id="video-background">
                    <div className="video-container-wrapper">
                        {this.renderVideo()}
                    </div>
                </div>
                <div id="share-link-background">
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <p className="share-link-icons-wrapper ">{ (lang === 'EN') ? 'Share this card via:' : 'שתף הכרטיס באמצעות' }</p>
                            </div>
                            <div className="row justify-content-center">
                                <div className=" mx-auto my-auto share-link-icons h-100">
                                    <button type="button" className="btn btn-circle btn-lg">
                                        <a href={"mailto:"+this.state.email}>
                                            <svg aria-hidden="true">
                                                <use xlinkHref="assets/images/svg-icons/sprite.svg#envelope-icon"></use>
                                            </svg>
                                        </a>
                                    </button>&nbsp;&nbsp;

                                    <button type="button" className="btn btn-circle btn-lg ">
                                        <a href={"https://www.facebook.com/sharer/sharer.php?u="+this.state.facebook}>
                                            <svg className="svg-icon" aria-hidden="true">
                                                <use xlinkHref="assets/images/svg-icons/sprite.svg#facebook-icon"></use>
                                            </svg>
                                        </a>
                                    </button>&nbsp;&nbsp;
                                    <button type="button" className="btn btn-circle btn-lg ">
                                        <a href={"https://api.whatsapp.com/send?phone="+this.state.whatsapp+'&text='}>
                                            <svg className="svg-icon" aria-hidden="true">
                                                <use xlinkHref="assets/images/svg-icons/sprite.svg#whatsapp-icon"></use>
                                            </svg>	
                                        </a>
                                    </button>&nbsp;&nbsp;
                                    <button type="button" className="btn btn-circle btn-lg ">
                                        <svg className="svg-icon" aria-hidden="true">
                                            <use xlinkHref="assets/images/svg-icons/sprite.svg#msg-icon"></use>
                                        </svg>	
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                <footer>
                    <div className="container h-100 footer-wrapper">
                        <div className="row h-100 footer-wrapper justify-content-center align-items-center">
                            <p><a href="https://portal.easy-card.co/#/register">{ (lang === 'EN') ? 'Create your own Easy-Card now' : '"צור כרטיס Easy-Card  שלך עכשיו!"'}</a></p>
                        </div>
                    </div>
                </footer>
                {/* <script type="text/javascript" src="js/main.js"></script> */}
            </div>
        );
    }

    render() {
        if(!this.state.process){
            if(!isMobile && this.props.location.pathname.split('/')[2] === undefined){
                if(this.state.noCard){
                    return <div>No Card Available</div>
                } else{
                    return(
                        <div id="desktop">
                            <div className="bgmain" style={{'backgroundImage': 'assets/images/bg.jpg'}}>
                                <div className="displaydesktop">
                                    <div className="center-div">
                                        <img id="the-frame" src="assets/images/mobile.png" alt="" />
                                    </div>
                                    <div className="center-div2" id="iframe-container">
                                        {this.renderMobileCard()}
                                    </div>
                                </div>
                                { this.desktopScripts() }
                            </div>
                        </div>
                    );
                }
            } else{
                if(this.state.noCard){
                    return <div>No Card Available</div>
                }else{
                    return this.renderMobileCard();
                }
            }
        } else { return null; }
    }
}

// const mapStateToProps = state => {
//     return {
//         user: state.AuthReducer.user
//     };
// };

const mapDispatchToProps = () => {
    return {
        get: (slug) => actionCreater.getLogedInSlug(slug),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(Index);
// 0732224444