import APIModel from "../../Models/APIModel";
import axios from "axios";

export const getCards = (token, search) => {
  return axios.get(APIModel.HOST + "cards?ui=1&" + search, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};

export const getCard = (token, id) => {
  return axios.get(APIModel.HOST + "card/" + id, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};

export const changeStatus = (token, id, sta) => {
  return axios.get(APIModel.HOST + "card/status/" + id + "/" + sta, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};

export const getSlugs = (token) => {
  return axios.get(APIModel.HOST + "cards/slugs", {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};

export const userDetail = (token, id) => {
  return axios.get(APIModel.HOST + "admin/users/" + id, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};


export const createCard = (token, params) => {
  return axios.post(APIModel.HOST + "cards", params, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};

export const getSlug = (params) => {
  return axios.post(APIModel.HOST + "slug", params, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  });
};

export const getLogedInSlug = (params) => {
  return axios.post(APIModel.HOST + "slug/logedInSlug", params, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  });
};


export const editCard = (token, id, data) => {
  return axios.post(APIModel.HOST + "cards/" + id, data, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};


export const deleteCard = (token, id) => {
  return axios.delete(APIModel.HOST + "cards/" + id, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};
