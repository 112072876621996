import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import {
  AreaChart,
  Area,
  Line,
  ResponsiveContainer,
  Bar,
  BarChart,
  LineChart,
} from "recharts";
import {
  faAngleUp,
  faArrowLeft,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimplePagination from "./Common/SimplePagination";
import alertify from "alertifyjs";
import avatar1 from "../assets/images/avatars/1.jpg";
import avatar2 from "../assets/images/avatars/2.jpg";
import avatar3 from "../assets/images/avatars/3.jpg";
import avatar4 from "../assets/images/avatars/4.jpg";
import "../index.css";
import { getSearchUrlFromState } from "../util/functions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../Store/Actions/type";
import * as dashboardActionCreater from "../Store/Actions/DashboardActions";
import * as actionCreater from "../Store/Actions/CardActions";
import Loader from "react-loader-spinner";
import T from "../Helpers/Translations";
import $ from "jquery";
import * as qs from "query-string";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import APIModel from "../Models/APIModel";
const data = [
  { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
  { name: "Page C", uv: 2000, pv: 6800, amt: 2290 },
  { name: "Page D", uv: 4780, pv: 7908, amt: 2000 },
  { name: "Page E", uv: 2890, pv: 9800, amt: 2181 },
  { name: "Page F", uv: 1390, pv: 3800, amt: 1500 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
];

const data2 = [
  { name: "Page A", uv: 5400, pv: 5240, amt: 1240 },
  { name: "Page B", uv: 7300, pv: 4139, amt: 3221 },
  { name: "Page C", uv: 8200, pv: 7980, amt: 5229 },
  { name: "Page D", uv: 6278, pv: 4390, amt: 3200 },
  { name: "Page E", uv: 3189, pv: 7480, amt: 6218 },
  { name: "Page D", uv: 9478, pv: 6790, amt: 2200 },
  { name: "Page E", uv: 1289, pv: 1980, amt: 7218 },
  { name: "Page F", uv: 3139, pv: 2380, amt: 5150 },
  { name: "Page G", uv: 5349, pv: 3430, amt: 3210 },
];

class Dashboard extends Component {
  state = {
    // ...this.props,
    country_code: "",
    country: "",
    features: "",
    all_features: "",
    page: 0,
    totalPages: 0,
    process: true,
    host: "https://backend.easy-card.co/api/",
  };

  get = () => {
    this.setState({
      process: true,
    });
    let { get, dispatch, user, errorHandler } = this.props;

    get(user.auth.access_token)
      .then((res) => {
        this.setState({
          dashboardData: res.data.data,
        });

        dispatch({
          type: actions.GET_DASHBOARD,
          payload: res.data.data,
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          process: false,
        });
      });
  };

  next = () => {
    let next = this.state.page + 1;
    if (next >= this.state.totalPages) {
      let search = getSearchUrlFromState(this.state);
      this.getCard(search + "page=" + next);
    }
  };

  previous = () => {
    let previous = this.state.page - 1;
    if (previous > 0) {
      let search = getSearchUrlFromState(this.state);
      this.getCard(search + "page=" + previous);
    }
  };

  changeStatus = (id, sta, v) => {
    let easycardUser = localStorage.getItem("easycardUser");
    let userProfile = JSON.parse(easycardUser);
    let currentBalance = userProfile["amount"];

    if (
      currentBalance === null ||
      currentBalance === "" ||
      currentBalance === 0 ||
      (currentBalance <= 0) | (currentBalance === undefined)
    ) {
      localStorage.removeItem("card_activate");
      localStorage.setItem("card_activate", id);
      this.props.history.push("/subcriptions");
    }

    let { user, changeStatus, dispatch, alertify, errorHandler } = this.props;

    sta = sta == 1 ? 0 : 1;
    changeStatus(user.auth.access_token, id, sta)
      .then((res) => {
        dispatch({
          type: actions.CHANGE_STATUS,
          payload: id,
        });
        this.get();
        // alertify.success(res.data.message);
      })
      .catch(errorHandler);
  };

  updateBrowserid() {
    let z = localStorage.getItem("Browser_id");
    let easycardUser = localStorage.getItem("easycardUser");
    let userProfile = JSON.parse(easycardUser);
    let userid = userProfile["id"];
    let params = { browser_token: z, id: userid };
    return axios
      .post(this.state.host + "browser_token", params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log(response.data.message);
      });
  }

  published = (id, sta, v) => {
    let { user, changeStatus, dispatch, alertify, errorHandler } = this.props;
    let easycardUser = localStorage.getItem("easycardUser");
    let userProfile = JSON.parse(easycardUser);
    let access_token = user.auth.access_token;
    sta = sta == 1 ? 0 : 1;
    return axios
      .post(this.state.host + "card/publish/" + id + "/" + sta, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => {
        alertify.success(response.data.message);
        this.props.history.push("/");
      })
      .catch(errorHandler);
  };

  unpublished = (id, sta, v) => {
    let { user, changeStatus, dispatch, alertify, errorHandler } = this.props;
    let easycardUser = localStorage.getItem("easycardUser");
    let userProfile = JSON.parse(easycardUser);
    let user_id = userProfile["id"];
    let access_token = user.auth.access_token;
    sta = sta == 1 ? 0 : 1;
    let params = { unpublished: "unpublished" };

    return axios
      .post(this.state.host + "card/status/" + id + "/" + sta, params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => {
        alertify.success(response.data.message);
        this.props.history.push("/");
      })
      .catch(errorHandler);

    // let { user, changeStatus, dispatch, alertify, errorHandler } = this.props;
    // sta = (sta == 1) ? 0 : 1;
    // changeStatus(user.auth.access_token, id, sta)
    //   .then(res => {
    //     dispatch({
    //       type: actions.CHANGE_STATUS,
    //       payload: id
    //     });
    //     this.get();
    //     alertify.success(res.data.message);
    //   })
    //   .catch(errorHandler);
  };

  getCard = (search) => {
    this.setState({
      process: true,
    });
    let { getCard, dispatch, user, errorHandler } = this.props;

    getCard(user.auth.access_token, search)
      .then((res) => {
        this.setState({
          page: res.data.meta.current_page,
          totalPages: res.data.meta.last_page,
        });
        dispatch({
          type: actions.GET_CARDS,
          payload: res.data.data,
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          process: false,
        });
      });
  };

  componentDidMount() {
    let search = this.props.location.search;
    const params = qs.parse(search);
    for (let key in params) {
      this.setState({
        [key]: params[key],
      });
    }
    this.get();
    this.getCard(search);
    this.updateBrowserid();
    alertify.set("notifier", "position", "top-right");
  }

  render() {
    let lang = $("html").attr("lang").toUpperCase();
    let t = T[0];
    const { dashboardData: m } = this.state;
    const { cards } = this.props;

    // alert("cards");
    // console.log(cards);
    // return false;

    console.log(this.props);
    let cc = this.state.process ? "hide-it" : "";
    if (!m) {
      return (
        <div className="the-loader" style={{ width: "100%", height: "100vh" }}>
          <Loader type="Triangle" color="#00adb4" height={100} width={100} />
        </div>
      );
    }
    return (
      <div>
        {this.state.process ? (
          <div
            className="the-loader"
            style={{ width: "100%", height: "100vh" }}
          >
            <Loader type="Triangle" color="#00adb4" height={100} width={100} />
          </div>
        ) : (
          ""
        )}
        <div className={cc}>
          <div className="app-page-title">
            <div className="page-title-wrapper">
              <div className="page-title-heading">
                <div className="page-title-icon">
                  <i className="pe-7s-car icon-gradient bg-mean-fruit"></i>
                </div>
                <div className="text-left">
                  {t.AnalyticsDashboard[lang]}
                  <div className="page-title-subheading">
                    {t.DashboardSubheading[lang]}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Row>
            <Col md="4">
              <div className="card mb-3 widget-chart">
                <div className="widget-chart-content">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg bg-primary" />
                    <i className="pe-7s-id text-primary"></i>
                  </div>
                  <div className="widget-numbers">
                    {this.state.dashboardData.allCards}
                  </div>
                  <div className="widget-subheading">{t.TotalCards[lang]}</div>
                  <div className="widget-description text-success">
                    <FontAwesomeIcon icon={faAngleUp} />
                    {/* <span className="pl-1">175.5%</span> */}
                  </div>
                </div>
                <div className="widget-chart-wrapper">
                  <ResponsiveContainer width="100%" aspect={3.0 / 1.0}>
                    <LineChart
                      data={data}
                      margin={{
                        top: 0,
                        right: 5,
                        left: 5,
                        bottom: 0,
                      }}
                    >
                      <Line
                        type="monotone"
                        dataKey="pv"
                        stroke="#3ac47d"
                        strokeWidth={3}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="card mb-3 widget-chart">
                <div className="widget-chart-content">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg bg-success" />
                    <i className="pe-7s-check text-success" />
                  </div>
                  <div className="widget-numbers">
                    {this.state.dashboardData.activeCards}
                  </div>
                  <div className="widget-subheading">
                    {t.ActiveSubscriptions[lang]}
                  </div>
                  <div className="widget-description text-warning">
                    {/* <span className="pr-1">175.5%</span> */}
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </div>
                </div>
                <div className="widget-chart-wrapper">
                  <ResponsiveContainer width="100%" aspect={3.0 / 1.0}>
                    <AreaChart
                      data={data}
                      margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <Area
                        type="monotoneX"
                        dataKey="uv"
                        stroke="#fd7e14"
                        fill="#ffb87d"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="card mb-3 widget-chart">
                <div className="widget-chart-content">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg bg-danger" />
                    <i className="pe-7s-less text-danger" />
                  </div>
                  <div className="widget-numbers">
                    {this.state.dashboardData.expiredCards}
                  </div>
                  <div className="widget-subheading">
                    {t.ExpiredSubscriptions[lang]}
                  </div>
                  <div className="widget-description text-danger">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </div>
                </div>
                <div className="widget-chart-wrapper">
                  <ResponsiveContainer width="100%" aspect={3.0 / 1.0}>
                    <BarChart data={data}>
                      <Bar
                        dataKey="uv"
                        fill="#81a4ff"
                        stroke="#3f6ad8"
                        strokeWidth={2}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              {cards.length > 0 ? (
                <Card className="main-card mb-3">
                  <div className="card-header">{t.ManageCards[lang]}</div>
                  <div className="table-responsive">
                    <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                      <thead>
                        {/* <tr>
                                        <th className="text-center">#</th>
                                        <th>Name</th>
                                        <th className="text-center">URL</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Actions</th>
                                    </tr> */}
                        <tr>
                          <th className="text-center">#</th>
                          <th>{t.cardname[lang]}</th>
                          <th className="text-center">{t.curl[lang]}</th>
                          <th className="text-center">{t.astate[lang]}</th>
                          <th className="text-center">{t.astatus[lang]}</th>
                          <th className="text-center">
                            {t.asubscription[lang]}
                          </th>
                          <th className="text-center">{t.aActions[lang]}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cards.map((m) => {
                          return (
                            // let a_card_link = {
                            //     pathname: '/edit-card/'+m.id,
                            //     query: m.id
                            //   },
                            // <tr key={c.id}>
                            //     <td className="text-center text-muted">#{c.id}</td>
                            //     <td>
                            //     <div className="widget-content p-0">
                            //         <div className="widget-content-wrapper">
                            //         <div className="widget-content-left flex2">
                            //             <div className="widget-heading">{c.user_name}</div>
                            //             <div className="widget-subheading opacity-7">{c.card_title}</div>
                            //         </div>
                            //         </div>
                            //     </div>
                            //     </td>
                            //     <td className="text-center">easy-card.co/#/{c.slug}</td>
                            //     <td className="text-center">
                            //     <div className={ c.status === '1' ? 'badge badge-success' : 'badge badge-warning' }>
                            //         { c.status === '1' ? 'Active' : 'Inactive' }
                            //     </div>
                            //     </td>
                            //     <td className="text-center">
                            //     <Link to={'/edit-card/'+c.id} className="btn btn-danger btn-sm mr-2">Edit</Link>
                            //     { (m.status === '1') ?
                            //     <a target="blank" href={"https://dev.easy-card.co/#/"+c.slug} className="btn btn-primary btn-sm card-detail-btn">View</a>
                            //     :
                            //     <a target="blank" href={"https://dev.easy-card.co/#/card/"+c.user_id+"/view/"+c.slug} className="btn btn-primary btn-sm card-detail-btn">View</a>
                            //     }
                            //     </td>
                            // </tr>

                            <tr key={m.id}>
                              <td className="text-center text-muted">
                                #{m.id}
                              </td>
                              <td>
                                <div className="widget-content p-0">
                                  <div className="widget-content-wrapper">
                                    <div className="widget-content-left flex2">
                                      {/* <div className="widget-heading">
																			{
																				m.user_name
																			}
																		</div> */}
                                      <div className="widget-heading">
                                        {m.card_title}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                {APIModel.PORTAL}/{m.slug}
                              </td>
                              <td className="text-center">
                                <div className={"badge badge-success"}>
                                  {m.status === "1"
                                    ? t.aActive[lang]
                                    : t.aInActive[lang]}
                                </div>
                              </td>
                              <td className="text-center">
                                {(m.status === "1" &&
                                  m.publish_status === null) ||
                                m.publish_status == "1" ? (
                                  <button
                                    type="button"
                                    className="toggle-sw"
                                    onClick={this.unpublished.bind(
                                      this,
                                      m.id,
                                      m.status
                                    )}
                                  >
                                    <div
                                      className={"badge badge-info"}
                                      data-tip={"Click to unpublish card"}
                                    >
                                      {t.apublished[lang]}
                                    </div>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="toggle-sw"
                                    onClick={this.published.bind(
                                      this,
                                      m.id,
                                      m.status
                                    )}
                                  >
                                    <div
                                      className={"badge badge-info"}
                                      data-tip={"Click to publish card"}
                                    >
                                      {t.aunpublished[lang]}
                                    </div>
                                  </button>
                                )}
                              </td>
                              <td className="text-center">
                                <div className={"badge badge-primary"}>
                                  {m.sub == "" ? t.anosubscription[lang] : ""}

                                  {m.sub == "monthly" ? t.monthly[lang] : ""}

                                  {m.sub == "yearly" ? t.annually[lang] : ""}
                                </div>
                              </td>

                              <td style={{ paddingLeft: "100px" }}>
                                <Link
                                  to={"/edit-card/" + m.id}
                                  className="btn btn-danger btn-sm mr-2"
                                >
                                  {t.aEdit[lang]}
                                </Link>
                                {m.status == "1" && m.publish_status == "1" ? (
                                  <a
                                    target="_blank"
                                    href={APIModel.PORTAL_URL + m.slug}
                                    className="btn btn-primary btn-sm card-detail-btn"
                                  >
                                    {t.View[lang]}
                                  </a>
                                ) : (
                                  // <a
                                  //   target="_blank"
                                  //   href={"http://localhost:3001/#/" + m.slug}
                                  //   className="btn btn-primary btn-sm card-detail-btn"
                                  // >
                                  //   View
                                  // </a>
                                  <a
                                    target="_blank"
                                    href={
                                      APIModel.PORTAL_URL +
                                      "card/" +
                                      m.user_id +
                                      "/view/" +
                                      m.slug
                                    }
                                    className="btn btn-primary btn-sm card-detail-btn"
                                  >
                                    {t.View[lang]}
                                  </a>
                                  // <a
                                  //   target="_blank"
                                  //   href={
                                  //     "http://localhost:3001/#/card/" +
                                  //     m.user_id +
                                  //     "/view/" +
                                  //     m.slug
                                  //   }
                                  //   className="btn btn-primary btn-sm card-detail-btn"
                                  // >
                                  //   View
                                  // </a>
                                )}
                                <button
                                  type="button"
                                  className="toggle-sw"
                                  onClick={this.changeStatus.bind(
                                    this,
                                    m.id,
                                    m.status
                                  )}
                                >
                                  <div
                                    className={
                                      m.status === "0"
                                        ? "badge badge-success"
                                        : ""
                                    }
                                    data-tip={"Click to Activate"}
                                  >
                                    {m.status === "0" ? t.aactivate[lang] : ""}
                                  </div>
                                  <ReactTooltip />
                                </button>

                                {m.permission.survey ? (
                                  <Link
                                    to={"/card-survey/" + m.slug}
                                    className="badge badge-survey"
                                    style={{
                                      background: "#e790e7",
                                      color: "white",
                                    }}
                                  >
                                    {t.Card_Survey_Graph[lang]}
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-block text-center card-footer">
                    <SimplePagination
                      next={this.next}
                      previous={this.previous}
                      nextl={t.Next[lang]}
                      previousl={t.Prev[lang]}
                    />
                  </div>
                </Card>
              ) : (
                <div className="card-header">
                  {t.YOUSTILL[lang]}{" "}
                  <Link className="btn btn-primary btn-sm" to={"/create-card"}>
                    {" "}
                    {t.create[lang]}
                  </Link>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cards: state.CardReducer.cards,
    user: state.AuthReducer.user,
  };
};
const mapDispatchToProps = () => {
  return {
    get: (token) => dashboardActionCreater.getDashboard(token),
    getCard: (token, search) => actionCreater.getCards(token, search),
    changeStatus: (token, id, sta) =>
      actionCreater.changeStatus(token, id, sta),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
