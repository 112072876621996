import React, { Component } from "react";
import * as actions from "../../Store/Actions/type";
import * as actionCreater from "../../Store/Actions/UserActions";
import Languages from "../../Helpers/Languages";
import T from "../../Helpers/Translations";
import APIModel from "../../Models/APIModel";
import axios from "axios";
import Validator from "../../Helpers/Validator";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import FileBase64 from "react-file-base64";
import ImageUploader from "react-images-upload";
import Cropper from "react-easy-crop";
import Switch from "react-switch";
import alertify from "alertifyjs";
import TimeRange from "react-time-range";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import SelectSearch from "react-select-search";
import $ from "jquery";
import Loader from "react-loader-spinner";
import { SketchPicker } from "react-color";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coupon_no: "",
      user_id: "",
      token: "",
      language: "",
      language_val: "",
      match: 0,
      password: "",
      password_again: "",
      files: [],
      avatar: null,
      about: "",
      process: true,
      redirect: false,
      host: APIModel.HOST,
      coupon_data: {},
      card_no: "",
      exp_date: "",
      coupon_price: "",
      cvv: "",
      currency: "",
    };
  }

  componentWillMount() {
    let pageURL = window.location.href;
    let id = pageURL.substr(pageURL.lastIndexOf("/") + 1);

    this._postmess = false;
    this.setState({ coupon_no: id });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let easycardUser = localStorage.getItem("easycardUser");
    // let userProfile = JSON.parse(easycardUser);
    // console.log(userProfile)
    alertify.set("notifier", "position", "top-right");
    this.get();
    if (this.state.coupon_no == "" || this.state.coupon_no == null) {
    } else {
      this.getCouponDetails();
    }
  }

  globalErrorHandler = (error, push = false) => {
    const { response } = error;
    let message;
    if (response) {
      message = response.data.message;
    } else {
      message = error.toString();
    }
    alertify.error(message);
    if (push == true) {
      this.props.history.push("/cards");
    }
  };

  getCouponDetails = () => {
    return axios
      .post(
        this.state.host +
          "admin/payment/multidata/coupon?column_name=random_number&value=" +
          this.state.coupon_no,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + this.state.token,
          },
        }
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          this.setState({ coupon_data: response.data.data[0] });

          if (response.data.data[0].price == 0) {
            // console.log("coupon", response.data.data[0])
            return this.activateCard(
              this.state.coupon_no,
              response.data.data[0].type
            );
          }

          if (
            this.state.coupon_data.currency == null ||
            this.state.coupon_data.currency == ""
          ) {
          } else {
            this.setState({
              currency: this.state.coupon_data.currency,
            });
          }
        }
      })
      .catch(this.globalErrorHandler)
      .finally();
  };

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  validatenumber(num) {
    const re = /^[0-9]+$/;
    return re.test(num);
  }
  pay = () => {
    let lang = $("html").attr("lang").toUpperCase();
    let t = T[0];
    if (this.state) {
      if (this.state.card_no === null || this.state.card_no === "") {
        if (lang == "EN") {
          alertify.error("please enter card No");
          return false;
        }
        if (lang == "HE") {
          alertify.error("אנא הכנס מספר כרטיס");
          return false;
        }
        if (lang == "AR") {
          alertify.error("الرجاء إدخال رقم البطاقة");
          return false;
        }
      }
      if (this.state.exp_date === null || this.state.exp_date === "") {
        if (lang == "EN") {
          alertify.error("please enter card expiry date");
          return false;
        }
        if (lang == "HE") {
          alertify.error("אנא הכנס תוקף כרטיס");
          return false;
        }
        if (lang == "AR") {
          alertify.error("الرجاء إدخال تاريخ انتهاء صلاحية البطاقة");
          return false;
        }
      }
      if (this.state.cvv === null || this.state.cvv === "") {
        if (lang == "EN") {
          alertify.error("Please enter cvv");
          return false;
        }
        if (lang == "HE") {
          alertify.error("אנא הכנס CVV (3 ספרות בגב הכרטיס)");
          return false;
        }
        if (lang == "AR") {
          alertify.error("الرجاء إدخال رمز التحقق من البطاقة CVV");
          return false;
        }
      }
      if (this.state.cvv === null || this.state.cvv === "") {
        if (lang == "EN") {
          alertify.error("Please enter cvv");
          return false;
        }
        if (lang == "HE") {
          alertify.error("אנא הכנס CVV (3 ספרות בגב הכרטיס)");
          return false;
        }
        if (lang == "AR") {
          alertify.error("الرجاء إدخال رمز التحقق من البطاقة CVV");
          return false;
        }
      }
      if (this.state.name === null || this.state.name === "") {
        if (lang == "EN") {
          alertify.error("Please enter name");
          return false;
        }
        if (lang == "HE") {
          alertify.error("אנא הכנס שם");
          return false;
        }
        if (lang == "AR") {
          alertify.error("الرجاء إدخال الإسم");
          return false;
        }
      }
      if (this.state.email === null || this.state.email === "") {
        if (lang == "EN") {
          alertify.error("Please enter email");
          return false;
        }
        if (lang == "HE") {
          alertify.error('אנא הכנס כתובת דוא"ל');
          return false;
        }
        if (lang == "AR") {
          alertify.error("الرجاء إدخال البريد الإلكتروني");
          return false;
        }
      }

      if (this.state.email != null || this.state.email != "") {
        if (this.validateEmail(this.state.email)) {
        } else {
          if (lang == "EN") {
            alertify.error("Please enter valid email");
            return false;
          }
          if (lang == "HE") {
            alertify.error('אנא הכנס כתובת דוא"ל תקינה');
            return false;
          }
          if (lang == "AR") {
            alertify.error("الرجاء إدخال بريد إلكتروني صالح");
            return false;
          }
        }
      }

      if (this.state.alt_mobile === null || this.state.alt_mobile === "") {
        if (this.validatenumber(this.state.alt_mobile)) {
        } else {
          if (lang == "EN") {
            alertify.error("Please enter valid number");
            return false;
          }
          if (lang == "HE") {
            alertify.error("אנא הכנס מספר תקין");
            return false;
          }
          if (lang == "AR") {
            alertify.error("الرجاء إدخال رقم صالح");
            return false;
          }
        }
      }

      if (this.state.alt_mobile != null || this.state.alt_mobile != "") {
        if (this.validatenumber(this.state.alt_mobile)) {
        } else {
          if (lang == "EN") {
            alertify.error("Please enter valid number");
            return false;
          }
          if (lang == "HE") {
            alertify.error("אנא הכנס מספר תקין");
            return false;
          }
          if (lang == "AR") {
            alertify.error("الرجاء إدخال رقم صالح");
            return false;
          }
        }
      }
    }
    let cardid = localStorage.getItem("card_activate");
    let sub = localStorage.getItem("sub");
    let params = {
      coupon_no: this.state.coupon_no,
      cvv: this.state.cvv,
      user_id: this.state.user_id,
      name: this.state.name,
      profile: this.state.profile,
      amount: this.state.coupon_data.price,
      expiry_date: this.state.exp_date,
      card_id: cardid,
      subs: sub,
    };

    return axios
      .post(this.state.host + "admin/payment/pay/create", params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + this.state.token,
        },
      })
      .then((response) => {
        let message = response.data.message;
        if (lang == "EN") {
          alertify.success("Card is activated successfully");
        }
        if (lang == "HE") {
          alertify.success("הכרטיס הופעל בהצלחה");
        }
        if (lang == "AR") {
          alertify.success("تم تفعيل البطاقة بنجاح");
        }
        // alertify.success(message);
        let easycardUser = localStorage.getItem("easycardUser");
        let userProfile = JSON.parse(easycardUser);
        let currentBalance = userProfile["amount"];
        userProfile["amount"] = this.state.coupon_data.price;

        localStorage.setItem("easycardUser", JSON.stringify(userProfile));
        localStorage.removeItem("card_activate");
        this.props.history.push("/cards");
      })
      .catch(this.globalErrorHandler)
      .finally();
  };

  get = () => {
    this.setState({
      process: true,
    });
    let { get, dispatch, user, errorHandler } = this.props;

    get(user.auth.access_token, user.id)
      .then((res) => {
        const userData = res.data.data;

        dispatch({
          type: actions.GET_PROFILE,
          payload: userData,
        });

        let profile = res.data[0];

        let a_phone = JSON.parse(profile.alt_phone);
        if (a_phone == null) {
          a_phone = { alt_mobile: "" };
        }

        let social = JSON.parse(profile._socials);
        if (social == null) {
          social = { website: "" };
        }

        this.setState({
          profile: res.data[0],
          id: profile.id,
          name: "",
          position: profile.position,
          specialization: profile.specialization,
          language: profile.language,
          about: profile.about ? profile.about : "",
          email: "",
          phone: "",
          alt_mobile: "",
          office_number: a_phone.office_number ? a_phone.office_number : "",
          fax: a_phone.fax ? a_phone.fax : "",
          whatsapp: a_phone.whatsapp ? a_phone.whatsapp : "",
          website: social.website ? social.website : "",
          waze: social.waze ? social.waze : "",
          facebook: social.facebook ? social.facebook : "",
          instagram: social.instagram ? social.instagram : "",
          twitter: social.twitter ? social.twitter : "",
          linkdin: social.linkdin ? social.linkdin : "",
          google_plus: social.google_plus ? social.google_plus : "",
          youtube: social.youtube ? social.youtube : "",
          address: profile.address ? profile.address : "",
          total_active_cards: profile.total_active_cards,
          total_cards: profile.total_cards,
          avatar: profile.avatar,
        });

        let s = this;
        Languages.filter(function (item) {
          if (item.value === s.state.language) {
            s.setState({
              language_val: item.name,
            });
          }
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          process: false,
        });
      });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getMessageFromIframe = (props) => {
    this._postmess = window.addEventListener("message", function (event) {
      if (event.data) {
        if (!event.data.source) {
          let json = JSON.parse(event.data);

          if (json.action == "back") {
            props.history.push("/cards");
          }

          if (json.status == "success") {
            props.history.push({
              pathname: "/cards",
              state: { modal: json.status },
            });
          } else if (json.status == "error") {
            props.history.push({
              pathname: "/cards",
              state: { modal: json.status },
            });
          }
        }
      }
    });
  };

  render() {
    let userCard = JSON.parse(localStorage.getItem("payUser"));
    // console.log("userCard",userCard)
    let card = JSON.parse(localStorage.getItem("card_activate"));
    let card_id = card;
    let currency = 2; //2 - usd | 1 - shekel

    let lang = $("html").attr("lang").toUpperCase();
    let t = T[0];

    let newLang = lang;

    switch (lang) {
      case "EN":
        lang = "us";
        currency = 2;
        break;
      case "HE":
        lang = "il";
        currency = 1;
        break;
      case "AR":
        lang = "us";
        currency = 2;
        break;
      default:
        lang = "us";
    }
    let priceSub = localStorage.getItem("priceSub");

    let recur_transaction;
    let sub = localStorage.getItem("sub");
    switch (sub) {
      case "yearly":
        recur_transaction = "7_approved";
        break;
      case "monthly":
        recur_transaction = "4_approved";
        break;
    }

    let sumToPay = this.state.coupon_data.price
      ? this.state.coupon_data.price
      : priceSub;

    let vatIL = false;
    if (userCard.country == "IL") {
      let vatILn = (parseFloat(sumToPay) / 100) * 17;
      vatIL = parseFloat(vatILn.toFixed(1));
      // sumToPay = parseFloat(sumToPay) + vatIL
    }

    if (this.state.coupon_data.currency) {
      if (this.state.coupon_data.currency == "ILS") currency = 1;
      else currency = 2;
    }

    // console.log(userCard)

    return (
      <div id="create-card-form">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1
                  style={{
                    color: "#00adb4",
                    "font-weight": "700",
                  }}
                >
                  {t.Creditcardnumber[newLang]}
                </h1>
              </div>
              <div className="col-12 text-center ">
                <h3 className="page-title">
                  {/* {this.state.coupon_no
										? "Coupon No " + this.state.coupon_no
										: ""} */}
                </h3>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  {vatIL != false && (
                    <>
                      {t.UserPayPrice[newLang]}:{" "}
                      {lang == "il" ? t.UserPayILS[lang] : "$"} {sumToPay},{" "}
                      {t.UserPayIncludeVat[newLang]} 17% (
                      {lang == "il" ? t.UserPayILS[lang] : "$"} {vatIL})
                    </>
                  )}
                </div>
                <div className="col-md-12 text-center">
                  <iframe
                    src={
                      "https://direct.tranzila.com/avib83/iframenew.php?sum=" +
                      sumToPay +
                      "&currency=" +
                      currency +
                      "&cred_type=1&lang=" +
                      lang +
                      "&recur_transaction=" +
                      recur_transaction +
                      "&card_id=" +
                      card_id +
                      "&coupon_no=" +
                      this.state.coupon_no +
                      "&subs=" +
                      sub +
                      "&supplier=avib83" +
                      "&email=" +
                      userCard.email +
                      "&phone=" +
                      userCard.phone +
                      "&contact=" +
                      userCard.name +
                      "&company=" +
                      userCard.company +
                      "&country=" +
                      userCard.country +
                      "&tranmode=AK" +
                      "&pdesc=easycard"
                    }
                    width="370px"
                    height="550px"
                    frameborder="0"
                    style={{ marginTop: 20 }}
                    loadReactIframe={this.getMessageFromIframe(this.props)}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
  };
};

const mapDispatchToProps = () => {
  return {
    get: (token, id) => actionCreater.getProfile(token, id),
    editUser: (token, id, data) => actionCreater.editUser(token, id, data),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
