import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../Store/Actions/type";
import * as actionsCreators from "../../Store/Actions/AuthActions";
import alertify from "alertifyjs";
import { errorHandler } from "../../util/functions";
import Languages from "../../Helpers/Languages";
import SelectSearch from "react-select-search";
import Loader from "react-loader-spinner";
import $ from "jquery";
import Cookies from "js-cookie";
import APIModel from "../../Models/APIModel";
class RegisterHebrew extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    password: "",
    password_again: "",
    language: "EN",
    language_val: "EN",
    process: false,
    match: 0,
    terms: false,
    required: false,
  };

  state = {
    ...this.initState,
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.avatar ? e.target.avatar[0] : e.target.value,
    });

    if (
      (e.target.name === "password_again" || e.target.name === "password") &&
      e.target.value !== ""
    ) {
      if (
        e.target.value === this.state.password ||
        this.state.password_again === e.target.value
      ) {
        this.setState({ match: 1 });
      } else {
        this.setState({ match: 0 });
      }
    }
  };

  componentWillMount() {
    $("html").attr("dir", "rtl");
    $("html").attr("lang", "He");
  }

  onChangeCheck = (e) => {
    if (e.target.checked === true) {
      this.setState({ terms: true });
    } else {
      this.setState({ terms: false });
    }
  };

  changeLang = (e) => {
    this.setState({ language: e.value, language_val: e.name });
  };

  register = () => {
    let { register, dispatch, history } = this.props;

    if (this.state) {
      if (
        this.state.language_val == "בחר שפה" ||
        this.state.language_val == undefined
      ) {
        alertify.error("בחר שפה");
        $("select").select();
        return;
      }

      if (this.state.email === undefined) {
        alertify.error("דואל הוא שדה חובה");
        $("input#email").focus();
        return;
      } else if (this.state.email.length > 0) {
        let re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(this.state.email)) {
        } else {
          alertify.error("כתובת הדואל צריכה להיות תקינה");
          $("input#email").focus();
          return;
        }
      }

      if (this.state.name === undefined) {
        alertify.error("שם הוא שדה חובה");
        $("input#name").focus();
        return;
      }

      if (this.state.phone === undefined) {
        alertify.error("מספר טלפון הוא שדה חובה");
        $("input#phone").focus();
        return;
      } else if (this.state.phone.length > 0) {
        var phoneRGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
        if (phoneRGEX.test(this.state.phone)) {
        } else {
          alertify.error("הכנס מספר טלפון תקין");
          $("input#phone").focus();
          return;
        }
      }

      if (this.state.password === undefined) {
        alertify.error("יש להזין ססמא");
        $("input#password").focus();
        return;
      } else if (this.state.password.length > 0) {
        if (this.state.password.length < 6) {
          alertify.error("ססמא חייבת להכיל לפחות 6 תווים");
          $("input#password").focus();
          return;
        }
      }

      if (this.state.match === 0) {
        alertify.error("הסיסמא לא תואמת");
        $("input#passwordAgain").focus();
        return;
      }

      if (this.state.terms === undefined) {
        alertify.error("אנא אשר את תנאי השימוש כדי להמשיך");
        return;
      }

      if (this.state.terms === false) {
        alertify.error("אנא אשר את תנאי השימוש כדי להמשיך");
        return;
      }
    }

    this.setState({
      process: true,
    });

    const { email, name, password, phone, language } = this.state;
    const is_loged_in = 1;
    const params = { email, name, password, phone, language, is_loged_in };
    register(params)
      .then((res) => {
        dispatch({
          type: actions.REGISTER,
          payload: res.data,
        });

        history.push("/");
      })
      .catch(this.globalErrorHandler)
      .finally(() => {
        this.setState({
          process: false,
        });
      });
  };

  globalErrorHandler = (error) => {
    const { response } = error;
    let message;
    if (response) {
      message = response.data.message;
    } else {
      message = error.toString();
    }
    alertify.error(message);
  };

  componentDidMount() {
    alertify.set("notifier", "position", "top-right");
    const cookie = Cookies.get();
    console.log(cookie);
  }

  render() {
    return (
      <div className="h-100 bg-premium-dark">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <div className="mx-auto my-auto app-login-box col-md-12 bg-premium-dark">
            <div className="modal-dialog w-100">
              <div className="modal-content">
                <div className="modal-body">
                  <h5 className="modal-title">
                    <div className="text-center">
                      <img src="assets/images/easy-card.png" alt="" />
                    </div>
                  </h5>
                  <h4 className="mt-2">
                    <div>ברוכים הבאים,</div>
                    <span>
                      יקח לך רק כמה שניות ליצור לך חשבון ב <br /> Easy Card
                    </span>
                    <br />
                    <span style={{ color: "red" }}>כל השדות חובה</span>
                  </h4>

                  <div className="divider row"></div>
                  <div className="form-row">
                    <div className="col-md-12">
                      <div className="position-relative row form-group">
                        <div className="col-sm-12">
                          <SelectSearch
                            onChange={(e) => this.changeLang(e)}
                            className="select-search-box"
                            options={Languages}
                            value={this.state.language_val}
                            name="language"
                            placeholder={
                              this.state.language_val
                                ? this.state.language_val
                                : "בחר שפה"
                            }
                          />
                          <small className="form-error-mode">בחר שפה</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="position-relative form-group">
                        <input
                          value={this.state.email}
                          onChange={this.onChange}
                          name="email"
                          id="email"
                          placeholder="דואל"
                          type="email"
                          className="form-control"
                          autoComplete="new-password"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="position-relative form-group">
                        <input
                          value={this.state.name}
                          onChange={this.onChange}
                          name="name"
                          id="name"
                          placeholder="שם מלא"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="position-relative form-group">
                        <input
                          value={this.state.phone}
                          onChange={this.onChange}
                          name="phone"
                          id="phone"
                          placeholder="מספר טלפון"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="position-relative form-group">
                        <input
                          value={this.state.password}
                          onChange={this.onChange}
                          name="password"
                          id="password"
                          placeholder="ססמא"
                          type="password"
                          className="form-control"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="position-relative form-group">
                        <input
                          value={this.state.password_again}
                          onChange={this.onChange}
                          name="password_again"
                          id="password_again"
                          placeholder="הזן שוב ססמא"
                          type="password"
                          className="form-control"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 position-relative form-check">
                    <input
                      name="check"
                      name="terms"
                      id="terms"
                      type="checkbox"
                      className="form-check-input"
                      autoComplete="off"
                      onClick={this.onChangeCheck}
                    />
                    <label for="terms" className="form-check-label">
                      {/* <a href="/terms-and-conditions" target="_blank"> Terms and Conditions</a>. */}
                      <a
                        // href="https://dev.easy-card.co/terms/and/conditions"
                        href={`${APIModel.PORTAL_URL}terms/and/conditions`}
                        target="_blank"
                      >
                        {" "}
                        קבל את תנאי השימוש
                      </a>
                      .
                    </label>
                  </div>
                  <div className="divider row"></div>
                  <h6 className="mb-0">
                    כבר רשום?{" "}
                    <Link to={"/login/Hb"} className="text-primary">
                      היכנס
                    </Link>{" "}
                    |{" "}
                    <Link to={"/ForgotPassword/He"} className="text-primary">
                      שחזר ססמא
                    </Link>
                  </h6>
                </div>
                <div className="modal-footer d-block text-center">
                  <button
                    className="btn-wide btn-pill btn-shadow btn-hover-shine btn btn-primary btn-lg"
                    onClick={this.register}
                  >
                    צור חשבון
                  </button>
                </div>
              </div>
            </div>
            <div className="text-center text-white opacity-8 mt-3">
              זכויות יוצרים © Easy Card 2019
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
    register: (data) => actionsCreators.register(data),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterHebrew);
