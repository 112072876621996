import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import SideMenu from "./SideMenu";
import Footer from "./Footer";
import Header from "./Header";
import routes from "../../routes";
import T from "../../Helpers/Translations";
import alertify from "alertifyjs";
import { errorHandler } from "../../util/functions";
import * as actions from "../../Store/Actions/type";
import * as actionsCreators from "../../Store/Actions/MetaDataActions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import $ from "jquery";

class UserAppLayout extends Component {
  isAuthenticated = () => {
    return this.props.user !== null;
  };

  componentWillMount() {
    let lang = this.props.user.language;

    if (lang === "HE" || lang === "AR") {
      $("html").attr("dir", "rtl");
      $("html").attr("lang", lang);
    } else {
      $("html").attr("dir", "ltr");
      $("html").attr("lang", lang);
    }
  }

  componentDidMount() {
    alertify.set("notifier", "position", "top-right");

    let { fetchMetaData, dispatch, user } = this.props;

    fetchMetaData(user.auth.access_token)
      .then((res) => {
        dispatch({
          type: actions.GET_META_DATA,
          payload: res.data.data,
        });
      })
      .catch(this.globalErrorHandler);
  }

  globalErrorHandler = (error) => {
    const { dispatch } = this.props;

    errorHandler(error, dispatch, alertify);
  };

  render() {
    let lang = $("html").attr("lang").toUpperCase();
    let t = T[0];
    return (
      <div id="main-lang">
        <Header />
        <div className="app-main">
          <SideMenu />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Switch>
                {routes.map((route, idx) => {
                  return route.Component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => {
                        return (
                          <route.Component
                            alertify={alertify}
                            ability={this.ability}
                            {...props}
                            {...this.props}
                            errorHandler={this.globalErrorHandler}
                          />
                        );
                      }}
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </div>
            <Footer
              copyright={t.footercopyright[lang]}
              allright={t.footerallrights[lang]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
    fetchMetaData: (token) => actionsCreators.getMetaData(token),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAppLayout);
