import React, { Component } from "react";
import Loader from "react-loader-spinner";
import { Row, Col, Card } from "reactstrap";
import axios from "axios";
import $ from "jquery";
import T from "../Helpers/Translations";
import { VictoryPie,VictoryLabel } from 'victory';
import CanvasJSReact from '../canvasjs.react';
import { Bar, HorizontalBar } from 'react-chartjs-2'

// var CanvasJS = CanvasJSReact.CanvasJS;
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
// CanvasJS.addColorSet("greenShades",
// [//colorSet Array

// "rgb(255, 99, 71)",
// "rgb(255, 165, 0)",
// "rgb(255, 215, 0)",
// "rgb(0, 255, 255)",
// "rgb(0, 0, 128)",
// "rgb(128, 0, 128)",
// "rgb(255, 0, 0)",
// "rgb(0, 0, 255)",
// "rgb(255, 192, 203)",
// "rgb(165, 42, 42)"

// ]);



class Cardsurvey extends Component {
	state = {		
		process: true,
        host: "https://backend.easy-card.co/api/",
        survey_text : "",
		data: "",
		bar_data: ""
    };
    


    updateBrowserid = ()=> {
        this.setState({
			process: true,
		});
        let pageURL = window.location.href;
        let slug1 = pageURL.substr(pageURL.lastIndexOf("/") + 1);
        let params = {card_slug: slug1 };
		return axios
			.post(this.state.host + "survey_check_data", params, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			})
			.then((response) => {
				if(response.data.message == "1")
				{
					var result = [];
					var result1 = [];
					console.log('survey_data');
					var keys = Object.keys(response.data.data.graph_data);
		keys.forEach(function(key){
			result.push(response.data.data.graph_data[key]);
		});
		var keys1 = Object.keys(response.data.data.new_chart);
		keys1.forEach(function(key){
			result1.push(response.data.data.new_chart[key]);
		});
					this.setState({survey_text: Object.values(response.data.data.keywords)})
					this.setState({data: result})
					this.setState({bar_data: result1})
				}
				console.log('graph dATA');
				console.log(this.state.survey_text);
				console.log(this.state.data);
				console.log(this.state.bar_data);
			}).finally(() => {
				this.setState({
					process: false,
				});
			});
    }
    
    componentDidMount()
    {
        this.updateBrowserid();
    }
    render() {
		// const options = {
		// 	title: {
		// 	  text: "Card Survey Bar Graph"
		// 	},
		// 	colorSet: "greenShades",
		// 	data: [{				
		// 			  type: "column",
		// 			  dataPoints: this.state.bar_data
		// 	 }]
		// 	}
		
		var options1 = {
			legend: {
				display: false,
				labels: {
					display: false
				},
				position: 'top',

			},
			scales: {
			  xAxes: [{
				ticks: {
				  beginAtZero: true,
				  min: 0
				}    
			  }]
			}
		  };
		const data = {
			labels: this.state.survey_text,
			datasets: [
			  {
				label: '# of Votes',
				data:this.state.bar_data,
				backgroundColor: [
					
			"rgb(255, 99, 71)",
			"rgb(255, 165, 0)",
			"rgb(255, 215, 0)",
			"rgb(0, 255, 255)",
			"rgb(0, 0, 128)",
			"rgb(128, 0, 128)",
			"rgb(255, 0, 0)",
			"rgb(0, 0, 255)",
			"rgb(255, 192, 203)",
			"rgb(165, 42, 42)"
		
				],
				
			  },
			],
		  }
        
        let cc = this.state.process ? "hide-it" : "";
        let lang = $("html").attr("lang").toUpperCase();
		let t = T[0];
        return (
            <div>
				{this.state.process ? (
					<div
						className="the-loader"
						style={{ width: "100%", height: "100vh" }}
					>
						<Loader
							type="Triangle"
							color="#00adb4"
							height={100}
							width={100}
						/>
					</div>
				) : (
					""
				)}


                    <div className={cc}>
					<div className="app-page-title">
						<div className="page-title-wrapper">
							<div className="page-title-heading">
								<div className="page-title-icon">
									<i className="pe-7s-car icon-gradient bg-mean-fruit"></i>
								</div>
								<div className="text-left">
									{t.AnalyticsDashboard[lang]}
									<div className="page-title-subheading">
										{t.DashboardSubheading[lang]}
									</div>
								</div>
							</div>
						</div>
					</div>

                    <Row>

                    <Col md="12" style={{ textAlign: "center"}}>
                    <h2>{t.Card_Survey_Graph[lang]}</h2>

                        </Col>
                    </Row>
					<br></br>

                    <Row>
						{this.state.data != "" ? (

							<Col md="12">
							{/* <VictoryPie
							height={190}
							animate={{
								duration: 2000
							}}
													// colorScale={["tomato", "orange", "gold", "cyan", "navy","purple","red","blue","pink" ]}
													colorScale={[//colorSet Array

														"rgb(255, 99, 71)",
														"rgb(255, 165, 0)",
														"rgb(255, 215, 0)",
														"rgb(0, 255, 255)",
														"rgb(0, 0, 128)",
														"rgb(128, 0, 128)",
														"rgb(255, 0, 0)",
														"rgb(0, 0, 255)",
														"rgb(255, 192, 203)",
														"rgb(165, 42, 42)"
														
														]}
														labels={() => null}
														data={this.state.data}
							/> */}

								</Col>	
						) : (
							<Col md="12">
								
								{/* <h4>No Vote made against this survey</h4> */}
								<h4>{t.No_Vote_made_against_this_survey[lang]}</h4>
								
                                </Col>
						)}
                            
                            </Row>

							<Row>
						{this.state.data != "" ? (
							
							
							<Col md="9">
							 
							<Card md="8">
							<Bar data={data} options={options1} />
							 </Card>

							   </Col>
						) : (
							""
						)}
                            
                            </Row>



                       



                        </div>
                </div>
        );

    }

}
export default (Cardsurvey);
