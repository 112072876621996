import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../Store/Actions/type";
import * as actionsCreators from "../../Store/Actions/AuthActions";
import alertify from 'alertifyjs';
import { errorHandler } from '../../util/functions';
import $ from 'jquery';
import APIModel from "../../Models/APIModel";
import axios from "axios";


class ForgotpasswordHebrew extends Component {
    state = {
        email: "",
        password: "",
        error: "",
        inProgress: false,
        host:APIModel.HOST,
    };

    componentWillMount() {
        $("html").attr("dir", "rtl");
    $("html").attr("lang", "He");
        if (this.props.user) {
            this.props.history.push("/");
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.login();
        }
    }

    globalErrorHandler = (error) => {
        const { response } = error;
        let message;
        if(response){
            message = response.data.message;
        } else{
            message = error.toString();
        }
        alertify.error(message);
    }
    login =(e)=>{
        e.preventDefault();
        let params = {'email': this.state.email};
      
        if(this.state.email == "" || this.state.email=== undefined){
            alertify.error('יקח לך רק כמה שניות ליצור לך חשבון ב');
            return false;
        }
      
        return axios.post(this.state.host + "forgot/password", params,{
            'headers': {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }).then(response => {
          
            alertify.success(response.data.message);
      }).catch(this.globalErrorHandler).finally();
       }
      


     


    componentDidMount() {
        alertify.set('notifier', 'position', 'top-right');
    }

    render() {
        const { email, inProgress, password } = this.state;
        return (
            <div className="app-container">
                <div className="h-100 bg-premium-dark bg-animation">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <div className="mx-auto app-login-box col-md-8">
                            <div className="app-logo-inverse mx-auto mb-3"></div>
                            <div className="modal-dialog w-100 mx-auto">
                                <div className="modal-content">
                                    <div className="text-center bg-white pt-3">
                                        <img src="assets/images/easy-card.png" alt=""/>
                                    </div>
                                    <div className="modal-body">
                                        <div className="h5 modal-title text-center">
                                            <h4 className="mt-2">
                                                <div>שמחים שחזרת,</div>
                                                <span>יקח לך רק כמה שניות ליצור לך חשבון ב</span>
                                            </h4>
                                        </div>
                                        <form className="">
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <div className="position-relative form-group">
                                                        <input type="email" className="form-control"  name="email" id="inputEmail1"
                                                            onChange={this.onChange} value={this.state.email} aria-describedby="emailHelp" placeholder="דואל" required />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="divider"></div>
                                        <h6 className="mb-0">אין חשבון <Link to={'/register'} className="text-primary">הירשם עכשיו</Link></h6>
                                    </div>
                                    <div className="modal-footer clearfix">
                                        <div className="float-left"><Link to={'/login'} className="btn-lg btn btn-link">שחזר ססמא</Link></div>
                                        <div className="float-right">
                                            <button className="btn btn-primary btn-lg"  value={(inProgress) ? "Please wait..." : "Login"} disabled={inProgress} onClick={this.login}>הכנס</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center text-white opacity-8 mt-3">זכויות יוצרים © Easy Card 2019</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



const mapStateToProps = state => {
    return {
        user: state.AuthReducer.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
        login: data => actionsCreators.forgetPassword(data)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotpasswordHebrew);