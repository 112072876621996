
import APIModel from "../../Models/APIModel";
import axios from "axios";

export const getMetaData = token => {
  return axios.get(APIModel.HOST + "dashboard/stats",{
    'headers': {
      'Content-Type': 'application/json',
      'Accept':'application/json',
      'Authorization':'Bearer '+token
    }
  });
};

export const getSettings = (token) => {
  return axios.get(APIModel.HOST + "settings",{
    'headers': {
      'Content-Type': 'application/json',
      'Accept':'application/json',
      'Authorization':'Bearer '+token
    }
  });
};


export const editSettings = (token,settings) => {

  let params = {
    settings: settings
  };

  return axios.put(APIModel.HOST + "settings",params,{
    'headers': {
      'Content-Type': 'application/json',
      'Accept':'application/json',
      'Authorization':'Bearer '+token
    }
  });
};

export const getCountries = (token, search) => {
  return axios.get(APIModel.HOST + "admin/countries" + search,{
    'headers': {
      'Content-Type': 'application/json',
      'Accept':'application/json',
      'Authorization':'Bearer '+token
    }
  });
};

