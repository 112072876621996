import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../Store/Actions/type";
import * as actionsCreators from "../../Store/Actions/AuthActions";
import alertify from "alertifyjs";
import { errorHandler } from "../../util/functions";
import Languages from "../../Helpers/Languages";
import SelectSearch from "react-select-search";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import { useEffect } from "react";

const Header = (props) => {
//   useEffect(() => {
//     $("#test").click(function () {
//         console.log("22 hoya")
//         $("#side-bar").toggleClass("closed-sidebar")
//     });
//   }, []);

  return (
    <div className="app-header header-shadow">
      <div className="app-header__logo">
        <div className="logo-src">
          <Link to={"/dashboard"}>
            <img src="assets/images/easy-card.png" alt="" />
          </Link>
        </div>
        <div className="header__pane ml-auto">
          <div>
            <button
              id="test"
              type="button"
              className="hamburger close-sidebar-btn hamburger--elastic"
              data-class="closed-sidebar"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="app-header__mobile-menu">
        <div>
          <button
            type="button"
            className="hamburger hamburger--elastic mobile-toggle-nav"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      <div className="app-header__menu">
        <span>
          <button
            type="button"
            className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
          >
            <span className="btn-icon-wrapper">
              <i className="fa fa-ellipsis-v fa-w-6"></i>
            </span>
          </button>
        </span>
      </div>
      <div className="app-header__content">
        <div className="app-header-right">
          <div className="header-btn-lg pr-0">
            <div className="widget-content p-0">
              <div className="widget-content-wrapper">
                {/* <div className="widget-content-left  ml-3 header-user-info"> */}
                <div className="widget-content-left">
                  <div className="widget-heading">{props.user.name}</div>
                  <div className="widget-subheading">
                    {props.user.specialization}
                  </div>
                </div>
                {/* <div className="widget-content-right header-user-info ml-3"> */}
                <ReactTooltip />
                <div className="widget-content-right" data-tip="Logout">
                  <Link to="/logout">
                    <i className="pe-7s-power f-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
  };
};
export default connect(mapStateToProps, null)(Header);

// export default Header;
