import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import FileBase64 from "react-file-base64";
import ImageUploader from "react-images-upload";
import Cropper from "react-easy-crop";
import Switch from "react-switch";
import StickyBox from "react-sticky-box";
import alertify from "alertifyjs";
import TimeRange from "react-time-range";
import moment from "moment";
import Carousel from "nuka-carousel";
import { isMobile, isIOS } from "react-device-detect";
import { errorHandler } from "../../util/functions";
import * as actions from "../../Store/Actions/type";
import * as actionCreater from "../../Store/Actions/CardActions";
import * as settingactionCreater from "../../Store/Actions/ConfigurationActions";
import QRCode from "qrcode.react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import Languages from "../../Helpers/Languages";
import Validator from "../../Helpers/Validator";
import T from "../../Helpers/Translations";
import SelectSearch from "react-select-search";
import $ from "jquery";
import axios from "axios";
import Loader from "react-loader-spinner";
import { SketchPicker } from "react-color";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import APIModel from "../../Models/APIModel";

class EditCard extends Component {
  initState = {
    ...this.props.user,
    card_id: this.props.location.pathname.split("/")[2],
    users: this.props.user,
    user_id: this.props.user.id,
    slugs: this.props.slugs,

    name: "",
    email: "",
    position: "",
    specialization: "",
    background: "",
    field_title: "Background",
    field_value: "",
    phone: "",
    alt_mobile: "",
    fax: "",
    office_number: "",
    whatsapp: "",
    language: "",
    language_val: "",
    website: "",
    waze: "",
    facebook: "",
    instagram: "",
    twitter: "",
    linkdin: "",
    google_plus: "",
    youtube: "",
    tiktok: "",
    status: "",
    leave_a_message_label: "Leave A Message",
    leave_a_message: "",
    card_title: "",
    card_video: "",
    card_url: "",
    about_us: { label: "About Us", text: "" },
    our_vision: { label: "Our Vision", text: "" },
    our_application: {
      label: "Our Application",
      text: "",
      google_url: "",
      apple_url: "",
    },
    card_url_typo: false,
    card_links: [],
    our_stories: ["Our Stories"],
    startTime: [],
    endTime: [],
    activity_hour: ["Activity Hour"],
    articles: [{ label: "Articles" }],
    employees: [{ label: "Staff" }],
    recommendations: [{ label: "Recommendations", link: "" }],
    files: [],
    images: [],
    defaultImages: [],
    limits: [],
    key_check: [],
    profile_image: "",
    business_image: "",
    business_image_serve: "",
    background_image: "",
    slugCan: "",
    survey: [{ label: "survey", text: "", title: "" }],
    survey_title: "",
    heading_survey: "Survey",
    heading_article: "Article",
    heading_employee: "Employee",
    heading_recommandation: "Recommandation",
    heading_qrcode: "QR Code",
    heading_story: "Story",
    heading_active_hour: "Active Hour",
    checked: {
      leave_a_message_label: false,
      profile_image: false,
      business_image: false,
      background_image: false,
      images: false,
      card_video: false,
      card_links: false,
      success_stories: false,
      about_us: false,
      our_vision: false,
      our_application: false,
      our_stories: false,
      activity_hour: false,
      articles: false,
      employees: false,
      recommendations: false,
      phone: false,
      alt_mobile: false,
      office_number: false,
      fax: false,
      whatsapp: false,
      email: false,
      waze: false,
      website: false,
      facebook: false,
      instagram: false,
      youtube: false,
      tiktok: false,
      twitter: false,
      google_plus: false,
      qr_code: false,
      survey: false,
    },
    process: true,
    displayColorPicker: {
      contact_background_color: false,
      default_background_color: false,
      contact_forground_color: false,
      buttons_background_color: false,
      buttons_icon_background_color: false,
      buttons_icon_fill_color: false,
      buttons_text_color: false,
      content_background_color: false,
      content_button_background_color: false,
      content_text_color: false,
    },
    colors: {
      default_background_color: "#222221",
      contact_background_color: "#222221",
      contact_forground_color: "#ffffff",
      buttons_background_color: "#f7f7f7",
      buttons_icon_background_color: "#ffffff",
      buttons_icon_fill_color: "#ffd65a",
      buttons_text_color: "#5e5e5e",
      content_background_color: "#f7f7f7",
      content_button_background_color: "#0099CC",
      content_text_color: "#ffffff",
    },
    ccolors: {
      default_background_color: "#222221",
      contact_background_color: "#222221",
      contact_forground_color: "#ffffff",
      buttons_background_color: "#f7f7f7",
      buttons_icon_background_color: "#ffffff",
      buttons_icon_fill_color: "#ffd65a",
      buttons_text_color: "#5e5e5e",
      content_background_color: "#f7f7f7",
      content_button_background_color: "#0099CC",
      content_text_color: "#ffffff",
    },
    image_process: true,
    button_min: 3,
    button_max: 8,
    button_current: 0,
    set_background_crop: false,
    backgroundcrop: { x: 0, y: 0 },
    backgroundzoom: 1,
    backgroundaspect: 4 / 2,
    backgroundresult: [],

    set_business_crop: false,
    businesscrop: { x: 0, y: 0 },
    businesszoom: 1,
    businessaspect: 2 / 2,
    businessresult: [],

    set_profile_crop: false,
    profilecrop: { x: 0, y: 0 },
    profilezoom: 1,
    profileaspect: 2 / 2,
    profileresult: [],
  };

  state = {
    ...this.initState,
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.avatar ? e.target.avatar[0] : e.target.value,
    });
    console.clear();

    let twist = false;
    if (e.target.value.length > 0) {
      twist = true;
    }
    this.handleCardToggleFields(e.target.name, twist);
  };

  onChangeB = (e) => {
    this.setState({
      [e.target.name]: e.target.avatar ? e.target.avatar[0] : e.target.value,
    });

    let twist = false;

    let c = 0;
    let m = this.state.checked;
    m.phone ? c++ : void 0;
    m.alt_mobile ? c++ : void 0;
    m.fax ? c++ : void 0;
    m.office_number ? c++ : void 0;
    m.whatsapp ? c++ : void 0;
    m.website ? c++ : void 0;
    m.waze ? c++ : void 0;
    m.facebook ? c++ : void 0;
    m.twitter ? c++ : void 0;
    m.instagram ? c++ : void 0;
    m.email ? c++ : void 0;
    m.google_plus ? c++ : void 0;
    m.linkdin ? c++ : void 0;
    m.youtube ? c++ : void 0;
    m.tiktok ? c++ : void 0;

    if (c < 8) {
      if (e.target.value.length > 0) {
        twist = true;
      }
      this.handleCardToggleButtonsFields(e.target.name, twist);
    }
  };

  onChangeL = (id, key, e) => {
    let k = this.state[`${id}`];
    k[key] = e.target.value;

    this.setState({
      [`${id}`]: k,
    });
  };

  renderVideo() {
    if (this.state.card_video == "" || this.state.card_video == null) {
    } else {
      let checkVideo = this.state.card_video;
      var newstrs = checkVideo.replace("watch?v=", "embed/");
    }

    const video = (
      <Fragment>
        {this.state.checked.card_video ? (
          <iframe
            width="100%"
            height="350"
            scrolling="no"
            src={"https://www.youtube.com/embed/" + newstrs}
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          ""
        )}
      </Fragment>
    );
    return video;
  }

  handleSurveytitle = (i) => (e) => {
    //debugger;
    let cur_state = [...this.state["survey"]];
    cur_state[0]["title"] = e.target.value;
    this.setState({
      survey: this.state["survey"],
    });
    console.log(this.state.survey);
  };
  handleCardToggleFields(key, checked) {
    this.setState({
      checked: {
        ...this.state.checked,
        [`${key}`]: checked,
      },
    });
  }

  addsurvey = () => {
    //debugger;
    let _limit = 10;
    let cur_length = this.state.survey.length;
    if (cur_length <= _limit) {
      this.state.survey.push("");

      this.setState({
        survey: this.state.survey,
      });
      this.handleCardToggleFields("survey", true);
    } else {
      const { dispatch } = this.props;
      errorHandler(
        "Action is now allowed, please contact support",
        dispatch,
        alertify
      );
    }
  };

  handlesurveyText = (i) => (e) => {
    //debugger;
    let survey = [...this.state.survey];
    survey[i] = e.target.value;
    this.setState({
      survey: survey,
    });
  };

  handleSurveyDelete = (i) => (e) => {
    //debugger;
    let del_survey = [...this.state.survey];
    del_survey.splice(i, 1);
    this.setState(
      {
        survey: del_survey,
      },
      () =>
        this.state.survey.length === 1
          ? this.handleCardToggleFields("survey", false)
          : ""
    );
  };

  handleCardToggleButtonsFields(key, checked) {
    let c = 0;
    let m = this.state.checked;
    m.phone ? c++ : void 0;
    m.alt_mobile ? c++ : void 0;
    m.fax ? c++ : void 0;
    m.office_number ? c++ : void 0;
    m.whatsapp ? c++ : void 0;
    m.website ? c++ : void 0;
    m.waze ? c++ : void 0;
    m.facebook ? c++ : void 0;
    m.twitter ? c++ : void 0;
    m.instagram ? c++ : void 0;
    m.email ? c++ : void 0;
    m.google_plus ? c++ : void 0;
    m.linkdin ? c++ : void 0;
    m.youtube ? c++ : void 0;
    m.tiktok ? c++ : void 0;

    let curr = c;
    // let curr = this.state.button_current;
    let key_check = [...this.state.key_check];
    let pass = false;
    let msg = "";

    if (!checked) {
      let i = 0;
      key_check.splice(i, 1);
      curr--;
      pass = this.state.button_min < c ? true : false;
      // pass = (this.state.button_min < this.state.button_current) ? true : false;
      let lang = $("html").attr("lang").toUpperCase();

      if (lang == "EN") {
        msg =
          "If you want to turn off this button you need to turn another one on. A minimum of 3 buttons are required";
      }

      if (lang == "HE") {
        msg =
          "אם תרצה להדליק כפתור זה תצטרך לכבות אחר, לא ניתן לבחור יותר מ 3 כפתורים.";
      }

      if (lang == "AR") {
        msg =
          "ذا أردت تشغيل هذا الزر، يجب أن تقوم بإيقاف تشغيل زر آخر. يمكن تحديد 3 أزرار كحد أقصى";
      }
    } else if (checked) {
      this.state.key_check.push(key);
      curr++;
      pass = this.state.button_max > c ? true : false;
      // pass = (this.state.button_max > this.state.button_current) ? true : false;
      let lang = $("html").attr("lang").toUpperCase();

      if (lang == "EN") {
        msg =
          "If you want to turn off this button you need to turn another one on. A minimum of 8 buttons are required";
      }

      if (lang == "HE") {
        msg =
          "אם תרצה להדליק כפתור זה תצטרך לכבות אחר, לא ניתן לבחור יותר מ 8 כפתורים.";
      }

      if (lang == "AR") {
        msg =
          "ذا أردت تشغيل هذا الزر، يجب أن تقوم بإيقاف تشغيل زر آخر. يمكن تحديد 8 أزرار كحد أقصى";
      }
    }

    if (pass) {
      this.setState({
        checked: {
          ...this.state.checked,
          [`${key}`]: checked,
        },
        button_current: curr,
        key_check: [...this.state.key_check, key_check],
      });
    } else {
      alertify.error(msg);
    }
  }

  onClick = (e) => {
    let sta;
    if (this.state.card_url_typo === true) {
      if (this.state.card_url.length > 0) {
        sta = true;
      }
    } else {
      sta = true;
    }
    this.setState({
      card_url_typo: sta,
    });
  };

  onBlur = (e) => {
    let sta;
    if (this.state.card_url.length > 0) {
      sta = true;
    } else {
      sta = false;
    }
    this.setState({
      card_url_typo: sta,
    });
  };

  get = () => {
    let { getConfigurations, dispatch, user, errorHandler } = this.props;

    getConfigurations(user.auth.access_token)
      .then((res) => {
        let d = res.data;
        let c = JSON.stringify(d);
        let conf = JSON.parse(c);
        const config = Object.entries(conf);
        let data = {};

        config.forEach((e) => {
          data = { ...data, [e[0]]: e[1] };
        });

        this.setState({
          limits: data,
        });

        dispatch({
          type: actions.GET_CONFIGURATIONS,
          payload: config,
        });
      })
      .catch(errorHandler);
  };

  slugs = () => {
    let { slugs, dispatch, user, errorHandler } = this.props;
    slugs(user.auth.access_token)
      .then((res) => {
        this.setState({
          slugs: res.data.data,
        });

        dispatch({
          type: actions.GET_SLUGS,
          payload: res.data.data,
        });
      })
      .catch(errorHandler);
  };

  getImages = (key, i) => {
    let self = this;

    this.setState({
      image_process: true,
    });

    if (key === "carousel") {
      key = "images";
      if (i) {
        i.forEach(function (ii) {
          axios
            .get(ii, {
              responseType: "arraybuffer",
              headers: {
                "Content-Type":
                  "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
                Accept:
                  "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
              },
            })
            .then((res) => {
              let image = btoa(
                new Uint8Array(res.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              const posts = `data:${res.headers[
                "content-type"
              ].toLowerCase()};base64,${image}`;
              self.handleDefaultImages(posts);
            });
        });
      }
    } else {
      axios
        .get(i, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type":
              "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
            Accept:
              "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
          },
        })
        .then((res) => {
          let image = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          const posts = `data:${res.headers[
            "content-type"
          ].toLowerCase()};base64,${image}`;
          self.setState({
            [`${key}`]: posts,
          });
        })
        .finally(function () {
          self.setState({
            image_process: false,
          });
        });
    }
  };

  handleDefaultImages(image) {
    let defaultImage = [...this.state.defaultImages];
    defaultImage.push(image);
    this.setState({
      defaultImages: defaultImage,
      images: defaultImage,
    });
  }

  isURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  }

  getCard = () => {
    let { getCard, card_id, dispatch, user, errorHandler } = this.props;
    card_id = this.state.card_id;
    getCard(user.auth.access_token, card_id)
      .then((res) => {
        let card = res.data[0];

        this.getImages(
          "profile_image_serve",
          JSON.parse(card.images).profile_image
        );
        this.getImages(
          "business_image_serve",
          JSON.parse(card.images).business_image
        );
        this.getImages(
          "background_image_serve",
          JSON.parse(card.images).background_image
        );
        this.getImages("carousel", JSON.parse(card.images).carousel);

        let m = JSON.parse(card.permission);
        let c = 0;
        m.phone ? c++ : void 0;
        m.alt_mobile ? c++ : void 0;
        m.fax ? c++ : void 0;
        m.office_number ? c++ : void 0;
        m.whatsapp ? c++ : void 0;
        m.website ? c++ : void 0;
        m.waze ? c++ : void 0;
        m.facebook ? c++ : void 0;
        m.twitter ? c++ : void 0;
        m.instagram ? c++ : void 0;
        m.email ? c++ : void 0;
        m.google_plus ? c++ : void 0;
        m.linkdin ? c++ : void 0;
        m.youtube ? c++ : void 0;
        m.tiktok ? c++ : void 0;

        this.setState({
          card: card,
          id: card.id,
          card_title: card.card_title,
          card_video: card.card_video,
          card_url: card.slug,
          status: card.status,
          about_us: JSON.parse(card.about_us),
          our_vision: JSON.parse(card.vision),
          profile_image: JSON.parse(card.images).profile_image,
          profile_image_display: JSON.parse(card.images).profile_image,
          business_image: JSON.parse(card.images).business_image,
          background_image: JSON.parse(card.images).background_image,
          our_application: JSON.parse(card.applications),
          card_url_typo: true,
          card_links: JSON.parse(card.card_links),
          our_stories: JSON.parse(card.stories),
          activity_hour: JSON.parse(card.activity_hour),
          articles: JSON.parse(card.articles),
          employees: JSON.parse(card.employees),
          recommendations: JSON.parse(card.recommendations),
          slugCan: "",
          checked: JSON.parse(card.permission),
          survey: JSON.parse(card.survey),
          colors:
            card._colors !== null
              ? JSON.parse(card._colors)
              : {
                  default_background_color: "#222221",
                  contact_background_color: "#222221",
                  contact_forground_color: "#ffffff",
                  buttons_background_color: "#f7f7f7",
                  buttons_icon_background_color: "#ffffff",
                  buttons_icon_fill_color: "#ffd65a",
                  buttons_text_color: "#5e5e5e",
                  content_background_color: "#f7f7f7",
                  content_button_background_color: "#0099CC",
                  content_text_color: "#ffffff",
                },
          ccolors:
            card._colors !== null
              ? JSON.parse(card._colors)
              : {
                  default_background_color: "#222221",
                  contact_background_color: "#222221",
                  contact_forground_color: "#ffffff",
                  buttons_background_color: "#f7f7f7",
                  buttons_icon_background_color: "#ffffff",
                  buttons_icon_fill_color: "#ffd65a",
                  buttons_text_color: "#5e5e5e",
                  content_background_color: "#f7f7f7",
                  content_button_background_color: "#0099CC",
                  content_text_color: "#ffffff",
                },
          leave_a_message_label: JSON.parse(card._info).leave_a_message_label
            ? JSON.parse(card._info).leave_a_message_label
            : "Leave A Message",
          heading_survey: card.heading_survey,
          heading_article: card.heading_article,
          heading_employee: card.heading_employee,
          heading_recommandation: card.heading_recommandation,
          heading_qrcode: card.heading_qrcode,
          heading_story: card.heading_story,
          heading_active_hour: card.heading_active_hour,
          heading_leave_msg: card.heading_leave_msg,

          leave_a_message: JSON.parse(card._info).leave_a_message
            ? JSON.parse(card._info).leave_a_message
            : "",
          name: JSON.parse(card._info).name,
          email: JSON.parse(card._info).email
            ? JSON.parse(card._info).email
            : "",
          position: JSON.parse(card._info).position,
          specialization: JSON.parse(card._info).specialization,
          background: JSON.parse(card._info).background,
          field_title:
            card._info.length !== 0
              ? Object.keys(JSON.parse(card._info).field)[0]
              : "Background",
          field_value:
            card._info.length !== 0
              ? JSON.parse(card._info).field[
                  Object.keys(JSON.parse(card._info).field)
                ]
              : "",
          phone: JSON.parse(card._contacts).phone,
          alt_mobile: JSON.parse(card._contacts).alt_mobile,
          fax: JSON.parse(card._contacts).fax,
          office_number: JSON.parse(card._contacts).office_number,
          whatsapp: JSON.parse(card._contacts).whatsapp,
          language: card.language,
          language_val: card.language,
          website: JSON.parse(card._socials).website,
          waze: JSON.parse(card._socials).waze,
          facebook: JSON.parse(card._socials).facebook,
          instagram: JSON.parse(card._socials).instagram,
          twitter: JSON.parse(card._socials).twitter,
          linkdin: JSON.parse(card._socials).linkdin,
          google_plus: JSON.parse(card._socials).google_plus,
          youtube: JSON.parse(card._socials).youtube,
          tiktok: JSON.parse(card._socials).tiktok,
          button_current: c,
        });

        dispatch({
          type: actions.GET_CARD,
          payload: res.data.data,
        });

        this.setState({
          process: false,
        });

        console.clear();
        console.log("survey data");
        console.log(this.state.survey);
      })
      .catch(errorHandler);
  };

  cardUrl = (e) => {
    const va = this.state.slugs;
    let re = /^[^-?+)(*&^%$#@|;!~`"'\/\\=.<>,_][a-zA-Z0-9-]*$/;
    if (va.length > 0) {
      if (e.target.value.length === 0) {
        this.setState({
          slugCan: "",
          [e.target.name]: e.target.value,
        });
      } else if (va.includes(e.target.value)) {
        $(".available-status").css({ color: "red" });
        this.setState({
          slugCan: "Not Available",
          [e.target.name]: e.target.value,
        });
      } else {
        if (e.target.value.match(re) !== null) {
          if (e.target.value.length !== null && e.target.value.length < 15) {
            $(".available-status").css({ color: "green" });
            this.setState({
              slugCan: "Available",
              [e.target.name]: e.target.value,
            });
          }
        }
      }
    }
  };

  // Images
  getProfileImage(files) {
    let im = new Image();
    im.src = files.base64;
    im.onload = () => {
      if (im.width >= 512 && im.height >= 512) {
        this.setState({
          profile_image: files.base64,
          set_profile_crop: true,
          profile_image_serve: "",
        });
        this.handleCardToggleFields("profile_image", true);
      } else {
        alert("Please upload Recommended resolution image");
        var i = document.getElementsByClassName("profile_image");
        i[0].children[1].value = "";
      }
    };
  }
  getBusinessImage(files) {
    let im = new Image();
    im.src = files.base64;
    im.onload = () => {
      if (im.width >= 512 && im.height >= 512) {
        this.setState({
          business_image: files.base64,
          set_business_crop: true,
          business_image_serve: "",
        });
        this.handleCardToggleFields("business_image", true);
      } else {
        alertify.warning("Please upload Recommended resolution image!");
        this.setState({
          business_image: files.base64,
          set_business_crop: true,
          business_image_serve: "",
        });
        this.handleCardToggleFields("business_image", true);
        // var i = document.getElementsByClassName("business_image");
        // i[0].children[1].value = "";
      }
    };
  }
  getBackgroundImage(files) {
    let im = new Image();
    im.src = files.base64;
    im.onload = () => {
      if (im.width >= 510 && im.height >= 255) {
        this.setState({
          background_image: files.base64,
          set_background_crop: true,
          background_image_serve: "",
        });
        this.handleCardToggleFields("background_image", true);
      } else {
        alert("Please upload Recommended resolution image");
        var i = document.getElementsByClassName("background_image");
        i[0].children[1].value = "";
      }
    };
  }
  getAImages(image, file) {
    let _limit = this.state.limits.limit_images;
    let f_length = file.length;
    let cur_length = f_length;

    if (cur_length <= _limit) {
      if (image.length > 0) {
        this.setState({ images: file });
        this.handleCardToggleFields("images", true);
      } else if (file.length === 0) {
        this.handleCardToggleFields("images", false);
        this.setState({ images: file });
      } else {
        this.setState({ images: file });
      }
    } else {
      file.slice(-1);
      const { dispatch } = this.props;
      let lang = $("html").attr("lang").toUpperCase();

      if (lang == "EN") {
        alertify.error("You can add upto 5 images in your gallery.");

        // errorHandler(
        // 	"Can't Add More than " + _limit + " Images",
        // 	dispatch,
        // 	alertify
        // );
      }

      if (lang == "HE") {
        alertify.error("אתה יכולה להוסיף עד 5 תמונות מהגלריה שלך");

        // errorHandler(
        // 	"לא ניתן להוסיף יותר מ" + _limit + " Images",
        // 	dispatch,
        // 	alertify
        // );
      }

      if (lang == "AR") {
        alertify.error("يمكنك إضافة حتى 5 صور من ألبوم الصور لديك");

        // errorHandler(
        // 	"لا يمكن إضافة أكثر من " + _limit + " Images",
        // 	dispatch,
        // 	alertify
        // );
      }

      $(".uploadPictureContainer:last-child").children()[0].click();
    }
  }

  addLinks = (e) => {
    let _limit, cur_length, msg, l;
    let lang = $("html").attr("lang").toUpperCase();

    if (lang == "EN") {
      msg = "Can't Add More than " + _limit;
    }

    if (lang == "HE") {
      msg = "לא ניתן להוסיף יותר מ " + _limit;
    }

    if (lang == "AR") {
      msg = "لا يمكن إضافة أكثر من " + _limit;
    }

    if (e === "card_links") {
      _limit = this.state.limits.limit_custom_links;
      cur_length = this.state.card_links.length;
      let lang = $("html").attr("lang").toUpperCase();

      if (lang == "EN") {
        msg = +" Card Links";
      }

      if (lang == "HE") {
        msg = +" קישורים לכרטיס";
      }

      if (lang == "AR") {
        msg = +" روابط البطاقة";
      }
    } else if (e === "articles") {
      _limit = this.state.limits.limit_articles;
      cur_length = this.state.articles.length;
      let lang = $("html").attr("lang").toUpperCase();

      if (lang == "EN") {
        msg = +" Articles";
      }

      if (lang == "HE") {
        msg = +" מאמרים";
      }

      if (lang == "AR") {
        msg = +" المقالات";
      }
    } else if (e === "employees") {
      _limit = this.state.limits.limit_employees;
      cur_length = this.state.employees.length;
      let lang = $("html").attr("lang").toUpperCase();

      if (lang == "EN") {
        msg = +" Employees";
      }

      if (lang == "HE") {
        msg = +" עובדים";
      }

      if (lang == "AR") {
        msg = +" الموظفون";
      }
    } else if (e === "recommendations") {
      _limit = this.state.limits.limit_recommendations;
      cur_length = this.state.recommendations.length;
      let lang = $("html").attr("lang").toUpperCase();

      if (lang == "EN") {
        msg = +" Recommendations";
      }

      if (lang == "HE") {
        msg = +" המלצות";
      }

      if (lang == "AR") {
        msg = +" التوصيات";
      }
    }

    if (cur_length < _limit) {
      this.state[`${e}`].push({ title: "", link: "" });

      this.setState({
        [`${e}`]: this.state[`${e}`],
      });
      this.handleCardToggleFields(e, true);
    } else {
      const { dispatch } = this.props;
      errorHandler(msg, dispatch, alertify);
    }
  };

  handleLinkText = (f, i, key) => (e) => {
    let cur_state = [...this.state[`${f}`]];

    cur_state[i][`${key}`] = e.target.value;
    this.setState({
      [`${f}`]: this.state[`${f}`],
    });
    if (e.target.value.length > 0) {
      this.handleCardToggleFields(f, true);
    } else {
      this.handleCardToggleFields(f, false);
    }
  };

  handleLinkDelete = (f, i) => (e) => {
    let del_card_links = [...this.state[`${f}`]];

    del_card_links.splice(i, 1);
    this.setState(
      {
        [`${f}`]: del_card_links,
      },
      () =>
        this.state[`${f}`].length === 0
          ? this.handleCardToggleFields([`${f}`], false)
          : ""
    );
  };

  handleAppLinkText = (key) => (e) => {
    let app = this.state.our_application;
    app[`${key}`] = e.target.value;
    this.setState(
      {
        our_application: this.state.our_application,
      },
      () =>
        this.state.our_application.text == null ||
        (this.state.our_application.text == "" &&
          this.state.our_application.link == null) ||
        this.state.our_application.link == ""
          ? this.handleCardToggleFields("our_application", false)
          : this.handleCardToggleFields("our_application", true)
    );
  };

  addStory = () => {
    let _limit = this.state.limits.limit_success_stories;
    let cur_length = this.state.our_stories.length;

    if (cur_length < _limit) {
      this.state.our_stories.push("");

      this.setState({
        our_stories: this.state.our_stories,
      });
      this.handleCardToggleFields("our_stories", true);
    } else {
      const { dispatch } = this.props;
      let lang = $("html").attr("lang").toUpperCase();

      if (lang == "EN") {
        errorHandler(
          "Can't Add More than " + _limit + " Success Stories",
          dispatch,
          alertify
        );
      }

      if (lang == "HE") {
        errorHandler(
          "לא ניתן להוסיף יותר מ" + _limit + " סיפורי הצלחה",
          dispatch,
          alertify
        );
      }

      if (lang == "AR") {
        errorHandler(
          "لا يمكن إضافة أكثر من " + _limit + " صص النجاح",
          dispatch,
          alertify
        );
      }
    }
  };

  handleStoryText = (i) => (e) => {
    let our_stories = [...this.state.our_stories];
    our_stories[i] = e.target.value;
    this.setState({
      our_stories: our_stories,
    });
  };

  handleStoryDelete = (i) => (e) => {
    let del_story = [...this.state.our_stories];
    del_story.splice(i, 1);
    this.setState(
      {
        our_stories: del_story,
      },
      () =>
        this.state.our_stories.length === 0
          ? this.handleCardToggleFields("our_stories", false)
          : ""
    );
  };

  addActivity = () => {
    this.state.activity_hour.push("");

    this.setState({
      activity_hour: this.state.activity_hour,
    });
    this.handleCardToggleFields("activity_hour", true);
  };

  handleActivityText = (i) => (e) => {
    let activity_hour = [...this.state.activity_hour];
    activity_hour[i] = e.target.value;
    this.setState({
      activity_hour: activity_hour,
    });
  };

  handleActivityDelete = (i) => (e) => {
    let del_activity = [...this.state.activity_hour];
    del_activity.splice(i, 1);
    this.setState(
      {
        activity_hour: del_activity,
      },
      () =>
        this.state.activity_hour.length === 0
          ? this.handleCardToggleFields("activity_hour", false)
          : ""
    );
  };

  editCard = () => {
    let { editCard, dispatch, alertify, user, errorHandler, history } =
      this.props;
    let athis = this;

    let va = this.state.slugs;
    if (
      va.includes(this.state.card_url) &&
      this.state.card.slug !== this.state.card_url
    ) {
      let lang = $("html").attr("lang").toUpperCase();

      if (lang == "EN") {
        alertify.error("Please Choose an Available Url");
      }

      if (lang == "HE") {
        alertify.error("אנא בחר כתובת פנויה");
      }

      if (lang == "AR") {
        alertify.error("يرجى اختيار عنوان URL متاح");
      }

      // $("html, body").animate(
      //   { scrollTop: $(".app-page-title").offset().top },
      //   500,
      //   function () {
      //     athis.move("#tab-eg15-0", "#basic-info-btn");
      //   }
      // );
      return;
    }

    // let hs = this.state.key_check;
    // hs.forEach(e => {
    //   if(this.state[e] === null || this.state[e] === '' || this.state[e] === undefined){
    //     alertify.error("Fill all selected fields");
    //     $('html, body').animate({ scrollTop: $('.app-page-title').offset().top }, 500, function(){
    //       athis.move('#tab-eg15-0','#basic-info-btn')
    //       return;
    //     });
    //   }
    // });

    let curr_but = this.state.button_current;
    if (curr_but < 3 || curr_but > 8) {
      let lang = $("html").attr("lang").toUpperCase();

      if (lang == "EN") {
        alertify.error("Please select in the valid range");
      }

      if (lang == "HE") {
        alertify.error("אנא בחר טווח תקין");
      }

      if (lang == "AR") {
        alertify.error("يرجى التحديد في النطاق المسموح به");
      }

      // $("html, body").animate(
      //   { scrollTop: $(".app-page-title").offset().top },
      //   500,
      //   function () {
      //     athis.move("#tab-eg15-3", "#card-info-btn");
      //   }
      // );
      return;
    }

    this.setState({
      process: true,
    });

    let {
      name,
      email,
      position,
      specialization,
      background,
      field_title,
      field_value,
      phone,
      alt_mobile,
      fax,
      office_number,
      whatsapp,
      language,
      website,
      waze,
      facebook,
      instagram,
      twitter,
      linkdin,
      google_plus,
      youtube,
      tiktok,
      id,
      card_title,
      card_url,
      leave_a_message,
      leave_a_message_label,
      status,
      images,

      profileresult,
      businessresult,
      backgroundresult,
      profile_image,
      business_image,
      background_image,
      card_video,
      users,
      checked,
      card_links,
      colors,
      activity_hour,
      articles,
      about_us,
      our_vision,
      our_application,
      our_stories,
      employees,
      recommendations,
      user_id,
      survey,
      heading_leave_msg,
      heading_survey,
      heading_article,
      heading_employee,
      heading_recommandation,
      heading_qrcode,
      heading_story,
      heading_active_hour,
    } = { ...this.state };

    const params = {
      name,
      email,
      position,
      specialization,
      background,
      field_title,
      field_value,
      phone,
      alt_mobile,
      fax,
      office_number,
      whatsapp,
      language,
      website,
      waze,
      facebook,
      tiktok,
      instagram,
      twitter,
      linkdin,
      google_plus,
      youtube,
      id,
      card_title,
      card_url,
      leave_a_message,
      leave_a_message_label,
      status,
      images,
      user_id,
      profile_image: profileresult.length !== 0 ? profileresult : profile_image,
      business_image:
        businessresult.length !== 0 ? businessresult : business_image,
      background_image:
        backgroundresult.length !== 0 ? backgroundresult : background_image,
      // profile_image, business_image, background_image,
      card_video,
      users,
      checked,
      card_links,
      colors,
      activity_hour,
      articles,
      about_us,
      our_vision,
      our_application,
      our_stories,
      employees,
      recommendations,
      survey,
      heading_leave_msg,
      heading_survey,
      heading_article,
      heading_employee,
      heading_recommandation,
      heading_qrcode,
      heading_story,
      heading_active_hour,
    };

    let err = Validator(params);
    if (err.length !== 0) {
      //debugger;
      let i = 0;
      let sw = 0;
      let em = 0;
      let ar = 0;
      let sr = 0;
      while (i <= err.length) {
        if (err[i] !== undefined) {
          $(err[i]).show();
        }
        if (err[i] === "#card-title-error") {
          sw = 1;
        }
        if (err[i] === "#employess_error") {
          em = 1;
        }
        if (err[i] === "#articles_error") {
          ar = 1;
        }
        if (err[i] === "#stories_error") {
          sr = 1;
        }
        i++;
      }
      if (err.length === 1 && em === 1) {
        let lang = $("html").attr("lang").toUpperCase();

        if (lang == "EN") {
          alertify.error("Please fill employee's data");
        }

        if (lang == "HE") {
          alertify.error("אנא מלא פרטי עובד");
        }

        if (lang == "AR") {
          alertify.error("يرجى ملء بيانات الموظف");
        }
        this.move("#tab-eg15-4", "#card-content-btn");
        this.setState({
          process: false,
        });
        return;
      }

      if (err.length === 2 && em === 1) {
        let lang = $("html").attr("lang").toUpperCase();

        if (lang == "EN") {
          alertify.error("Please fill employee's data");
        }

        if (lang == "HE") {
          alertify.error("אנא מלא פרטי עובד");
        }

        if (lang == "AR") {
          alertify.error("يرجى ملء بيانات الموظف");
        }
        this.move("#tab-eg15-4", "#card-content-btn");
        this.setState({
          process: false,
        });
        return;
      }
      if (err.length === 1 && ar === 1) {
        let lang = $("html").attr("lang").toUpperCase();

        if (lang == "EN") {
          alertify.error("Please fill articles data");
        }

        if (lang == "HE") {
          alertify.error("אנא מלא פרטם");
        }

        if (lang == "AR") {
          alertify.error("يرجى ملء بيانات الموظف");
        }
        this.move("#tab-eg15-4", "#card-content-btn");
        this.setState({
          process: false,
        });
        return;
      }

      if (err.length === 2 && ar === 1) {
        let lang = $("html").attr("lang").toUpperCase();

        if (lang == "EN") {
          alertify.error("Please fill articles data");
        }

        if (lang == "HE") {
          alertify.error("אנא מלא פרטם");
        }

        if (lang == "AR") {
          alertify.error("يرجى ملء بيانات الموظف");
        }
        this.move("#tab-eg15-4", "#card-content-btn");
        this.setState({
          process: false,
        });
        return;
      }
      if (err.length === 1 && sw === 1) {
        let lang = $("html").attr("lang").toUpperCase();

        if (lang == "EN") {
          alertify.error("Please Choose card title");
        }

        if (lang == "HE") {
          alertify.error("אנא בחר כותרת לכרטיס");
        }

        if (lang == "AR") {
          alertify.error("يرجى اختيار عنوان البطاقة");
        }
        this.move("#tab-eg15-0", "#basic-info-btn");
        this.setState({
          process: false,
        });
        return;
      }
      if (err.length >= 1 && sr === 1) {
        alertify.error("Please Fill Story Data");
        this.move("#tab-eg15-4", "#card-content-btn", "#success_stories");
        this.setState({
          process: false,
        });
        return;
      }

      if (err.length === 2 && sw === 1) {
        this.move("#tab-eg15-0", "#basic-info-btn");
      } else {
        this.validateURL();
        this.move("#tab-eg15-3", "#card-info-btn");
      }
      this.setState({
        process: false,
      });
      return;
    }

    editCard(user.auth.access_token, id, params)
      .then((res) => {
        dispatch({
          type: actions.EDIT_CARD,
          payload: res.data.data,
        });
        history.push("/cards");

        // this.slugs();

        // this.setState({
        //   process: false
        // });

        // alertify.success(res.data.message);
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          processing: false,
        });
      });
  };

  changeLang = (e) => {
    this.setState({ language: e.value, language_val: e.name });
  };

  move = (k, e, f = "") => {
    //debugger;
    $("#btn-group a").removeClass("active");
    $(".tab-content div").removeClass("active");
    $(k).addClass("active");
    $(e).addClass("active");
    // $("html, body").animate(
    //   { scrollTop: $(".app-page-title").offset().top },
    //   500
    // );
    if (e == "#card-content-btn" && f == "") {
      // $("html, body").animate(
      //   {
      //     scrollTop: $(".emp").offset().top,
      //   },
      //   2000
      // );
      return;
    }
    if (f == "#success_stories") {
      // $("html, body").animate(
      //   {
      //     scrollTop: $("#success_story").offset().top,
      //   },
      //   1000
      // );
      return;
    }
  };

  togglePicker(key, displayColorPicker) {
    this.setState({
      displayColorPicker: {
        ...this.state.displayColorPicker,
        [`${key}`]: !displayColorPicker,
      },
    });
  }

  closePicker = (key) => {
    this.setState({
      displayColorPicker: {
        ...this.state.displayColorPicker,
        [`${key}`]: false,
      },
    });
  };

  handleColor = (key, color, event) => {
    this.setState({
      colors: {
        ...this.state.colors,
        [`${key}`]: color.hex,
      },
    });
    $("#" + key + "_show").css("background-color", color.hex);
  };

  componentWillMount() {
    this.get();
    this.getCard();
    this.slugs();
  }
  pickerCancel = (key) => {
    //debugger;
    this.setState({
      colors: {
        ...this.state.colors,
        [`${key}`]: this.state.ccolors[`${key}`],
      },
    });
    $("#" + key + "_show").css(
      "background-color",
      this.state.ccolors[`${key}`]
    );
    this.setState({
      displayColorPicker: {
        ...this.state.displayColorPicker,
        [`${key}`]: false,
      },
    });
  };

  componentDidUpdate() {
    this.mobileScripts();
  }

  mobileScripts() {
    //debugger;
    if (this.state.colors) {
      if (this.state.button_current) {
        if (this.state.button_current === 6) {
          $("#custom-card-wrapper .col-3").css("flex", "0 0 26%");
          $("#custom-card-wrapper .col-3").css("max-width", "unset");
        } else if (this.state.button_current === 5) {
          $("#custom-card-wrapper .col-3").css("flex", "0 0 26%");
          $("#custom-card-wrapper .col-3").css("max-width", "unset");
        } else {
          $("#custom-card-wrapper .col-3").css("flex", "");
          $("#custom-card-wrapper .col-3").css("max-width", "");
        }
      }

      $("#custom-card-wrapper #about-us-container").css(
        "background-color",
        this.state.colors.contact_background_color
      );
      $("#custom-card-wrapper #about-us-title").css(
        "color",
        this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #about-us-title").css(
        "border-bottom",
        "2px solid " + this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #about-us-content p").css(
        "color",
        this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #reach-us-button").css(
        "background-color",
        this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #add-contact").css(
        "background-color",
        this.state.colors.content_button_background_color
      );
      $("#custom-card-wrapper #add-contact").css(
        "color",
        this.state.colors.content_text_color
      );
      $("#custom-card-wrapper #add-contact .svg-icon").css(
        "fill",
        this.state.colors.content_text_color
      );
      $("#custom-card-wrapper #share-link-background").css(
        "background-color",
        this.state.colors.default_background_color
      );
      $("#custom-card-wrapper #share-link-background").css(
        "background-color",
        this.state.colors.default_background_color
      );
      $("#custom-card-wrapper .logo-img").css(
        "background-color",
        this.state.colors.contact_background_color
      );
      $("#custom-card-wrapper .about-us-section-row").css(
        "background-color",
        this.state.colors.contact_background_color
      );
      $("#custom-card-wrapper #name1, #name2").css(
        "color",
        this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #designations").css(
        "color",
        this.state.colors.contact_forground_color
      );
      $("#custom-card-wrapper #reach-us-icons").css(
        "background-color",
        this.state.colors.buttons_background_color
      );
      $("#custom-card-wrapper .icons-div a").css(
        "background-color",
        this.state.colors.buttons_icon_background_color
      );
      $("#custom-card-wrapper .icons-div a").css(
        "border-color",
        this.state.colors.buttons_icon_fill_color
      );
      $("#custom-card-wrapper .icons-div a .svg-icon").css(
        "fill",
        this.state.colors.buttons_icon_fill_color
      );
      $("#custom-card-wrapper .icons-div p").css(
        "color",
        this.state.colors.buttons_text_color
      );
      $("#custom-card-wrapper #dropdown-background").css(
        "background-color",
        this.state.colors.content_background_color
      );
      $("#custom-card-wrapper .resume-wrap").css(
        "background-color",
        this.state.colors.content_button_background_color
      );
      $("#custom-card-wrapper .date button").css(
        "background",
        this.state.colors.content_button_background_color
      );
      $("#custom-card-wrapper .info-prop-p").css(
        "color",
        this.state.colors.content_text_color
      );
      $("#custom-card-wrapper .resume-wrap h6").css(
        "color",
        this.state.colors.content_text_color
      );
      $("#custom-card-wrapper .resume-wrap").css(
        "border-color",
        this.state.colors.content_button_background_color
      );
    }
  }
  getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  getCroppedImage(key, imagesrc, croppedAreaPixels) {
    if (!imagesrc.includes("http")) {
      if (imagesrc) {
        const nimage = new Image();
        nimage.src = imagesrc;
        let image = nimage;
        const pixelCrop = croppedAreaPixels;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        var imgWidth = image.width || image.naturalWidth;
        var imgHeight = image.height || image.naturalHeight;
        const safeArea = Math.max(imgWidth, imgHeight) * 2;
        const rotation = 0;

        canvas.width = safeArea;
        canvas.height = safeArea;

        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.rotate(this.getRadianAngle(rotation));
        ctx.translate(-safeArea / 2, -safeArea / 2);

        ctx.drawImage(
          image,
          safeArea / 2 - imgWidth * 0.5,
          safeArea / 2 - imgHeight * 0.5
        );

        const data = ctx.getImageData(0, 0, safeArea, safeArea);

        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        ctx.putImageData(
          data,
          0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
          0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
        );

        return new Promise((resolve) => {
          canvas.toBlob((file) => {
            resolve(URL.createObjectURL(file));
          }, "image/jpeg");
          this.setState({
            [`${key}`]: canvas.toDataURL("image/jpeg"),
            // set_background_crop: false
          });
        });
      }
    }
  }

  onCropChange = (key, crop) => {
    this.setState({ [`${key}`]: crop });
  };

  onCropComplete = (key, imagesrc, croppedArea, croppedAreaPixels) => {
    if (croppedAreaPixels.height === 0) {
      return false;
    }
    if (isNaN(croppedAreaPixels.height)) {
      return false;
    }
    console.log(key, imagesrc, croppedAreaPixels);
    this.getCroppedImage(key, imagesrc, croppedAreaPixels);
  };

  onZoomChange = (key, zoom) => {
    this.setState({ [`${key}`]: zoom });
  };

  render() {
    var displayBlock = {
      display: "block",
    };
    let lang = $("html").attr("lang").toUpperCase();
    let lang1 = this.state.language;
    let style = this.state.language == "EN" ? "ltr" : "rtl";
    let textalign =
      this.state.language == "HE"
        ? "right"
        : this.state.language == "AR"
        ? "right"
        : "";
    let margin_right =
      this.state.language == "HE"
        ? "unset"
        : this.state.language == "AR"
        ? "unset"
        : "";
    let margin_left =
      this.state.language == "HE"
        ? "10px"
        : this.state.language == "AR"
        ? "10px"
        : "";
    let t = T[0];
    let ss, ff;
    let counter_button = 0;
    let cc = this.state.process ? "hide-it" : "";
    ss = this.state.process ? (ss = "hide-it") : "app-page-title";
    ff = this.state.process ? (ff = "hide-it") : "main-card mb-3 card";
    return (
      <div>
        <div>
          {this.state.process ? (
            <div class="the-loader">
              <Loader
                type="Triangle"
                color="#00adb4"
                height={100}
                width={100}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div id="create-card-form" className={cc}>
          <div className={ss}>
            <div className="page-title-wrapper">
              <div className="page-title-heading">
                <div className="text-left">
                  {t.EditCard[lang]} - {this.state.card_title}
                  <div className="page-title-subheading">
                    {t.CreateANewCard_p[lang]}
                    <br />
                    <span>{t.allcard[lang]}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-right">
            <button
              class="mb-2 mr-2 btn-hover-shine btn btn-wide btn-dark btn-tot-UP"
              type="button"
              onClick={this.editCard}
            >
              {t.Update[lang]}
            </button>
          </div>
          <div className="row">
            <div className="col-md-3 ">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <div id="custom-card-wrapper" style={{ direction: style }}>
                  <link
                    href="/assets/css/c-c.css"
                    rel="stylesheet"
                    type="text/css"
                    media="all"
                  />
                  <div id="header-bg">
                    <div className="container">
                      <div className="row">
                        {this.state.checked.background_image &&
                        (this.state.background_image !== undefined ||
                          this.state.background_image !== "") ? (
                          this.state.backgroundresult == "" ? (
                            <img
                              src={this.state.background_image_serve}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                            />
                          ) : (
                            <img
                              src={this.state.backgroundresult}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                            />
                          )
                        ) : (
                          <img
                            src="https://dev.easy-card.co/assets/images/business/1.jpg"
                            alt=""
                            style={{ width: "100%", height: "100%" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div id="divider"></div>

                  {this.state.checked.business_image ||
                  this.state.business_image ||
                  this.state.checked.profile_image ||
                  this.state.profile_image ||
                  this.state.name ||
                  this.state.position ||
                  this.state.specialization ? (
                    <div className="container" id="share-link-background">
                      <div className="row">
                        {this.state.checked.business_image &&
                        this.state.business_image ? (
                          <div style={{ display: "contents" }}>
                            <div className="logo-img img-properties d-flex align-items-center justify-content-center checking">
                              {this.state.businessresult == "" ? (
                                <img
                                  src={this.state.business_image}
                                  className="img-responsive"
                                />
                              ) : (
                                <img
                                  src={this.state.businessresult}
                                  className="img-responsive"
                                />
                              )}
                            </div>
                            <br />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="container about-us-section">
                          <div className="row about-us-section-row">
                            {this.state.checked.profile_image &&
                            this.state.profile_image ? (
                              <div>
                                <div
                                  className="col-xs-5 about-us-section-row-img"
                                  style={{ float: "left" }}
                                >
                                  {this.state.profileresult == "" ? (
                                    <img
                                      src={this.state.profile_image}
                                      className="img-responsive person-img"
                                      height="150"
                                    />
                                  ) : (
                                    <img
                                      src={this.state.profileresult}
                                      className="img-responsive person-img"
                                      height="150"
                                    />
                                  )}
                                </div>
                                <div className="col-xs-6 my-auto name-title-div">
                                  <center>
                                    <p id="name1">{this.state.name}</p>
                                    <p id="name2"></p>
                                    <p id="designations">
                                      {this.state.position}
                                      <br />
                                      {this.state.specialization}
                                    </p>
                                  </center>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="col-xs-12 m-auto"
                                style={{ paddingTop: "2em" }}
                              >
                                <center>
                                  <p id="name1">{this.state.name}</p>
                                  <p id="name2"></p>
                                  <p id="designations">
                                    {this.state.position}
                                    <br />
                                    {this.state.specialization}
                                  </p>
                                </center>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <div id="reach-us-icons">
                    <div className="container">
                      <div className="row">
                        {counter_button < 8 &&
                        this.state.checked.phone === true &&
                        this.state.phone !== undefined &&
                        this.state.phone !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a href={"tel:" + this.state.phone}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 4.5 4.5"
                                className="svg-icon mobile-icon"
                                aria-hidden="true"
                              >
                                <path
                                  id="XMLID_3_"
                                  class="st0"
                                  d="M3.2,0H1.3C1.1,0,1,0.1,1,0.3v3.8c0,0.2,0.1,0.3,0.3,0.3h1.9c0.2,0,0.3-0.1,0.3-0.3V0.3
			C3.5,0.1,3.4,0,3.2,0z M1.8,0.2h0.9c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1H1.8C1.7,0.4,1.7,0.3,1.8,0.2C1.7,0.2,1.7,0.2,1.8,0.2z
			 M2.2,4.2C2.1,4.2,2,4.1,2,4s0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2C2.4,4.1,2.3,4.2,2.2,4.2z M3.3,3.5H1.2V0.6h2.1V3.5z"
                                />
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "Mobile" : ""}
                              {lang1 === "HE" ? "נייד" : ""}
                              {lang1 === "AR" ? "رقم المحمول" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {counter_button < 8 &&
                        this.state.checked.alt_mobile === true &&
                        this.state.alt_mobile !== undefined &&
                        this.state.alt_mobile !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a href={"tel:" + this.state.alt_mobile}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 4.5 4.5"
                                className="svg-icon mobile-icon"
                                aria-hidden="true"
                              >
                                <path
                                  id="XMLID_3_"
                                  class="st0"
                                  d="M3.2,0H1.3C1.1,0,1,0.1,1,0.3v3.8c0,0.2,0.1,0.3,0.3,0.3h1.9c0.2,0,0.3-0.1,0.3-0.3V0.3
			C3.5,0.1,3.4,0,3.2,0z M1.8,0.2h0.9c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1H1.8C1.7,0.4,1.7,0.3,1.8,0.2C1.7,0.2,1.7,0.2,1.8,0.2z
			 M2.2,4.2C2.1,4.2,2,4.1,2,4s0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2C2.4,4.1,2.3,4.2,2.2,4.2z M3.3,3.5H1.2V0.6h2.1V3.5z"
                                />
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "Alternative Mobile" : ""}
                              {lang1 === "HE" ? "טלפון נוסף" : ""}
                              {lang1 === "AR" ? "Alternative Mobile" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {counter_button < 8 &&
                        this.state.checked.office_number === true &&
                        this.state.office_number !== undefined &&
                        this.state.office_number !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a href={"tel:" + this.state.office_number}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 4.5 4.5"
                                className="svg-icon phone-icon"
                                aria-hidden="true"
                              >
                                <path
                                  class="st0"
                                  d="M4.3,0.2L3.4,0C3.3,0,3.2,0,3.2,0.1l-0.4,1c0,0.1,0,0.2,0.1,0.2l0.5,0.4C3,2.5,2.5,3,1.8,3.3
	L1.4,2.8C1.3,2.7,1.2,2.7,1.1,2.8l-1,0.4C0,3.2,0,3.3,0,3.4l0.2,0.9c0,0.1,0.1,0.2,0.2,0.2c2.3,0,4.1-1.8,4.1-4.1
	C4.5,0.3,4.4,0.2,4.3,0.2z"
                                />
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "Office" : ""}
                              {lang1 === "HE" ? "משרד" : ""}
                              {lang1 === "AR" ? "رقم المكتب" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {counter_button < 8 &&
                        this.state.checked.whatsapp === true &&
                        this.state.whatsapp !== undefined &&
                        this.state.whatsapp !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            {lang1 === "EN" ? (
                              isMobile ? (
                                <a
                                  href={
                                    "whatsapp://send?text=Use the following link to view" +
                                    this.state.name +
                                    "’s digital card: " +
                                    this.state.pageurl +
                                    " %0a" +
                                    "%0a%0aPowered by Easy Card https://dev.easy-card.co/"
                                  }
                                  data-action="share/whatsapp/share"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 4.5 4.5"
                                    className="svg-icon whatsapp-icon"
                                    aria-hidden="true"
                                  >
                                    <path
                                      class="st0"
                                      d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                                    />
                                  </svg>
                                </a>
                              ) : (
                                <a
                                  href={
                                    "https://api.whatsapp.com/send?text=Use the following link to view" +
                                    this.state.name +
                                    "’s digital card: " +
                                    this.state.pageurl +
                                    " %0a" +
                                    "%0a%0aPowered by Easy Card https://dev.easy-card.co/"
                                  }
                                  data-action="share/whatsapp/share"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 4.5 4.5"
                                    className="svg-icon whatsapp-icon"
                                    aria-hidden="true"
                                  >
                                    <path
                                      class="st0"
                                      d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                                    />
                                  </svg>
                                </a>
                              )
                            ) : (
                              ""
                            )}

                            {lang1 === "HE" ? (
                              isMobile ? (
                                <a
                                  href={
                                    "whatsapp://send?text=לצפייה בכרטיס הביקור הדיגיטלי של " +
                                    this.state.name +
                                    this.state.pageurl +
                                    " %0a" +
                                    "%0a%0aPowered by Easy Card https://dev.easy-card.co/"
                                  }
                                  data-action="share/whatsapp/share"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 4.5 4.5"
                                    className="svg-icon whatsapp-icon"
                                    aria-hidden="true"
                                  >
                                    <path
                                      class="st0"
                                      d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                                    />
                                  </svg>
                                </a>
                              ) : (
                                <a
                                  href={
                                    "https://api.whatsapp.com/send?text=לצפייה בכרטיס הביקור הדיגיטלי של " +
                                    this.state.name +
                                    this.state.pageurl +
                                    " %0a" +
                                    "%0a%0aPowered by Easy Card https://dev.easy-card.co/"
                                  }
                                  data-action="share/whatsapp/share"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 4.5 4.5"
                                    className="svg-icon whatsapp-icon"
                                    aria-hidden="true"
                                  >
                                    <path
                                      class="st0"
                                      d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                                    />
                                  </svg>
                                </a>
                              )
                            ) : (
                              ""
                            )}

                            {lang1 === "AR" ? (
                              isMobile ? (
                                <a
                                  href={
                                    "whatsapp://send?text=للتمعن في بطاقة العمل الالكترونيه ل " +
                                    this.state.name +
                                    this.state.pageurl +
                                    " %0a" +
                                    "%0a%0aPowered by Easy Card https://dev.easy-card.co/"
                                  }
                                  data-action="share/whatsapp/share"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 4.5 4.5"
                                    className="svg-icon whatsapp-icon"
                                    aria-hidden="true"
                                  >
                                    <path
                                      class="st0"
                                      d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
	C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
	c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
	c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
	c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
	c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                                    />
                                  </svg>
                                </a>
                              ) : (
                                <a
                                  href={
                                    "https://api.whatsapp.com/send?text=للتمعن في بطاقة العمل الالكترونيه ل " +
                                    this.state.name +
                                    this.state.pageurl +
                                    " %0a" +
                                    "%0a%0aPowered by Easy Card https://dev.easy-card.co/"
                                  }
                                  data-action="share/whatsapp/share"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 4.5 4.5"
                                    className="svg-icon whatsapp-icon"
                                    aria-hidden="true"
                                  >
                                    <path
                                      class="st0"
                                      d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                                    />
                                  </svg>
                                </a>
                              )
                            ) : (
                              ""
                            )}
                            <p>
                              {lang1 === "EN" ? "WhatsApp" : ""}
                              {lang1 === "HE" ? "ווטסאפ" : ""}
                              {lang1 === "AR" ? "واتساب" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {counter_button < 8 &&
                        this.state.checked.email === true &&
                        this.state.email !== undefined &&
                        this.state.email !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            {lang1 === "EN" ? (
                              <a
                                href={
                                  "mailto:" +
                                  "" +
                                  "?subject=" +
                                  this.state.name +
                                  "'s digital card%0D%0A" +
                                  "&body=Use the link to view " +
                                  this.state.name +
                                  "’s digital card:%0D%0A" +
                                  this.state.pageurl +
                                  "%0D%0A %0D%0A Powered by Easy Card https://dev.easy-card.co/#/ "
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 4.5 4.5"
                                  className="svg-icon envelope-icon"
                                  aria-hidden="true"
                                >
                                  <polygon
                                    class="st0"
                                    points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="1.4,2.2 0,3.5 0,1 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="3.1,2.2 4.5,1 4.5,3.5 	"
                                  />
                                </svg>
                              </a>
                            ) : (
                              ""
                            )}

                            {lang1 === "AR" ? (
                              <a
                                href={
                                  "mailto:" +
                                  "" +
                                  "?subject=" +
                                  this.state.name +
                                  "'s digital card%0D%0A" +
                                  "&body=للتمعن في بطاقة العمل الالكترونيه ل " +
                                  this.state.name +
                                  this.state.pageurl +
                                  "%0D%0A %0D%0A Powered by Easy Card https://dev.easy-card.co/#/ "
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 4.5 4.5"
                                  className="svg-icon envelope-icon"
                                  aria-hidden="true"
                                >
                                  <polygon
                                    class="st0"
                                    points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="1.4,2.2 0,3.5 0,1 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="3.1,2.2 4.5,1 4.5,3.5 	"
                                  />
                                </svg>
                              </a>
                            ) : (
                              ""
                            )}
                            {lang1 === "HE" ? (
                              <a
                                href={
                                  "mailto:" +
                                  "" +
                                  "?subject=" +
                                  this.state.name +
                                  "'s digital card%0D%0A" +
                                  "&body=לצפייה בכרטיס הביקור הדיגיטלי של " +
                                  this.state.name +
                                  this.state.pageurl +
                                  "%0D%0A %0D%0A Powered by Easy Card https://dev.easy-card.co/ "
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 4.5 4.5"
                                  className="svg-icon envelope-icon"
                                  aria-hidden="true"
                                >
                                  <polygon
                                    class="st0"
                                    points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="1.4,2.2 0,3.5 0,1 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="3.1,2.2 4.5,1 4.5,3.5 	"
                                  />
                                </svg>
                              </a>
                            ) : (
                              ""
                            )}
                            <p>
                              {lang1 === "EN" ? "Email" : ""}
                              {lang1 === "HE" ? 'דוא"ל' : ""}
                              {lang1 === "AR" ? "البريد الإلكتروني" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* </div>
                          
                          <div id="row2-icons" className="row"> */}
                        {counter_button < 8 &&
                        this.state.checked.waze === true &&
                        this.state.waze !== undefined &&
                        this.state.waze !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a href={this.state.waze}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 4.5 4.5"
                                className="svg-icon waze-icon"
                                aria-hidden="true"
                              >
                                <g id="XMLID_12_">
                                  <g id="XMLID_22_">
                                    <g id="XMLID_23_">
                                      <path
                                        id="XMLID_24_"
                                        class="st0"
                                        d="M4.5,2c0-1-0.9-1.8-2-1.8c-1.1,0-2,0.8-2,1.8c0,0,0.1,0.4-0.1,0.6C0.4,2.6,0.3,2.7,0.1,2.7
					C0.1,2.7,0,2.7,0,2.8c0,0.4,0.3,0.7,0.9,0.8c0,0.1,0,0.1,0,0.2c0,0.3,0.3,0.6,0.6,0.6S2,4.1,2,3.8c0,0,0,0,0,0
					c0.2,0,0.4,0,0.6,0c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6c0-0.1,0-0.2-0.1-0.3C4.1,3.2,4.5,2.6,4.5,2z M3.4,3.3
					c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.3c0,0-0.1,0-0.1,0c-0.2,0-0.5,0-0.7,0C1.8,3.3,1.6,3.2,1.4,3.2
					C1.3,3.2,1.1,3.3,1,3.4C0.6,3.3,0.4,3.1,0.3,2.9c0.1,0,0.3-0.1,0.4-0.2C0.9,2.4,0.8,2,0.8,2c0-0.9,0.8-1.5,1.7-1.5
					S4.2,1.1,4.2,2C4.2,2.5,3.9,3,3.4,3.3z"
                                      />
                                    </g>
                                  </g>
                                  <g id="XMLID_19_">
                                    <g id="XMLID_20_">
                                      <path
                                        id="XMLID_21_"
                                        class="st0"
                                        d="M2,1.4L2,1.4c-0.1,0-0.1,0.1-0.1,0.1S1.9,1.7,2,1.7s0.1-0.1,0.1-0.1S2.1,1.4,2,1.4z"
                                      />
                                    </g>
                                  </g>
                                  <g id="XMLID_16_">
                                    <g id="XMLID_17_">
                                      <path
                                        id="XMLID_18_"
                                        class="st0"
                                        d="M3.1,1.4L3.1,1.4C3,1.4,3,1.5,3,1.5S3,1.7,3.1,1.7c0.1,0,0.1-0.1,0.1-0.1S3.2,1.4,3.1,1.4z"
                                      />
                                    </g>
                                  </g>
                                  <g id="XMLID_13_">
                                    <g id="XMLID_14_">
                                      <path
                                        id="XMLID_15_"
                                        class="st0"
                                        d="M3.3,2.1c-0.1,0-0.2,0-0.2,0.1C3,2.5,2.8,2.7,2.5,2.7C2.3,2.7,2,2.5,2,2.2
					c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0.1-0.1,0.2C1.8,2.7,2.1,3,2.5,3c0.4,0,0.8-0.3,0.8-0.7C3.4,2.2,3.3,2.1,3.3,2.1z"
                                      />
                                    </g>
                                  </g>
                                </g>
                                <circle
                                  id="XMLID_1_"
                                  class="st0"
                                  cx="1.4"
                                  cy="3.8"
                                  r="0.6"
                                />
                                <circle
                                  id="XMLID_3_"
                                  class="st0"
                                  cx="3.1"
                                  cy="3.8"
                                  r="0.6"
                                />
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "Waze" : ""}
                              {lang1 === "HE" ? "ווייז" : ""}
                              {lang1 === "AR" ? "Waze" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {counter_button < 8 &&
                        this.state.checked.website === true &&
                        this.state.website !== undefined &&
                        this.state.website !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a target="blank" href={this.state.website}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 4.5 4.5"
                                className="svg-icon globe-icon"
                                aria-hidden="true"
                              >
                                <path
                                  class="st0"
                                  d="M4.4,3c0.1-0.2,0.1-0.5,0.1-0.7s0-0.5-0.1-0.7c0,0,0,0,0,0C4.1,0.6,3.2,0,2.3,0S0.4,0.6,0.1,1.5c0,0,0,0,0,0
	C0,1.7,0,2,0,2.3S0,2.8,0.1,3c0,0,0,0,0,0c0.3,0.9,1.1,1.5,2.1,1.5S4.1,3.9,4.4,3C4.4,3,4.4,3,4.4,3z M2.3,4.2C2.2,4.2,2,4,1.9,3.7
	C1.9,3.5,1.8,3.3,1.8,3.1h0.9c0,0.2-0.1,0.4-0.1,0.6C2.5,4,2.3,4.2,2.3,4.2z M1.7,2.8c0-0.2,0-0.4,0-0.5s0-0.4,0-0.5h1
	c0,0.2,0,0.4,0,0.5s0,0.4,0,0.5H1.7z M0.3,2.3c0-0.2,0-0.4,0.1-0.5h1c0,0.2,0,0.4,0,0.5c0,0.2,0,0.4,0,0.5h-1
	C0.3,2.6,0.3,2.4,0.3,2.3z M2.3,0.3c0.1,0,0.2,0.2,0.3,0.5C2.6,1,2.7,1.2,2.7,1.4H1.8c0-0.2,0.1-0.4,0.1-0.6C2,0.5,2.2,0.3,2.3,0.3z
	 M3.1,1.7h1c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5h-1c0-0.2,0-0.4,0-0.5S3.1,1.9,3.1,1.7z M4,1.4H3C3,1,2.9,0.6,2.8,0.4
	C3.3,0.5,3.7,0.9,4,1.4z M1.7,0.4C1.6,0.6,1.5,1,1.5,1.4H0.5C0.8,0.9,1.2,0.5,1.7,0.4z M0.5,3.1h0.9c0.1,0.4,0.2,0.8,0.3,1
	C1.2,4,0.8,3.6,0.5,3.1z M2.8,4.1C2.9,3.9,3,3.5,3,3.1H4C3.7,3.6,3.3,4,2.8,4.1z"
                                />
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "website" : ""}
                              {lang1 === "HE" ? "אתר" : ""}
                              {lang1 === "AR" ? "website" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {counter_button < 8 &&
                        this.state.checked.facebook === true &&
                        this.state.facebook !== undefined &&
                        this.state.facebook !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a href={this.state.facebook}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 4.5 4.5"
                                className="svg-icon facebook-icon"
                                aria-hidden="true"
                              >
                                <path
                                  class="st0"
                                  d="M3.4,0L2.8,0C2.1,0,1.7,0.4,1.7,1.1v0.5H1.1C1.1,1.6,1,1.7,1,1.7v0.7c0,0.1,0,0.1,0.1,0.1h0.6
		v1.9c0,0.1,0,0.1,0.1,0.1h0.8c0.1,0,0.1,0,0.1-0.1V2.5h0.7c0.1,0,0.1,0,0.1-0.1l0-0.7c0,0,0,0,0-0.1c0,0,0,0-0.1,0H2.7V1.2
		c0-0.2,0-0.3,0.3-0.3l0.4,0c0.1,0,0.1,0,0.1-0.1L3.4,0C3.5,0,3.4,0,3.4,0z"
                                />
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "Facebook" : ""}
                              {lang1 === "HE" ? "פייסבוק" : ""}
                              {lang1 === "AR" ? "Facebook" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {counter_button < 8 &&
                        this.state.checked.instagram === true &&
                        this.state.instagram !== undefined &&
                        this.state.instagram !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a href={this.state.instagram}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                className="svg-icon instagram-icon"
                                aria-hidden="true"
                              >
                                <path
                                  class="st0"
                                  d="M14.52,2.469H5.482c-1.664,0-3.013,1.349-3.013,3.013v9.038c0,1.662,1.349,3.012,3.013,3.012h9.038c1.662,0,3.012-1.35,3.012-3.012V5.482C17.531,3.818,16.182,2.469,14.52,2.469 M13.012,4.729h2.26v2.259h-2.26V4.729z M10,6.988c1.664,0,3.012,1.349,3.012,3.012c0,1.664-1.348,3.013-3.012,3.013c-1.664,0-3.012-1.349-3.012-3.013C6.988,8.336,8.336,6.988,10,6.988 M16.025,14.52c0,0.831-0.676,1.506-1.506,1.506H5.482c-0.831,0-1.507-0.675-1.507-1.506V9.247h1.583C5.516,9.494,5.482,9.743,5.482,10c0,2.497,2.023,4.52,4.518,4.52c2.494,0,4.52-2.022,4.52-4.52c0-0.257-0.035-0.506-0.076-0.753h1.582V14.52z"
                                ></path>
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "instagram" : ""}
                              {lang1 === "HE" ? "אינסטגרם" : ""}
                              {lang1 === "AR" ? "instagram" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {counter_button < 8 &&
                        this.state.checked.youtube === true &&
                        this.state.youtube !== undefined &&
                        this.state.youtube !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a href={this.state.youtube}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                className="svg-icon youtube-icon"
                                aria-hidden="true"
                              >
                                <path
                                  class="st0"
                                  d="M9.426,7.625h0.271c0.596,0,1.079-0.48,1.079-1.073V4.808c0-0.593-0.483-1.073-1.079-1.073H9.426c-0.597,0-1.079,0.48-1.079,1.073v1.745C8.347,7.145,8.83,7.625,9.426,7.625 M9.156,4.741c0-0.222,0.182-0.402,0.404-0.402c0.225,0,0.405,0.18,0.405,0.402V6.62c0,0.222-0.181,0.402-0.405,0.402c-0.223,0-0.404-0.181-0.404-0.402V4.741z M12.126,7.625c0.539,0,1.013-0.47,1.013-0.47v0.403h0.81V3.735h-0.81v2.952c0,0-0.271,0.335-0.54,0.335c-0.271,0-0.271-0.202-0.271-0.202V3.735h-0.81v3.354C11.519,7.089,11.586,7.625,12.126,7.625 M6.254,7.559H7.2v-2.08l1.079-2.952H7.401L6.727,4.473L6.052,2.527H5.107l1.146,2.952V7.559z M11.586,12.003c-0.175,0-0.312,0.104-0.405,0.204v2.706c0.086,0.091,0.213,0.18,0.405,0.18c0.405,0,0.405-0.451,0.405-0.451v-2.188C11.991,12.453,11.924,12.003,11.586,12.003 M14.961,8.463c0,0-2.477-0.129-4.961-0.129c-2.475,0-4.96,0.129-4.96,0.129c-1.119,0-2.025,0.864-2.025,1.93c0,0-0.203,1.252-0.203,2.511c0,1.252,0.203,2.51,0.203,2.51c0,1.066,0.906,1.931,2.025,1.931c0,0,2.438,0.129,4.96,0.129c2.437,0,4.961-0.129,4.961-0.129c1.117,0,2.024-0.864,2.024-1.931c0,0,0.202-1.268,0.202-2.51c0-1.268-0.202-2.511-0.202-2.511C16.985,9.328,16.078,8.463,14.961,8.463 M7.065,10.651H6.052v5.085H5.107v-5.085H4.095V9.814h2.97V10.651z M9.628,15.736h-0.81v-0.386c0,0-0.472,0.45-1.012,0.45c-0.54,0-0.606-0.515-0.606-0.515v-3.991h0.809v3.733c0,0,0,0.193,0.271,0.193c0.27,0,0.54-0.322,0.54-0.322v-3.604h0.81V15.736z M12.801,14.771c0,0,0,1.03-0.742,1.03c-0.455,0-0.73-0.241-0.878-0.429v0.364h-0.876V9.814h0.876v1.92c0.135-0.142,0.464-0.439,0.878-0.439c0.54,0,0.742,0.45,0.742,1.03V14.771z M15.973,12.39v1.287h-1.688v0.965c0,0,0,0.451,0.405,0.451s0.405-0.451,0.405-0.451v-0.45h0.877v0.708c0,0-0.136,0.901-1.215,0.901c-1.08,0-1.282-0.901-1.282-0.901v-2.51c0,0,0-1.095,1.282-1.095S15.973,12.39,15.973,12.39 M14.69,12.003c-0.405,0-0.405,0.45-0.405,0.45v0.579h0.811v-0.579C15.096,12.453,15.096,12.003,14.69,12.003"
                                ></path>
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "Youtube" : ""}
                              {lang1 === "HE" ? "יוטיוב" : ""}
                              {lang1 === "AR" ? "Youtube" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {counter_button < 8 &&
                        this.state.checked.twitter === true &&
                        this.state.twitter !== undefined &&
                        this.state.twitter !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a href={this.state.twitter}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                className="svg-icon twitter-icon"
                                aria-hidden="true"
                              >
                                <path
                                  class="st0"
                                  d="M18.258,3.266c-0.693,0.405-1.46,0.698-2.277,0.857c-0.653-0.686-1.586-1.115-2.618-1.115c-1.98,0-3.586,1.581-3.586,3.53c0,0.276,0.031,0.545,0.092,0.805C6.888,7.195,4.245,5.79,2.476,3.654C2.167,4.176,1.99,4.781,1.99,5.429c0,1.224,0.633,2.305,1.596,2.938C2.999,8.349,2.445,8.19,1.961,7.925C1.96,7.94,1.96,7.954,1.96,7.97c0,1.71,1.237,3.138,2.877,3.462c-0.301,0.08-0.617,0.123-0.945,0.123c-0.23,0-0.456-0.021-0.674-0.062c0.456,1.402,1.781,2.422,3.35,2.451c-1.228,0.947-2.773,1.512-4.454,1.512c-0.291,0-0.575-0.016-0.855-0.049c1.588,1,3.473,1.586,5.498,1.586c6.598,0,10.205-5.379,10.205-10.045c0-0.153-0.003-0.305-0.01-0.456c0.7-0.499,1.308-1.12,1.789-1.827c-0.644,0.28-1.334,0.469-2.06,0.555C17.422,4.782,17.99,4.091,18.258,3.266"
                                ></path>
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "Twitter" : ""}
                              {lang1 === "HE" ? "טוויטר" : ""}
                              {lang1 === "AR" ? "Twitter" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {counter_button < 8 &&
                        this.state.checked.linkdin === true &&
                        this.state.linkdin !== undefined &&
                        this.state.linkdin !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a href={this.state.linkdin}>
                              <svg
                                className="svg-icon linked-icon"
                                aria-hidden="true"
                                viewBox="0 0 4.5 4.5"
                              >
                                <path
                                  class="st0"
                                  d="M1,4.5H0.1v-3H1V4.5z M0.5,1.1C0.2,1.1,0,0.8,0,0.5C0,0.2,0.2,0,0.5,0c0.3,0,0.5,0.2,0.5,0.5
		C1.1,0.8,0.8,1.1,0.5,1.1z M4.5,4.5H3.6V3c0-0.3,0-0.8-0.5-0.8C2.6,2.2,2.5,2.6,2.5,3v1.5H1.6v-3h0.9v0.4h0c0.1-0.2,0.4-0.5,0.9-0.5
		c0.9,0,1.1,0.6,1.1,1.4L4.5,4.5L4.5,4.5z"
                                />
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "Linkedin" : ""}
                              {lang1 === "HE" ? "לינקדאין" : ""}
                              {lang1 === "AR" ? "Linkedin" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {counter_button < 8 &&
                        this.state.checked.google_plus === true &&
                        this.state.google_plus !== undefined &&
                        this.state.google_plus !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a
                              href={"skype:" + this.state.google_plus + "?chat"}
                            >
                              <svg
                                className="svg-icon google_plus-icon"
                                aria-hidden="true"
                                viewBox="0 0 25 20"
                              >
                                <path d="M22.987 13.966c1.357-7.765-5.416-14.412-13.052-12.979-5.821-3.561-12.503 3.226-8.935 9.029-1.387 7.747 5.384 14.48 13.083 13.01 5.832 3.536 12.493-3.26 8.904-9.06zm-10.603 5.891c-3.181 0-6.378-1.448-6.362-3.941.005-.752.564-1.442 1.309-1.442 1.873 0 1.855 2.795 4.837 2.795 2.093 0 2.807-1.146 2.807-1.944 0-2.886-9.043-1.117-9.043-6.543 0-2.938 2.402-4.962 6.179-4.741 3.602.213 5.713 1.803 5.917 3.289.101.971-.542 1.727-1.659 1.727-1.628 0-1.795-2.181-4.6-2.181-1.266 0-2.334.528-2.334 1.674 0 2.395 8.99 1.005 8.99 6.276-.001 3.039-2.423 5.031-6.041 5.031z" />
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "Skype" : ""}
                              {lang1 === "HE" ? "סקייפ" : ""}
                              {lang1 === "AR" ? "Skype" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {counter_button < 8 &&
                        this.state.checked.fax === true &&
                        this.state.fax !== undefined &&
                        this.state.fax !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a href={"fax:" + this.state.fax}>
                              <svg
                                className="svg-icon fax-icon"
                                aria-hidden="true"
                                viewBox="0 0 4.5 4.5"
                              >
                                <path
                                  class="st0"
                                  d="M4.3,2.1C4.2,2,4.1,1.9,4,1.9H3.8V1.2c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2L3.2,0.4
		C3.2,0.3,3.1,0.3,3,0.2c-0.1,0-0.2-0.1-0.2-0.1H1c-0.1,0-0.1,0-0.2,0.1C0.7,0.3,0.7,0.4,0.7,0.4v1.5H0.5C0.4,1.9,0.3,2,0.2,2.1
		C0.1,2.2,0,2.3,0,2.4v1.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0h0.6v0.4c0,0.1,0,0.1,0.1,0.2C0.8,4.3,0.9,4.3,1,4.3h2.6c0.1,0,0.1,0,0.2-0.1
		c0.1-0.1,0.1-0.1,0.1-0.2V3.6h0.6c0,0,0,0,0.1,0c0,0,0,0,0-0.1V2.4C4.5,2.3,4.4,2.2,4.3,2.1z M3.5,4H1V3.3h2.4V4z M3.5,2.2H1V0.5
		h1.7V1c0,0.1,0,0.1,0.1,0.2C2.9,1.2,3,1.2,3,1.2h0.4L3.5,2.2L3.5,2.2z M4.1,2.5c0,0-0.1,0.1-0.1,0.1s-0.1,0-0.1-0.1
		c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1S4.1,2.5,4.1,2.5z"
                                />
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "Fax" : ""}
                              {lang1 === "HE" ? "פקס" : ""}
                              {lang1 === "AR" ? "رقم الفاكس" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* -------------tiktok starts--------------- */}

                        {counter_button < 8 &&
                        this.state.checked.tiktok === true &&
                        this.state.tiktok !== undefined &&
                        this.state.tiktok !== null ? (
                          <div
                            className="icons-div col-3"
                            counter={counter_button++}
                          >
                            <a target="blank" href={this.state.tiktok}>
                              <svg
                                className="svg-icon"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path
                                  class="st0"
                                  d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                                />
                              </svg>
                            </a>
                            <p>
                              {lang1 === "EN" ? "TikTok" : ""}
                              {lang1 === "HE" ? "טיקטוק" : ""}
                              {lang1 === "AR" ? "TikTok" : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* -------------tiktok ends--------------- */}
                      </div>

                      <div className="reach-us-button">
                        <center>
                          <a
                            id="add-contact"
                            className="btn-lg mx-auto"
                            href={this.state.vcard}
                          >
                            <svg
                              className="svg-icon"
                              aria-hidden="true"
                              viewBox="0 0 20 20"
                            >
                              <path
                                class="st0"
                                d="M14.613,10c0,0.23-0.188,0.419-0.419,0.419H10.42v3.774c0,0.23-0.189,0.42-0.42,0.42s-0.419-0.189-0.419-0.42v-3.774H5.806c-0.23,0-0.419-0.189-0.419-0.419s0.189-0.419,0.419-0.419h3.775V5.806c0-0.23,0.189-0.419,0.419-0.419s0.42,0.189,0.42,0.419v3.775h3.774C14.425,9.581,14.613,9.77,14.613,10 M17.969,10c0,4.401-3.567,7.969-7.969,7.969c-4.402,0-7.969-3.567-7.969-7.969c0-4.402,3.567-7.969,7.969-7.969C14.401,2.031,17.969,5.598,17.969,10 M17.13,10c0-3.932-3.198-7.13-7.13-7.13S2.87,6.068,2.87,10c0,3.933,3.198,7.13,7.13,7.13S17.13,13.933,17.13,10"
                              ></path>
                            </svg>
                            {lang1 === "EN" ? "Add to contacts" : ""}
                            {lang1 === "HE" ? "הוסף לאנשי הקשר" : ""}
                            {lang1 === "AR" ? "أضف إلى جهات الاتصال" : ""}
                          </a>
                        </center>
                      </div>
                    </div>
                  </div>

                  {this.state.field_value ? (
                    <div id="about-us-background">
                      <div className="container">
                        <div className="row">
                          <div id="about-us-container" className="col-12">
                            <div id="about-us-content">
                              <div className="col-xs-12 p-3">
                                <center>
                                  <div id="background-wrapper">
                                    <span id="about-us-title">
                                      {this.state.field_title}
                                    </span>
                                  </div>
                                  {/* <p> */}
                                  {this.state.field_value
                                    ? this.state.field_value
                                        .split("\n")
                                        .map((item, i) =>
                                          item ? (
                                            <p
                                              style={{
                                                wordBreak: "break-word",
                                              }}
                                              key={i}
                                            >
                                              {item}
                                            </p>
                                          ) : (
                                            <br />
                                          )
                                        )
                                    : ""}
                                  {/* {this.state.field_value} */}
                                  {/* </p> */}
                                </center>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <div id="dropdown-background">
                    <div className="container">
                      <div className="row">
                        <div id="dropdown-container" className="col-12">
                          <div
                            className="ftco-section ftco-no-pb col-12 p-0"
                            id="resume-section"
                          >
                            {this.state.checked.about_us &&
                            this.state.about_us.text ? (
                              <div
                                className="resume-wrap"
                                style={{ textAlign: textalign }}
                              >
                                <span className="date">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    data-toggle="collapse"
                                    data-target="#collapse-text"
                                  >
                                    <i
                                      className="ion-2x"
                                      style={{
                                        marginRight: margin_right,
                                        marginLeft: margin_left,
                                      }}
                                    >
                                      <img src="https://dev.easy-card.co/assets/images/digital-card-icons/About us.png" />
                                    </i>
                                    <p className="info-prop-p dropdown-paragraph-extension">
                                      {/* {this.state.about_us.label} */}
                                      {t.AboutUs[lang]}
                                    </p>
                                  </button>
                                  <div
                                    id="collapse-text"
                                    className="collapse text collapse-text"
                                  >
                                    <div className="the-padder-b">
                                      {this.state.about_us.text
                                        ? this.state.about_us.text
                                            .split("\n")
                                            .map((item, i) =>
                                              item ? (
                                                <h6 key={i}>{item}</h6>
                                              ) : (
                                                <br />
                                              )
                                            )
                                        : ""}
                                      {/* <h6>{this.state.about_us.text}</h6> */}
                                    </div>
                                  </div>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.checked.our_vision &&
                            this.state.our_vision.text ? (
                              <div
                                className="resume-wrap"
                                style={{ textAlign: textalign }}
                              >
                                <span className="date">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    data-toggle="collapse"
                                    data-target="#collapse-text1"
                                  >
                                    <i
                                      className="ion-2x"
                                      style={{
                                        marginRight: margin_right,
                                        marginLeft: margin_left,
                                      }}
                                    >
                                      <img src="https://dev.easy-card.co/assets/images/digital-card-icons/Our vision.png" />
                                    </i>
                                    <p className="info-prop-p dropdown-paragraph-extension">
                                      {/* {this.state.our_vision.label} */}
                                      {t.OurVision[lang]}
                                    </p>
                                  </button>
                                  <div
                                    id="collapse-text1"
                                    className="collapse text collapse-text"
                                  >
                                    <div className="the-padder-b">
                                      {this.state.our_vision.text
                                        ? this.state.our_vision.text
                                            .split("\n")
                                            .map((item, i) =>
                                              item ? (
                                                <h6 key={i}>{item}</h6>
                                              ) : (
                                                <br />
                                              )
                                            )
                                        : ""}
                                      {/* <h6>{this.state.our_vision.text}</h6> */}
                                    </div>
                                  </div>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.checked.our_application &&
                            this.state.our_application.text ? (
                              <div
                                className="resume-wrap"
                                style={{ textAlign: textalign }}
                              >
                                <span className="date">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    data-toggle="collapse"
                                    data-target="#collapse-text2"
                                  >
                                    <i
                                      className="ion-2x"
                                      style={{
                                        marginRight: margin_right,
                                        marginLeft: margin_left,
                                      }}
                                    >
                                      <img src="https://dev.easy-card.co/assets/images/digital-card-icons/Our application.png" />
                                    </i>
                                    <p className="info-prop-p dropdown-paragraph-extension">
                                      {/* {this.state.our_application.label} */}
                                      {t.OurApplication[lang]}
                                    </p>
                                  </button>
                                  <div
                                    id="collapse-text2"
                                    className="collapse text collapse-text"
                                  >
                                    <div className="the-padder-b">
                                      {this.state.our_application.text
                                        ? this.state.our_application.text
                                            .split("\n")
                                            .map((item, i) =>
                                              item ? (
                                                <h6 key={i}>{item}</h6>
                                              ) : (
                                                <br />
                                              )
                                            )
                                        : ""}
                                      {/* <h6>{this.state.our_application.text}</h6> */}
                                      <br />
                                      <div className="container">
                                        <div className="row mx-auto store-row">
                                          {this.state.our_application
                                            .apple_url ? (
                                            <div className="col-xs-6 mx-auto">
                                              <div height="40px;">
                                                <a
                                                  href={
                                                    this.state.our_application
                                                      .apple_url
                                                  }
                                                >
                                                  <img
                                                    src="https://dev.easy-card.co/assets/images/business/apple.png"
                                                    height="42px"
                                                    width="125"
                                                    alt="apple store"
                                                  />
                                                </a>
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.our_application
                                            .google_url ? (
                                            <div className="col-xs-6 mx-auto apple-store">
                                              <div height="40px;">
                                                <a
                                                  href={
                                                    this.state.our_application
                                                      .google_url
                                                  }
                                                >
                                                  <img
                                                    src="https://dev.easy-card.co/assets/images/business/google.png"
                                                    height="42px"
                                                    width="125"
                                                    alt="apple store"
                                                  />
                                                </a>
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.checked.our_stories ? (
                              <div
                                className="resume-wrap"
                                style={{ textAlign: textalign }}
                              >
                                <span className="date">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    data-toggle="collapse"
                                    data-target="#collapse-text5"
                                  >
                                    <i
                                      className="ion-2x"
                                      style={{
                                        marginRight: margin_right,
                                        marginLeft: margin_left,
                                      }}
                                    >
                                      <img src="https://dev.easy-card.co/assets/images/digital-card-icons/Success stories.png" />
                                    </i>
                                    <p className="info-prop-p dropdown-paragraph-extension">
                                      {/* {this.state.heading_story} */}
                                      {t.SuccessStories[lang]}
                                    </p>
                                  </button>
                                  <div
                                    id="collapse-text5"
                                    className="collapse text collapse-text"
                                  >
                                    <div className="the-padder-b">
                                      <div
                                        id="carouselExampleSlidesOnly"
                                        className="carousel slide"
                                        data-ride="carousel"
                                      >
                                        <div className="carousel-inner">
                                          {this.state.our_stories.map(
                                            (one, index) =>
                                              index !== 0 ? (
                                                <div key={index}>
                                                  {one
                                                    ? one
                                                        .split("\n")
                                                        .map((item, i) =>
                                                          item ? (
                                                            <h6 key={i}>
                                                              {item}
                                                            </h6>
                                                          ) : (
                                                            <br />
                                                          )
                                                        )
                                                    : ""}
                                                  <hr />
                                                  {/* <h6>{one}</h6> <hr/> */}
                                                </div>
                                              ) : (
                                                ""
                                              )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {/* survey */}

                            {this.state.checked.survey ? (
                              <div
                                className="resume-wrap"
                                style={{ textAlign: textalign }}
                              >
                                <span className="date">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    data-toggle="collapse"
                                    data-target="#collapse-text11"
                                  >
                                    <i
                                      className="ion-2x"
                                      style={{
                                        marginRight: margin_right,
                                        marginLeft: margin_left,
                                      }}
                                    >
                                      <img src="https://dev.easy-card.co/assets/images/digital-card-icons/chart.png" />
                                    </i>
                                    <p className="info-prop-p dropdown-paragraph-extension">
                                      {/* {this.state.heading_survey} */}
                                      {t.Survey[lang]}
                                    </p>
                                  </button>

                                  <div
                                    id="collapse-text11"
                                    className="collapse text collapse-text"
                                  >
                                    <div id="survey_hide">
                                      <div style={{ marginTop: "20px" }}>
                                        {this.state.survey[0].title}
                                      </div>
                                      <table style={{ width: "100%" }}>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <div
                                                style={{ marginTop: "20px" }}
                                              >
                                                {this.state.survey.map(
                                                  (one, index) =>
                                                    index !== 0 ? (
                                                      <div key={index}>
                                                        <div className="issue">
                                                          <span class="group1">
                                                            <input
                                                              id="radio-C1"
                                                              type="radio"
                                                              value={one}
                                                              name="survey_ans"
                                                              style={{
                                                                width: "auto",
                                                              }}
                                                            ></input>
                                                            <label
                                                              for="radio-C1"
                                                              style={{
                                                                paddingLeft:
                                                                  "10px",
                                                                top: "auto",
                                                                display:
                                                                  "contents",
                                                              }}
                                                            >
                                                              {one}
                                                            </label>
                                                          </span>
                                                        </div>
                                                        <hr />
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {this.state.checked.activity_hour ? (
                              <div
                                className="resume-wrap"
                                style={{ textAlign: textalign }}
                              >
                                <span className="date">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    data-toggle="collapse"
                                    data-target="#collapse-text3"
                                  >
                                    <i
                                      className="ion-2x"
                                      style={{
                                        marginRight: margin_right,
                                        marginLeft: margin_left,
                                      }}
                                    >
                                      <img src="https://dev.easy-card.co/assets/images/digital-card-icons/Acivity hours.png" />
                                    </i>
                                    <p className="info-prop-p dropdown-paragraph-extension">
                                      {/* {this.state.heading_active_hour} */}
                                      {t.ActivityHour[lang]}
                                    </p>
                                  </button>
                                  <div
                                    id="collapse-text3"
                                    className="collapse text collapse-text"
                                  >
                                    <div className="the-padder-b">
                                      {this.state.activity_hour.map(
                                        (one, index) =>
                                          index !== 0 ? (
                                            <div key={index}>
                                              {one
                                                ? one
                                                    .split("\n")
                                                    .map((item, i) =>
                                                      item ? (
                                                        <h6 key={i}>{item}</h6>
                                                      ) : (
                                                        <br />
                                                      )
                                                    )
                                                : ""}
                                              <hr />
                                              {/* <h6>{one}</h6> <hr/> */}
                                            </div>
                                          ) : (
                                            ""
                                          )
                                      )}
                                    </div>
                                  </div>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {this.state.checked.articles ? (
                              <div
                                className="resume-wrap"
                                style={{ textAlign: textalign }}
                              >
                                <span className="date">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    data-toggle="collapse"
                                    data-target="#collapse-text7"
                                  >
                                    <i
                                      className="ion-2x"
                                      style={{
                                        marginRight: margin_right,
                                        marginLeft: margin_left,
                                      }}
                                    >
                                      <img src="https://dev.easy-card.co/assets/images/digital-card-icons/Articles.png" />
                                    </i>
                                    <p className="info-prop-p dropdown-paragraph-extension">
                                      {/* {this.state.heading_article} */}
                                      {t.Articles[lang]}
                                    </p>
                                  </button>
                                  <div
                                    id="collapse-text7"
                                    className="collapse text collapse-text"
                                  >
                                    {this.state.articles.map((one, index) =>
                                      index !== 0 ? (
                                        <div
                                          className="the-padder-b"
                                          key={index}
                                        >
                                          <h5>{one.title}</h5>
                                          <h6>
                                            <a href={one.link}>{one.link}</a>
                                          </h6>
                                          <hr />
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  </div>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {this.state.checked.employees ? (
                              <div
                                className="resume-wrap"
                                style={{ textAlign: textalign }}
                              >
                                <span className="date">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    data-toggle="collapse"
                                    data-target="#collapse-text4"
                                  >
                                    <i
                                      className="ion-2x"
                                      style={{
                                        marginRight: margin_right,
                                        marginLeft: margin_left,
                                      }}
                                    >
                                      <img src="https://dev.easy-card.co/assets/images/digital-card-icons/Staff.png" />
                                    </i>
                                    <p className="info-prop-p dropdown-paragraph-extension">
                                      {/* {this.state.heading_employee} */}
                                      {t.Employees[lang]}
                                    </p>
                                  </button>
                                  <div
                                    id="collapse-text4"
                                    className="collapse text collapse-text"
                                  >
                                    {this.state.employees.map((one, index) =>
                                      index !== 0 ? (
                                        <div
                                          className="the-padder-b"
                                          key={index}
                                        >
                                          <h6>
                                            <a href={one.link} target="blank">
                                              {one.title}
                                            </a>
                                          </h6>
                                          {/* <h6><a href={one.link} target="blank">{one.link}</a></h6> */}
                                          <hr />
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  </div>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {this.state.checked.recommendations &&
                            this.state.recommendations[0].link ? (
                              <div
                                className="resume-wrap"
                                style={{ textAlign: textalign }}
                              >
                                <span className="date">
                                  <a
                                    target="blank"
                                    href={this.state.recommendations[0].link}
                                    className="btn btn-info"
                                    style={{
                                      border: "unset",
                                      background: "unset",
                                      fontSize: "1em",
                                    }}
                                  >
                                    <i
                                      className="ion-2x"
                                      style={{
                                        marginRight: margin_right,
                                        marginLeft: margin_left,
                                      }}
                                    >
                                      <img src="https://dev.easy-card.co/assets/images/digital-card-icons/Recommendations.png" />
                                    </i>
                                    <p className="info-prop-p dropdown-paragraph-extension">
                                      {/* {this.state.heading_recommandation} */}
                                      {t.Recommendations[lang]}
                                    </p>
                                  </a>
                                  {/* <div id="collapse-text6" className="collapse text collapse-text">
                                                  {this.state.recommendations.map((one, index) => (
                                                      (index !== 0) ?
                                                      <div className="the-padder-b" key={index}>
                                                          <h6>{one.title}</h6>
                                                          <h6><a href={one.link}>{one.link}</a></h6>
                                                          <hr/>
                                                      </div> : ''
                                                  ))}
                                              </div> */}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {this.state.checked.qr_code == true ? (
                              <div
                                className="resume-wrap"
                                style={{ textAlign: textalign }}
                              >
                                <span className="date">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    data-toggle="collapse"
                                    data-target="#collapse-text1122"
                                  >
                                    <i
                                      className="ion-2x"
                                      style={{
                                        marginRight: margin_right,
                                        marginLeft: margin_left,
                                      }}
                                    >
                                      <img src="https://dev.easy-card.co/assets/images/digital-card-icons/qrcode.png" />
                                    </i>
                                    <p className="info-prop-p dropdown-paragraph-extension">
                                      {this.state.heading_qrcode}
                                    </p>
                                  </button>
                                  <div
                                    id="collapse-text1122"
                                    className="collapse text collapse-text"
                                  >
                                    <div
                                      className="the-padder-b"
                                      style={{ textAlign: "center" }}
                                    >
                                      <QRCode
                                        value={window.location.href}
                                        size={150}
                                        bgColor={"#ffffff"}
                                        fgColor={"#000000"}
                                        level={"L"}
                                        includeMargin={false}
                                        renderAs={"canvas"}
                                        imageSettings={{
                                          src: "http://localhost:3001/favicon.ico",
                                          x: null,
                                          y: null,
                                          height: 18,
                                          width: 18,
                                          excavate: true,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {this.state.checked.leave_a_message ? (
                              <div
                                className="resume-wrap"
                                style={{ textAlign: textalign }}
                              >
                                <span className="date">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    data-toggle="collapse"
                                    data-target="#collapse-text8"
                                  >
                                    <i
                                      className="ion-2x"
                                      style={{
                                        marginRight: margin_right,
                                        marginLeft: margin_left,
                                      }}
                                    >
                                      <img src="https://dev.easy-card.co/assets/images/digital-card-icons/Leave a message.png" />
                                    </i>
                                    <p className="info-prop-p dropdown-paragraph-extension">
                                      {/* {this.state
															.leave_a_message_label
															? t.Leaveusamessage[lang1]
															: t.Leaveusamessage[lang1]} */}
                                      {/* {this.state.heading_leave_msg} */}
                                      {t.Leaveusamessage[lang]}
                                    </p>
                                  </button>
                                  <div
                                    id="collapse-text8"
                                    className="collapse text collapse-text"
                                  >
                                    <br />
                                    <br />
                                    <form>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="message_name"
                                        name="message_name"
                                        value={this.state.message_name}
                                        onChange={this.onChange}
                                        placeholder={t.contactusfname[lang1]}
                                        required
                                      />
                                      <br />
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="message_phone"
                                        name="message_phone"
                                        value={this.state.message_phone}
                                        onChange={this.onChange}
                                        placeholder={
                                          t.contactusphonenumber[lang1]
                                        }
                                      />
                                      <br />
                                      <input
                                        type="email"
                                        className="form-control email-area"
                                        id="message_email"
                                        name="message_email"
                                        value={this.state.message_email}
                                        onChange={this.onChange}
                                        placeholder={t.contactusEmail[lang1]}
                                        required
                                      />
                                      <br />
                                      <textarea
                                        className="form-control textarea-text custom-color"
                                        id="message_message"
                                        name="message_message"
                                        rows="6"
                                        value={this.state.message_message}
                                        onChange={this.onChange}
                                        placeholder={t.leaveamessage[lang1]}
                                      ></textarea>
                                      <br />
                                      <button
                                        type="button"
                                        className="btn btn-send-message"
                                        onClick={this.leaveamessage}
                                      >
                                        {t.Sendmessage[lang1]}
                                      </button>
                                    </form>
                                  </div>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="img-slider-wrapper">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          {this.state.images !== undefined ? (
                            <Carousel
                              renderCenterLeftControls={({ previousSlide }) => (
                                <button
                                  type="button"
                                  id="carousel_buttons"
                                  onClick={previousSlide}
                                >
                                  {t.prev[lang1]}
                                </button>
                              )}
                              renderCenterRightControls={({ nextSlide }) => (
                                <button
                                  type="button"
                                  id="carousel_buttons"
                                  onClick={nextSlide}
                                >
                                  {t.next[lang1]}
                                </button>
                              )}
                              wrapAround
                              autoplay="true"
                              heightMode="current"
                            >
                              {this.state.images.map((one, index) => (
                                <img src={one} alt="" key={index} />
                              ))}
                            </Carousel>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="video-background">
                    <div className="video-container-wrapper">
                      {this.renderVideo()}
                    </div>
                  </div>

                  <div id="share-link-background">
                    <div className="container-fluid">
                      <div className="row justify-content-center">
                        <p className="share-link-icons-wrapper ">
                          {lang1 === "EN"
                            ? "Share this card via:"
                            : "שתף הכרטיס באמצעות"}
                        </p>
                      </div>
                      <div className="row justify-content-center">
                        <div className=" mx-auto my-auto share-link-icons">
                          <button
                            type="button"
                            className="btn btn-circle btn-lg"
                          >
                            {lang1 === "EN" ? (
                              <a
                                href={
                                  "mailto:" +
                                  "" +
                                  "?subject=" +
                                  this.state.name +
                                  "'s digital card%0D%0A" +
                                  "&body=Use the link to view " +
                                  this.state.name +
                                  "’s digital card:%0D%0A" +
                                  this.state.pageurl +
                                  "%0D%0A %0D%0A Powered by Easy Card https://dev.easy-card.co/#/ "
                                }
                              >
                                <svg
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 4.5 4.5"
                                >
                                  <polygon
                                    class="st0"
                                    points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="1.4,2.2 0,3.5 0,1 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="3.1,2.2 4.5,1 4.5,3.5 	"
                                  />
                                </svg>
                              </a>
                            ) : null}

                            {lang1 === "HE" ? (
                              <a
                                href={
                                  "mailto:" +
                                  "" +
                                  "?subject=" +
                                  this.state.name +
                                  "'s digital card%0D%0A" +
                                  "&body=לצפייה בכרטיס הביקור הדיגיטלי של " +
                                  this.state.name +
                                  this.state.pageurl +
                                  "%0D%0A %0D%0A Powered by Easy Card https://dev.easy-card.co/#/ "
                                }
                              >
                                <svg
                                  aria-hidden="true"
                                  viewBox="0 0 4.5 4.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <polygon
                                    class="st0"
                                    points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="1.4,2.2 0,3.5 0,1 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="3.1,2.2 4.5,1 4.5,3.5 	"
                                  />
                                </svg>
                              </a>
                            ) : (
                              ""
                            )}

                            {lang1 === "AR" ? (
                              <a
                                href={
                                  "mailto:" +
                                  "" +
                                  "?subject=" +
                                  this.state.name +
                                  "'s digital card%0D%0A" +
                                  "&body=للتمعن في بطاقة العمل الالكترونيه ل" +
                                  this.state.name +
                                  this.state.pageurl +
                                  "%0D%0A %0D%0A Powered by Easy Card https://dev.easy-card.co/#/ "
                                }
                              >
                                <svg
                                  className="svg-icon-contact"
                                  aria-hidden="true"
                                  viewBox="0 0 4.5 4.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <polygon
                                    class="st0"
                                    points="2.3,2.6 0.2,0.8 4.3,0.8 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="1.4,2.2 0,3.5 0,1 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="1.6,2.4 2.3,3 2.9,2.4 4.3,3.7 0.2,3.7 	"
                                  />
                                  <polygon
                                    class="st0"
                                    points="3.1,2.2 4.5,1 4.5,3.5 	"
                                  />
                                </svg>
                              </a>
                            ) : (
                              ""
                            )}
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            className="btn btn-circle btn-lg "
                          >
                            <a
                              href={
                                "https://www.facebook.com/sharer/sharer.php?u=" +
                                this.state.facebook
                              }
                            >
                              <svg
                                className="svg-icon"
                                aria-hidden="true"
                                viewBox="0 0 4.5 4.5"
                              >
                                <path
                                  class="st0"
                                  d="M3.4,0L2.8,0C2.1,0,1.7,0.4,1.7,1.1v0.5H1.1C1.1,1.6,1,1.7,1,1.7v0.7c0,0.1,0,0.1,0.1,0.1h0.6
		v1.9c0,0.1,0,0.1,0.1,0.1h0.8c0.1,0,0.1,0,0.1-0.1V2.5h0.7c0.1,0,0.1,0,0.1-0.1l0-0.7c0,0,0,0,0-0.1c0,0,0,0-0.1,0H2.7V1.2
		c0-0.2,0-0.3,0.3-0.3l0.4,0c0.1,0,0.1,0,0.1-0.1L3.4,0C3.5,0,3.4,0,3.4,0z"
                                />
                              </svg>
                            </a>
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            className="btn btn-circle btn-lg "
                          >
                            {lang1 === "EN" ? (
                              isMobile ? (
                                <a
                                  href={
                                    "whatsapp://send?text=Use the following link to view" +
                                    this.state.name +
                                    "’s digital card: " +
                                    this.state.pageurl +
                                    " %0a" +
                                    "%0a%0aPowered by Easy Card https://dev.easy-card.co/"
                                  }
                                  data-action="share/whatsapp/share"
                                >
                                  <svg
                                    className="svg-icon whatsapp-icon"
                                    aria-hidden="true"
                                    viewBox="0 0 4.5 4.5"
                                  >
                                    <path
                                      class="st0"
                                      d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                                    />
                                  </svg>
                                </a>
                              ) : (
                                <a
                                  href={
                                    "https://api.whatsapp.com/?text=Use the following link to view" +
                                    this.state.name +
                                    "’s digital card: " +
                                    this.state.pageurl +
                                    " %0a" +
                                    "%0a%0aPowered by Easy Card https://dev.easy-card.co/"
                                  }
                                  data-action="share/whatsapp/share"
                                >
                                  <svg
                                    className="svg-icon whatsapp-icon"
                                    aria-hidden="true"
                                    viewBox="0 0 4.5 4.5"
                                  >
                                    <path
                                      class="st0"
                                      d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                                    />
                                  </svg>
                                </a>
                              )
                            ) : (
                              ""
                            )}

                            {lang1 === "HE" ? (
                              isMobile ? (
                                <a
                                  href={
                                    "whatsapp://send?text=לצפייה בכרטיס הביקור הדיגיטלי של " +
                                    this.state.name +
                                    this.state.pageurl +
                                    " %0a" +
                                    "%0a%0aPowered by Easy Card https://dev.easy-card.co/"
                                  }
                                  data-action="share/whatsapp/share"
                                >
                                  <svg
                                    className="svg-icon whatsapp-icon"
                                    aria-hidden="true"
                                    viewBox="0 0 4.5 4.5"
                                  >
                                    <path
                                      class="st0"
                                      d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                                    />
                                  </svg>
                                </a>
                              ) : (
                                <a
                                  href={
                                    "https://api.whatsapp.com/?text=לצפייה בכרטיס הביקור הדיגיטלי של " +
                                    this.state.name +
                                    this.state.pageurl +
                                    " %0a" +
                                    "%0a%0aPowered by Easy Card https://dev.easy-card.co/"
                                  }
                                  data-action="share/whatsapp/share"
                                >
                                  <svg
                                    className="svg-icon whatsapp-icon"
                                    aria-hidden="true"
                                    viewBox="0 0 4.5 4.5"
                                  >
                                    <path
                                      class="st0"
                                      d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                                    />
                                  </svg>
                                </a>
                              )
                            ) : (
                              ""
                            )}

                            {lang1 === "AR" ? (
                              isMobile ? (
                                <a
                                  href={
                                    "whatsapp://send?text=للتمعن في بطاقة العمل الالكترونيه ل " +
                                    this.state.name +
                                    this.state.pageurl +
                                    " %0a" +
                                    "%0a%0aPowered by Easy Card https://dev.easy-card.co/"
                                  }
                                  data-action="share/whatsapp/share"
                                >
                                  <svg
                                    className="svg-icon whatsapp-icon"
                                    aria-hidden="true"
                                    viewBox="0 0 4.5 4.5"
                                  >
                                    <path
                                      class="st0"
                                      d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                                    />
                                  </svg>
                                </a>
                              ) : (
                                <a
                                  href={
                                    "https://api.whatsapp.com/?text=للتمعن في بطاقة العمل الالكترونيه ل " +
                                    this.state.name +
                                    this.state.pageurl +
                                    " %0a" +
                                    "%0a%0aPowered by Easy Card https://dev.easy-card.co/"
                                  }
                                  data-action="share/whatsapp/share"
                                >
                                  <svg
                                    className="svg-icon whatsapp-icon"
                                    aria-hidden="true"
                                    viewBox="0 0 4.5 4.5"
                                  >
                                    <path
                                      class="st0"
                                      d="M4.5,2.2c0,1.2-1,2.2-2.2,2.2c-0.4,0-0.8-0.1-1.1-0.3L0,4.5l0.4-1.2C0.2,3,0.1,2.6,0.1,2.2
		C0.1,1,1.1,0,2.3,0C3.5,0,4.5,1,4.5,2.2z M2.3,0.3c-1,0-1.9,0.8-1.9,1.8c0,0.4,0.1,0.8,0.4,1.1L0.6,4l0.7-0.2C1.6,3.9,1.9,4,2.3,4
		c1,0,1.9-0.8,1.9-1.8S3.3,0.3,2.3,0.3z M3.4,2.7c0,0,0,0-0.1-0.1C3.2,2.6,3,2.5,2.9,2.5c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.2,0.2
		c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.4-0.3C1.9,2.3,1.8,2.1,1.8,2.1c0-0.1,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1c0,0-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c0,0.1-0.2,0.2-0.2,0.4
		c0,0.3,0.2,0.5,0.2,0.6c0,0,0.4,0.6,0.9,0.8C2.9,3.3,2.9,3.2,3,3.2c0.1,0,0.3-0.1,0.4-0.3C3.4,2.8,3.4,2.7,3.4,2.7z"
                                    />
                                  </svg>
                                </a>
                              )
                            ) : (
                              ""
                            )}
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            className="btn btn-circle btn-lg "
                          >
                            {lang1 === "EN" ? (
                              isIOS ? (
                                <a
                                  href={
                                    "sms://&body=Use the following link to view " +
                                    this.state.name +
                                    "’s digital card:%0a" +
                                    this.state.pageurl +
                                    "%0a%0a https://dev.easy-card.co/"
                                  }
                                >
                                  <svg
                                    className="svg-icon"
                                    aria-hidden="true"
                                    viewBox="0 0 4.5 4.5"
                                  >
                                    <path
                                      class="st0"
                                      d="M2.2,0.3C1,0.3,0,1.1,0,2.1c0,0.4,0.2,0.8,0.5,1.1C0.4,3.7,0,4.1,0,4.1c0,0,0,0,0,0.1s0,0,0.1,0
c0.6,0,1-0.3,1.2-0.4c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-0.8,2.2-1.8S3.5,0.3,2.2,0.3z M1.1,2.4C1,2.4,0.9,2.3,0.9,2.1
C0.9,2,1,1.8,1.1,1.8c0.2,0,0.3,0.1,0.3,0.3S1.3,2.4,1.1,2.4z M2.2,2.4C2.1,2.4,2,2.3,2,2.1C2,2,2.1,1.8,2.2,1.8S2.5,2,2.5,2.1
S2.4,2.4,2.2,2.4z M3.4,2.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S3.5,2.4,3.4,2.4z"
                                    />
                                  </svg>
                                </a>
                              ) : (
                                <a
                                  href={
                                    "sms://?body=Use the following link to view " +
                                    this.state.name +
                                    "’s digital card:%0a" +
                                    this.state.pageurl +
                                    "%0a%0a https://dev.easy-card.co/"
                                  }
                                >
                                  <svg
                                    className="svg-icon"
                                    aria-hidden="true"
                                    viewBox="0 0 4.5 4.5"
                                  >
                                    <path
                                      class="st0"
                                      d="M2.2,0.3C1,0.3,0,1.1,0,2.1c0,0.4,0.2,0.8,0.5,1.1C0.4,3.7,0,4.1,0,4.1c0,0,0,0,0,0.1s0,0,0.1,0
	c0.6,0,1-0.3,1.2-0.4c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-0.8,2.2-1.8S3.5,0.3,2.2,0.3z M1.1,2.4C1,2.4,0.9,2.3,0.9,2.1
	C0.9,2,1,1.8,1.1,1.8c0.2,0,0.3,0.1,0.3,0.3S1.3,2.4,1.1,2.4z M2.2,2.4C2.1,2.4,2,2.3,2,2.1C2,2,2.1,1.8,2.2,1.8S2.5,2,2.5,2.1
	S2.4,2.4,2.2,2.4z M3.4,2.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S3.5,2.4,3.4,2.4z"
                                    />
                                  </svg>
                                </a>
                              )
                            ) : (
                              ""
                            )}

                            {lang1 === "HE" ? (
                              isIOS ? (
                                <a
                                  href={
                                    "sms://&body=לצפייה בכרטיס הביקור הדיגיטלי של " +
                                    this.state.name +
                                    "'s digital card:%0a" +
                                    this.state.pageurl +
                                    "%0a%0a https://dev.easy-card.co/"
                                  }
                                >
                                  <svg
                                    className="svg-icon"
                                    aria-hidden="true"
                                    viewBox="0 0 4.5 4.5"
                                  >
                                    <path
                                      class="st0"
                                      d="M2.2,0.3C1,0.3,0,1.1,0,2.1c0,0.4,0.2,0.8,0.5,1.1C0.4,3.7,0,4.1,0,4.1c0,0,0,0,0,0.1s0,0,0.1,0
	c0.6,0,1-0.3,1.2-0.4c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-0.8,2.2-1.8S3.5,0.3,2.2,0.3z M1.1,2.4C1,2.4,0.9,2.3,0.9,2.1
	C0.9,2,1,1.8,1.1,1.8c0.2,0,0.3,0.1,0.3,0.3S1.3,2.4,1.1,2.4z M2.2,2.4C2.1,2.4,2,2.3,2,2.1C2,2,2.1,1.8,2.2,1.8S2.5,2,2.5,2.1
	S2.4,2.4,2.2,2.4z M3.4,2.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S3.5,2.4,3.4,2.4z"
                                    />
                                  </svg>
                                </a>
                              ) : (
                                <a
                                  href={
                                    "sms://?body=לצפייה בכרטיס הביקור הדיגיטלי של " +
                                    this.state.name +
                                    "’s digital card:%0a" +
                                    this.state.pageurl +
                                    "%0a%0a https://dev.easy-card.co/"
                                  }
                                >
                                  <svg
                                    className="svg-icon"
                                    aria-hidden="true"
                                    viewBox="0 0 4.5 4.5"
                                  >
                                    <path
                                      class="st0"
                                      d="M2.2,0.3C1,0.3,0,1.1,0,2.1c0,0.4,0.2,0.8,0.5,1.1C0.4,3.7,0,4.1,0,4.1c0,0,0,0,0,0.1s0,0,0.1,0
		c0.6,0,1-0.3,1.2-0.4c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-0.8,2.2-1.8S3.5,0.3,2.2,0.3z M1.1,2.4C1,2.4,0.9,2.3,0.9,2.1
		C0.9,2,1,1.8,1.1,1.8c0.2,0,0.3,0.1,0.3,0.3S1.3,2.4,1.1,2.4z M2.2,2.4C2.1,2.4,2,2.3,2,2.1C2,2,2.1,1.8,2.2,1.8S2.5,2,2.5,2.1
		S2.4,2.4,2.2,2.4z M3.4,2.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S3.5,2.4,3.4,2.4z"
                                    />
                                  </svg>
                                </a>
                              )
                            ) : (
                              ""
                            )}

                            {lang1 === "AR" ? (
                              isIOS ? (
                                <a
                                  href={
                                    "sms://&body=للتمعن في بطاقة العمل الالكترونيه ل " +
                                    this.state.name +
                                    "’s digital card:%0a" +
                                    this.state.pageurl +
                                    "%0a%0a https://dev.easy-card.co/"
                                  }
                                >
                                  <svg
                                    className="svg-icon"
                                    aria-hidden="true"
                                    viewBox="0 0 4.5 4.5"
                                  >
                                    <path
                                      class="st0"
                                      d="M2.2,0.3C1,0.3,0,1.1,0,2.1c0,0.4,0.2,0.8,0.5,1.1C0.4,3.7,0,4.1,0,4.1c0,0,0,0,0,0.1s0,0,0.1,0
c0.6,0,1-0.3,1.2-0.4c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-0.8,2.2-1.8S3.5,0.3,2.2,0.3z M1.1,2.4C1,2.4,0.9,2.3,0.9,2.1
C0.9,2,1,1.8,1.1,1.8c0.2,0,0.3,0.1,0.3,0.3S1.3,2.4,1.1,2.4z M2.2,2.4C2.1,2.4,2,2.3,2,2.1C2,2,2.1,1.8,2.2,1.8S2.5,2,2.5,2.1
S2.4,2.4,2.2,2.4z M3.4,2.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S3.5,2.4,3.4,2.4z"
                                    />
                                  </svg>
                                </a>
                              ) : (
                                <a
                                  href={
                                    "sms://?body=للتمعن في بطاقة العمل الالكترونيه ل " +
                                    this.state.name +
                                    "’s digital card:%0a" +
                                    this.state.pageurl +
                                    "%0a%0a https://dev.easy-card.co/"
                                  }
                                >
                                  <svg
                                    className="svg-icon"
                                    aria-hidden="true"
                                    viewBox="0 0 4.5 4.5"
                                  >
                                    <path
                                      class="st0"
                                      d="M2.2,0.3C1,0.3,0,1.1,0,2.1c0,0.4,0.2,0.8,0.5,1.1C0.4,3.7,0,4.1,0,4.1c0,0,0,0,0,0.1s0,0,0.1,0
	c0.6,0,1-0.3,1.2-0.4c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.2-0.8,2.2-1.8S3.5,0.3,2.2,0.3z M1.1,2.4C1,2.4,0.9,2.3,0.9,2.1
	C0.9,2,1,1.8,1.1,1.8c0.2,0,0.3,0.1,0.3,0.3S1.3,2.4,1.1,2.4z M2.2,2.4C2.1,2.4,2,2.3,2,2.1C2,2,2.1,1.8,2.2,1.8S2.5,2,2.5,2.1
	S2.4,2.4,2.2,2.4z M3.4,2.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S3.5,2.4,3.4,2.4z"
                                    />
                                  </svg>
                                </a>
                              )
                            ) : (
                              ""
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <footer>
                    <div className="container footer-wrapper">
                      <div className="row footer-wrapper justify-content-center align-items-center">
                        <a href="https://dev.easy-card.co/">
                          <img
                            src="https://dev.easy-card.co/assets/images/footer_create_card.jpg"
                            style={{ width: "100%" }}
                          ></img>
                        </a>
                      </div>
                    </div>
                  </footer>
                  {/* <script type="text/javascript" src="js/main.js"></script> */}
                </div>
              </StickyBox>
            </div>

            <div className="col-md-9">
              <div className="main-card mb-3 card">
                <div className="card-body">
                  <form onSubmit={this.createCard}>
                    <div class="mb-3 text-center">
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          let newHref = e.target.href.split("#");
                          this.move(`#${newHref[1]}`, `#${e.target.id}`);
                        }}
                        role="group"
                        className="btn-group-sm nav btn-group"
                        id="btn-group"
                      >
                        <a
                          data-toggle="tab"
                          href="#tab-eg15-0"
                          id="basic-info-btn"
                          className="btn-shadow btn btn-primary show active"
                        >
                          {t.Generalinformation[lang]}
                        </a>
                        <a
                          data-toggle="tab"
                          href="#tab-eg15-1"
                          id="contact-info-btn"
                          className="btn-shadow btn btn-primary show"
                        >
                          {t.headerdesign[lang]}
                        </a>
                        <a
                          data-toggle="tab"
                          href="#tab-eg15-2"
                          id="social-info-btn"
                          className="btn-shadow btn btn-primary show"
                        >
                          {t.ContactInformation[lang]}
                        </a>
                        <a
                          data-toggle="tab"
                          href="#tab-eg15-3"
                          id="card-info-btn"
                          className="btn-shadow btn btn-primary show"
                        >
                          {t.Buttonsselection[lang]}
                        </a>
                        <a
                          data-toggle="tab"
                          href="#tab-eg15-4"
                          id="card-content-btn"
                          className="btn-shadow btn btn-primary show"
                        >
                          {t.CardSectionsInformation[lang]}
                        </a>
                        <a
                          data-toggle="tab"
                          href="#tab-eg15-5"
                          id="card-links-btn"
                          className="btn-shadow btn btn-primary show"
                        >
                          {t.CardContent[lang]}
                        </a>
                      </div>
                    </div>
                    <div className="tab-content">
                      <div
                        className="tab-pane show active"
                        id="tab-eg15-0"
                        role="tabpanel"
                      >
                        <div className="form-row">
                          <div className="col-md-12">
                            <p className="helper-text">
                              {t.Generalinformation1[lang]} <br />
                              {t.Generalinformation2[lang]}
                              <br /> {t.Generalinformation3[lang]}{" "}
                              <Link to="/profile">
                                {t.Generalinformationprofilesection[lang]}
                              </Link>{" "}
                              {
                                t.Generalinformatioandselectingyourlanguage[
                                  lang
                                ]
                              }
                            </p>
                            <hr />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-12">
                            <div className="position-relative row form-group">
                              <label
                                htmlFor="language"
                                className="col-sm-2 col-form-label"
                              >
                                {t.SelectLanguage[lang]}
                              </label>
                              <div className="col-sm-12" data-tip="Required">
                                <SelectSearch
                                  onChange={(e) => this.changeLang(e)}
                                  className="select-search-box"
                                  options={Languages}
                                  value={this.state.language_val}
                                  name="language"
                                  placeholder={
                                    this.state.language_val
                                      ? this.state.language_val
                                      : t.ChooseYourLanguage[lang]
                                  }
                                />
                                <small
                                  className="form-error-mode"
                                  id="language-error"
                                >
                                  {t.PleaseSelectaLanguage[lang]}
                                </small>
                                <ReactTooltip />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="position-relative form-group"
                              data-tip="Required"
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChange}
                                value={this.state.card_title}
                                name="card_title"
                                id="card_title"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="card_title"
                                className={
                                  this.state.card_title !== "" ? "show" : ""
                                }
                              >
                                {t.CardTitle[lang]}
                                <span className="required-prop">*</span>
                              </label>
                              <small
                                className="form-error-mode"
                                id="card-title-error"
                              >
                                {t.req[lang]}
                              </small>
                            </div>
                            <ReactTooltip />
                          </div>
                          <div className="col-md-6">
                            <div
                              className="position-relative form-group hb"
                              data-tip={t.selectaurlforyourcard[lang]}
                            >
                              <div className="card-url-typo">
                                {/* https://easy-card.co/ */}
                                {APIModel.PORTAL_URL}
                              </div>
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.cardUrl}
                                onClick={this.onClick}
                                onBlur={this.onBlur}
                                value={this.state.card_url}
                                name="card_url"
                                id="card_url"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="card_url"
                                id="hb_cardurl"
                                className="show"
                              >
                                {t.CardUrl[lang]}
                                <span className="required-prop">*</span>
                              </label>
                              <div className="available-status">
                                {this.state.slugCan}
                              </div>
                            </div>
                            <ReactTooltip />
                          </div>
                          <div className="col-md-12 text-center">
                            <button
                              class="mb-2 mr-2 btn-hover-shine btn btn-wide btn-dark"
                              type="button"
                              onClick={() =>
                                this.move("#tab-eg15-1", "#contact-info-btn")
                              }
                            >
                              {t.Next[lang]}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane show"
                        id="tab-eg15-1"
                        role="tabpanel"
                      >
                        <div className="form-row">
                          <div className="col-md-12">
                            <p className="helper-text">{t.addup[lang]}</p>
                            <hr />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-6">
                            <div className="image-boxed-model">
                              <span
                                forHtml="business-image"
                                className="col-form-label btn-span"
                              >
                                {t.blogo[lang]}
                              </span>
                              <span>
                                {t.recomendedres[lang]}: 512*512{" "}
                                {t.Pixels[lang]}
                              </span>
                              <div className="d-inline float-right">
                                <Switch
                                  onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  className="switch-create-card"
                                  offColor="#212123"
                                  onColor="#00adb4"
                                  onChange={this.handleCardToggleFields.bind(
                                    this,
                                    "business_image"
                                  )}
                                  checked={this.state.checked.business_image}
                                />
                              </div>
                            </div>
                            <div className="upload-btn-wrapper business_image">
                              <button className="btn">
                                {t.Uploadafile[lang]}
                              </button>
                              <FileBase64
                                multiple={false}
                                className=""
                                onDone={this.getBusinessImage.bind(this)}
                              />
                              <div className="file-info">FileName</div>
                              {this.state.business_image_serve !== "" ? (
                                this.state.image_process ? (
                                  <div class="the-loader">
                                    <Loader
                                      type="TailSpin"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                  </div>
                                ) : (
                                  <div className="file-holder">
                                    <div
                                      className="hover"
                                      onClick={() => {
                                        this.setState({
                                          business_image_serve: "",
                                        });

                                        this.setState({
                                          business_image: "",
                                        });
                                        this.handleCardToggleFields(
                                          "business_image",
                                          false
                                        );
                                      }}
                                    >
                                      <div className="hover-text">
                                        Click To Remove
                                      </div>
                                    </div>
                                    <img
                                      src={this.state.business_image_serve}
                                      className="file-image"
                                      alt=""
                                    />
                                  </div>
                                )
                              ) : this.state.business_image_serve === "" ? (
                                this.state.image_process ? (
                                  <div class="the-loader">
                                    <Loader
                                      type="TailSpin"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      this.state.set_business_crop
                                        ? "hide-it"
                                        : "file-holder"
                                    }
                                  >
                                    <div
                                      className="hover"
                                      onClick={() => {
                                        this.setState({
                                          business_image: "",
                                        });
                                        this.handleCardToggleFields(
                                          "business_image",
                                          false
                                        );
                                      }}
                                    >
                                      <div className="hover-text">
                                        Click To Remove
                                      </div>
                                    </div>
                                    <img
                                      src={this.state.businessresult}
                                      className="file-image"
                                      alt=""
                                    />
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              this.state.set_business_crop
                                ? "cropper"
                                : "hide-it"
                            }
                          >
                            <button
                              type="button"
                              className="cropper-btn"
                              onClick={() =>
                                this.setState({
                                  set_business_crop: false,
                                })
                              }
                            >
                              {t.Select[lang]}
                            </button>
                            <Cropper
                              image={this.state.business_image}
                              crop={this.state.businesscrop}
                              zoom={this.state.businesszoom}
                              aspect={this.state.businessaspect}
                              onCropChange={this.onCropChange.bind(
                                this,
                                "businesscrop"
                              )}
                              onCropComplete={this.onCropComplete.bind(
                                this,
                                "businessresult",
                                this.state.business_image
                              )}
                              onZoomChange={this.onZoomChange.bind(
                                this,
                                "businesszoom"
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="image-boxed-model">
                              <span
                                forHtml="background-image"
                                className="col-form-label btn-span"
                              >
                                {t.BusinessImage[lang]}
                              </span>
                              <span>
                                {t.recomendedres[lang]}: 510*255{" "}
                                {t.Pixels[lang]}
                              </span>
                              <div className="d-inline float-right">
                                <Switch
                                  onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  className="switch-create-card"
                                  offColor="#212123"
                                  onColor="#00adb4"
                                  onChange={this.handleCardToggleFields.bind(
                                    this,
                                    "background_image"
                                  )}
                                  checked={this.state.checked.background_image}
                                />
                              </div>
                            </div>
                            <div className="upload-btn-wrapper background_image">
                              <button className="btn">
                                {t.Uploadafile[lang]}
                              </button>
                              <FileBase64
                                multiple={false}
                                className=""
                                onDone={this.getBackgroundImage.bind(this)}
                              />
                              <div className="file-info">FileName</div>
                              {this.state.background_image_serve !== "" ? (
                                this.state.image_process ? (
                                  <div class="the-loader">
                                    <Loader
                                      type="TailSpin"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                  </div>
                                ) : (
                                  <div className="file-holder">
                                    <div
                                      className="hover"
                                      onClick={() => {
                                        this.setState({
                                          background_image_serve: "",
                                          background_image: "",
                                        });
                                        this.handleCardToggleFields(
                                          "background_image",
                                          false
                                        );
                                      }}
                                    >
                                      <div className="hover-text">
                                        Click To Remove
                                      </div>
                                    </div>
                                    <img
                                      src={this.state.background_image_serve}
                                      className="file-image"
                                      alt=""
                                    />
                                  </div>
                                )
                              ) : this.state.background_image_serve === "" ? (
                                this.state.image_process ? (
                                  <div class="the-loader">
                                    <Loader
                                      type="TailSpin"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      this.state.set_background_crop
                                        ? "hide-it"
                                        : "file-holder"
                                    }
                                  >
                                    <div
                                      className="hover"
                                      onClick={() => {
                                        this.setState({
                                          background_image: "",
                                        });
                                        this.handleCardToggleFields(
                                          "background_image",
                                          false
                                        );
                                      }}
                                    >
                                      <div className="hover-text">
                                        Click To Remove
                                      </div>
                                    </div>
                                    <img
                                      src={this.state.backgroundresult}
                                      className="file-image"
                                      alt=""
                                    />
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              this.state.set_background_crop
                                ? "cropper"
                                : "hide-it"
                            }
                          >
                            <button
                              type="button"
                              className="cropper-btn"
                              onClick={() =>
                                this.setState({
                                  set_background_crop: false,
                                })
                              }
                            >
                              {t.Select[lang]}
                            </button>
                            <Cropper
                              image={this.state.background_image}
                              crop={this.state.backgroundcrop}
                              zoom={this.state.backgroundzoom}
                              aspect={this.state.backgroundaspect}
                              onCropChange={this.onCropChange.bind(
                                this,
                                "backgroundcrop"
                              )}
                              onCropComplete={this.onCropComplete.bind(
                                this,
                                "backgroundresult",
                                this.state.background_image
                              )}
                              onZoomChange={this.onZoomChange.bind(
                                this,
                                "backgroundzoom"
                              )}
                            />
                          </div>

                          <div className="col-md-12">
                            <div className="image-boxed-model">
                              <span
                                forHtml="profile-image"
                                className="col-form-label btn-span"
                              >
                                {t.ProfilePicture[lang]}
                              </span>
                              <span>
                                {t.recomendedres[lang]}: 512*512{" "}
                                {t.Pixels[lang]}
                              </span>
                              <div className="d-inline float-right">
                                <Switch
                                  onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  className="switch-create-card"
                                  offColor="#212123"
                                  onColor="#00adb4"
                                  onChange={this.handleCardToggleFields.bind(
                                    this,
                                    "profile_image"
                                  )}
                                  checked={this.state.checked.profile_image}
                                />
                              </div>
                            </div>
                            <div className="upload-btn-wrapper profile_image">
                              <button className="btn">
                                {t.Uploadafile[lang]}
                              </button>
                              <FileBase64
                                multiple={false}
                                className=""
                                onDone={this.getProfileImage.bind(this)}
                              />
                              <div className="file-info">FileName</div>
                              {this.state.profile_image_serve !== "" ? (
                                this.state.image_process ? (
                                  <div class="the-loader">
                                    <Loader
                                      type="TailSpin"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                  </div>
                                ) : (
                                  <div className="file-holder">
                                    <div
                                      className="hover"
                                      onClick={() => {
                                        this.setState({
                                          profile_image_serve: "",
                                          profile_image: "",
                                          profile_image_display: "",
                                        });
                                        this.handleCardToggleFields(
                                          "profile_image",
                                          false
                                        );
                                      }}
                                    >
                                      <div className="hover-text">
                                        Click To Remove
                                      </div>
                                    </div>
                                    <img
                                      src={this.state.profile_image_serve}
                                      className="file-image"
                                      alt=""
                                    />
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                              {this.state.profile_image_serve === "" ? (
                                this.state.image_process ? (
                                  <div class="the-loader">
                                    <Loader
                                      type="TailSpin"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      this.state.set_profile_crop
                                        ? "hide-it"
                                        : "file-holder"
                                    }
                                  >
                                    <div
                                      className="hover"
                                      onClick={() => {
                                        this.setState({
                                          profile_image: "",
                                          profile_image_display: "",
                                        });
                                        this.handleCardToggleFields(
                                          "profile_image",
                                          false
                                        );

                                        alert(this.state.profileresult);
                                      }}
                                    >
                                      <div className="hover-text">
                                        Click To Remove
                                      </div>
                                    </div>
                                    <img
                                      src={this.state.profileresult}
                                      className="file-image"
                                      alt=""
                                    />
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              this.state.set_profile_crop
                                ? "cropper"
                                : "hide-it"
                            }
                          >
                            <button
                              type="button"
                              className="cropper-btn"
                              onClick={() =>
                                this.setState({
                                  set_profile_crop: false,
                                })
                              }
                            >
                              {t.Select[lang]}
                            </button>
                            <Cropper
                              image={this.state.profile_image}
                              crop={this.state.profilecrop}
                              zoom={this.state.profilezoom}
                              aspect={this.state.profileaspect}
                              onCropChange={this.onCropChange.bind(
                                this,
                                "profilecrop"
                              )}
                              onCropComplete={this.onCropComplete.bind(
                                this,
                                "profileresult",
                                this.state.profile_image
                              )}
                              onZoomChange={this.onZoomChange.bind(
                                this,
                                "profilezoom"
                              )}
                            />
                          </div>
                          <div className="col-md-10 mt-3 mb-5">
                            <span id="hint_color">
                              <a
                                href="assets/images/header_color.png"
                                target="_blank"
                              >
                                {t.whatwillitaffect[lang]}
                              </a>
                            </span>
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              name="default_background_color"
                              id="default_background_color"
                              onClick={() =>
                                this.togglePicker(
                                  "default_background_color",
                                  false
                                )
                              }
                              value={this.state.colors.default_background_color}
                            />
                            <label
                              forHtml="default_background_color"
                              className={
                                this.state.colors.default_background_color !==
                                ""
                                  ? "show"
                                  : ""
                              }
                            >
                              {t.Backgroundcolor[lang]}
                            </label>
                            {this.state.displayColorPicker
                              .default_background_color ? (
                              <div class="pop-over">
                                <div
                                  class="pop-over-cover"
                                  onClick={this.closePicker.bind(
                                    this,
                                    "default_background_color"
                                  )}
                                >
                                  {t.Select[lang]}
                                </div>
                                <div
                                  class="pop-over-cover"
                                  onClick={this.pickerCancel.bind(
                                    this,
                                    "default_background_color"
                                  )}
                                >
                                  cancel
                                </div>
                                <SketchPicker
                                  color={
                                    this.state.colors.default_background_color
                                  }
                                  onChange={this.handleColor.bind(
                                    this,
                                    "default_background_color"
                                  )}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="col-md-2 mt-3 mb-5 default_background_color_show"
                            id="default_background_color_show"
                          ></div>

                          <div className="col-md-12 text-center">
                            <button
                              class="mb-2 mr-2 btn-hover-shine btn btn-wide btn-light"
                              type="button"
                              onClick={() =>
                                this.move("#tab-eg15-0", "#basic-info-btn")
                              }
                            >
                              {t.Previous[lang]}
                            </button>
                            <button
                              class="mb-2 mr-2 btn-hover-shine btn btn-wide btn-dark"
                              type="button"
                              onClick={() =>
                                this.move("#tab-eg15-2", "#social-info-btn")
                              }
                            >
                              {t.Next[lang]}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane show"
                        id="tab-eg15-2"
                        role="tabpanel"
                      >
                        <div className="form-row">
                          <div className="col-md-12">
                            <p className="helper-text">{t.provide[lang]}</p>
                            <hr />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-6">
                            <div className="position-relative form-group">
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChange}
                                value={this.state.name}
                                name="name"
                                id="name"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="name"
                                className={this.state.name !== "" ? "show" : ""}
                              >
                                {t.Name[lang]}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="position-relative form-group">
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChange}
                                value={this.state.position}
                                name="position"
                                id="position"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="position"
                                className={
                                  this.state.position !== "" ? "show" : ""
                                }
                              >
                                {t.Position[lang]}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="position-relative form-group">
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChange}
                                value={this.state.specialization}
                                name="specialization"
                                id="specialization"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="specialization"
                                className={
                                  this.state.specialization !== "" ? "show" : ""
                                }
                              >
                                {t.Specialization[lang]}
                              </label>
                            </div>
                          </div>

                          {/*                           
                          <div className="col-md-12">
                            <div className="position-relative form-group">
                              <textarea rows="6"
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChange}
                                value={this.state.background}
                                name="background"
                                id="background"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="background"
                                className={
                                  this.state.background !== "" ? "show" : ""
                                }
                              >
                                {t.Background[lang]}
                              </label>
                            </div>
                          </div> */}

                          {/* ======================= BACKGROUND======================= */}

                          <div className="col-md-12">
                            <div className="position-relative form-group">
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChange}
                                value={
                                  this.state.field_title !== ""
                                    ? this.state.field_title
                                    : t.Background[lang]
                                }
                                value={t.Background[lang]}
                                name="field_title"
                                id="field_title"
                                type="text"
                                className="form-control"
                                placeholder={t.Background[lang]}
                              />
                              <textarea
                                name="field_value"
                                id="field_value"
                                className="form-control"
                                onChange={this.onChange}
                                value={
                                  this.state.field_value
                                    ? this.state.field_value
                                    : ""
                                }
                                rows="6"
                              ></textarea>
                            </div>
                          </div>

                          <div className="col-md-10 mt-3 mb-4">
                            <span id="hint_color">
                              <a
                                href="assets/images/background.png"
                                target="_blank"
                              >
                                {t.whatwillitaffect[lang]}
                              </a>
                            </span>

                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              name="contact_background_color"
                              id="contact_background_color"
                              onClick={() =>
                                this.togglePicker(
                                  "contact_background_color",
                                  false
                                )
                              }
                              value={this.state.colors.contact_background_color}
                            />
                            <label
                              forHtml="contact_background_color"
                              className={
                                this.state.colors.contact_background_color !==
                                ""
                                  ? "show"
                                  : ""
                              }
                            >
                              {t.Background[lang]}
                            </label>
                            {this.state.displayColorPicker
                              .contact_background_color ? (
                              <div class="pop-over">
                                <div
                                  class="pop-over-cover"
                                  onClick={this.closePicker.bind(
                                    this,
                                    "contact_background_color"
                                  )}
                                >
                                  Select
                                </div>
                                <div
                                  class="pop-over-cover"
                                  onClick={this.pickerCancel.bind(
                                    this,
                                    "contact_background_color"
                                  )}
                                >
                                  cancel
                                </div>
                                <SketchPicker
                                  color={
                                    this.state.colors.contact_background_color
                                  }
                                  onChange={this.handleColor.bind(
                                    this,
                                    "contact_background_color"
                                  )}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="col-md-10 mt-3 mb-4"
                            id="contact_background_color_show"
                          ></div>
                          <div className="col-md-10 mt-3 mb-4">
                            <span id="hint_color">
                              <a
                                href="assets/images/b_font.png"
                                target="_blank"
                              >
                                {t.whatwillitaffect[lang]}
                              </a>
                            </span>
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              name="contact_forground_color"
                              id="contact_forground_color"
                              onClick={() =>
                                this.togglePicker(
                                  "contact_forground_color",
                                  false
                                )
                              }
                              value={this.state.colors.contact_forground_color}
                            />
                            <label
                              forHtml="contact_forground_color"
                              className={
                                this.state.colors.contact_forground_color !== ""
                                  ? "show"
                                  : ""
                              }
                            >
                              {t.Fontcolor[lang]}
                            </label>
                            {this.state.displayColorPicker
                              .contact_forground_color ? (
                              <div class="pop-over">
                                <div
                                  class="pop-over-cover"
                                  onClick={this.closePicker.bind(
                                    this,
                                    "contact_forground_color"
                                  )}
                                >
                                  {t.Select[lang]}
                                </div>
                                <div
                                  class="pop-over-cover"
                                  onClick={this.pickerCancel.bind(
                                    this,
                                    "contact_forground_color"
                                  )}
                                >
                                  cancel
                                </div>
                                <SketchPicker
                                  color={
                                    this.state.colors.contact_forground_color
                                  }
                                  onChange={this.handleColor.bind(
                                    this,
                                    "contact_forground_color"
                                  )}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="col-md-10 mt-3 mb-4"
                            id="contact_forground_color_show"
                          ></div>
                          <div className="col-md-12 text-center">
                            <button
                              class="mb-2 mr-2 btn-hover-shine btn btn-wide btn-light"
                              type="button"
                              onClick={() =>
                                this.move("#tab-eg15-1", "#contact-info-btn")
                              }
                            >
                              {t.Previous[lang]}
                            </button>
                            <button
                              class="mb-2 mr-2 btn-hover-shine btn btn-wide btn-dark"
                              type="button"
                              onClick={() =>
                                this.move("#tab-eg15-3", "#card-info-btn")
                              }
                            >
                              {t.Next[lang]}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane show"
                        id="tab-eg15-3"
                        role="tabpanel"
                      >
                        <div className="form-row">
                          <div className="col-md-12">
                            <p className="helper-text">
                              {t.Buttonsselection1[lang]} <br />
                              {t.Buttonsselection2[lang]}
                              <br />
                              {t.Buttonsselection3[lang]}
                            </p>
                            <hr />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "phone"
                                )}
                                checked={this.state.checked.phone}
                              />
                            </div>
                            <div className="position-relative form-group">
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.phone}
                                name="phone"
                                id="phone"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="phone"
                                className={
                                  this.state.phone !== "" ? "show" : ""
                                }
                              >
                                {t.Phone[lang]}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "alt_mobile"
                                )}
                                checked={this.state.checked.alt_mobile}
                              />
                            </div>
                            <div className="position-relative form-group">
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.alt_mobile}
                                name="alt_mobile"
                                id="alt_mobile"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="alt_mobile"
                                className={
                                  this.state.alt_mobile !== "" ? "show" : ""
                                }
                              >
                                {t.AlternativeNumber[lang]}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "office_number"
                                )}
                                checked={this.state.checked.office_number}
                              />
                            </div>
                            <div className="position-relative form-group">
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.office_number}
                                name="office_number"
                                id="office_number"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="office_number"
                                className={
                                  this.state.office_number !== "" ? "show" : ""
                                }
                              >
                                {t.OfficeNumber[lang]}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "whatsapp"
                                )}
                                checked={this.state.checked.whatsapp}
                              />
                            </div>
                            <div className="position-relative form-group">
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.whatsapp}
                                name="whatsapp"
                                id="whatsapp"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="whatsapp"
                                className={
                                  this.state.whatsapp !== "" ? "show" : ""
                                }
                              >
                                {t.WhatsappNumber[lang]}
                              </label>
                              <span>
                                (Add ‘+’ and your country code before the
                                number)
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "email"
                                )}
                                checked={this.state.checked.email}
                              />
                            </div>
                            <div className="position-relative form-group">
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.email}
                                name="email"
                                id="email"
                                type="email"
                                className="form-control"
                              />
                              <label
                                forHtml="email"
                                className={
                                  this.state.email !== "" ? "show" : ""
                                }
                              >
                                {t.Email[lang]}
                              </label>
                              <small class="form-error-mode" id="email-error">
                                {t.req[lang]}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "waze"
                                )}
                                checked={this.state.checked.waze}
                              />
                            </div>
                            <div className="position-relative form-group">
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.waze}
                                name="waze"
                                id="waze"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="waze"
                                className={this.state.waze !== "" ? "show" : ""}
                              >
                                {t.Waze[lang]}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "website"
                                )}
                                checked={this.state.checked.website}
                              />
                            </div>
                            <div
                              className="position-relative form-group"
                              data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.website}
                                name="website"
                                id="website"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="website"
                                className={
                                  this.state.website !== "" ? "show" : ""
                                }
                              >
                                {t.Website[lang]}
                              </label>
                            </div>
                          </div>
                          <ReactTooltip />
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "facebook"
                                )}
                                checked={this.state.checked.facebook}
                              />
                            </div>
                            <div
                              className="position-relative form-group"
                              data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.facebook}
                                name="facebook"
                                id="facebook"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="facebook"
                                className={
                                  this.state.facebook !== "" ? "show" : ""
                                }
                              >
                                {t.Facebook[lang]}
                              </label>
                            </div>
                            <ReactTooltip />
                          </div>
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "instagram"
                                )}
                                checked={this.state.checked.instagram}
                              />
                            </div>
                            <div
                              className="position-relative form-group"
                              data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.instagram}
                                name="instagram"
                                id="instagram"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="instagram"
                                className={
                                  this.state.instagram !== "" ? "show" : ""
                                }
                              >
                                {t.Instagram[lang]}
                              </label>
                            </div>
                            <ReactTooltip />
                          </div>
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "youtube"
                                )}
                                checked={this.state.checked.youtube}
                              />
                            </div>
                            <div
                              className="position-relative form-group"
                              data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.youtube}
                                name="youtube"
                                id="youtube"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="youtube"
                                className={
                                  this.state.youtube !== "" ? "show" : ""
                                }
                              >
                                {t.Youtube[lang]}
                              </label>
                            </div>
                            <ReactTooltip />
                          </div>
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "twitter"
                                )}
                                checked={this.state.checked.twitter}
                              />
                            </div>
                            <div
                              className="position-relative form-group"
                              data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.twitter}
                                name="twitter"
                                id="twitter"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="twitter"
                                className={
                                  this.state.twitter !== "" ? "show" : ""
                                }
                              >
                                {t.Twitter[lang]}
                              </label>
                            </div>
                            <ReactTooltip />
                          </div>
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "fax"
                                )}
                                checked={this.state.checked.fax}
                              />
                            </div>
                            <div className="position-relative form-group">
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.fax}
                                name="fax"
                                id="fax"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="fax"
                                className={this.state.fax !== "" ? "show" : ""}
                              >
                                {t.FaxNumber[lang]}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "linkdin"
                                )}
                                checked={this.state.checked.linkdin}
                              />
                            </div>
                            <div
                              className="position-relative form-group"
                              data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.linkdin}
                                name="linkdin"
                                id="linkdin"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="linkdin"
                                className={
                                  this.state.linkdin !== "" ? "show" : ""
                                }
                              >
                                {t.Linkdin[lang]}
                              </label>
                            </div>
                            <ReactTooltip />
                          </div>
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "google_plus"
                                )}
                                checked={this.state.checked.google_plus}
                              />
                            </div>
                            <div
                              className="position-relative form-group"
                              data-tip="Use the Skype Id"
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.google_plus}
                                name="google_plus"
                                id="google_plus"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="Skype"
                                className={
                                  this.state.google_plus !== "" ? "show" : ""
                                }
                              >
                                {t.GooglePlus[lang]}
                              </label>
                            </div>
                            <ReactTooltip />
                          </div>

                          {/* tiktok starts */}
                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "tiktok"
                                )}
                                checked={this.state.checked.tiktok}
                              />
                            </div>
                            <div
                              className="position-relative form-group"
                              data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.tiktok}
                                name="tiktok"
                                id="tiktok"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="tiktok"
                                className={
                                  this.state.tiktok !== "" ? "show" : ""
                                }
                              >
                                {t.Tiktok[lang]}
                              </label>
                            </div>
                            <ReactTooltip />
                          </div>
                          {/* tiktok ends */}

                          <hr />
                          <hr />
                          <br></br>
                          <br></br>
                          <div className="col-md-6 mt-5">
                            <span id="hint_color_t">
                              <a
                                href="assets/images/buttons_background.png"
                                target="_blank"
                              >
                                {t.whatwillitaffect[lang]}
                              </a>
                            </span>
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              name="buttons_background_color"
                              id="buttons_background_color"
                              onClick={() =>
                                this.togglePicker(
                                  "buttons_background_color",
                                  false
                                )
                              }
                              value={this.state.colors.buttons_background_color}
                              className="form-control"
                            />
                            <label
                              forHtml="buttons_background_color"
                              className={
                                this.state.colors.buttons_background_color !==
                                ""
                                  ? "show"
                                  : ""
                              }
                            >
                              {t.Buttonsbackgroundcolor[lang]}
                            </label>
                            {this.state.displayColorPicker
                              .buttons_background_color ? (
                              <div class="pop-over">
                                <div
                                  class="pop-over-cover"
                                  onClick={this.closePicker.bind(
                                    this,
                                    "buttons_background_color"
                                  )}
                                >
                                  Select
                                </div>
                                <div
                                  class="pop-over-cover"
                                  onClick={this.pickerCancel.bind(
                                    this,
                                    "buttons_background_color"
                                  )}
                                >
                                  cancel
                                </div>
                                <SketchPicker
                                  color={
                                    this.state.colors.buttons_background_color
                                  }
                                  onChange={this.handleColor.bind(
                                    this,
                                    "buttons_background_color"
                                  )}
                                />
                              </div>
                            ) : null}
                          </div>
                          {/* <div className="col-md-2 mt-3" id="buttons_background_color_show"></div> */}
                          <div className="col-md-6 mt-5">
                            <span id="hint_color_t">
                              <a
                                href="assets/images/icons_background.png"
                                target="_blank"
                              >
                                {t.whatwillitaffect[lang]}
                              </a>
                            </span>
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              name="buttons_icon_background_color"
                              id="buttons_icon_background_color"
                              onClick={() =>
                                this.togglePicker(
                                  "buttons_icon_background_color",
                                  false
                                )
                              }
                              value={
                                this.state.colors.buttons_icon_background_color
                              }
                              className="form-control"
                            />
                            <label
                              forHtml="buttons_icon_background_color"
                              className={
                                this.state.colors
                                  .buttons_icon_background_color !== ""
                                  ? "show"
                                  : ""
                              }
                            >
                              {t.iconbackcolor[lang]}
                            </label>
                            {this.state.displayColorPicker
                              .buttons_icon_background_color ? (
                              <div class="pop-over">
                                <div
                                  class="pop-over-cover"
                                  onClick={this.closePicker.bind(
                                    this,
                                    "buttons_icon_background_color"
                                  )}
                                >
                                  Select
                                </div>
                                <div
                                  class="pop-over-cover"
                                  onClick={this.pickerCancel.bind(
                                    this,
                                    "buttons_icon_background_color"
                                  )}
                                >
                                  cancel
                                </div>
                                <SketchPicker
                                  color={
                                    this.state.colors
                                      .buttons_icon_background_color
                                  }
                                  onChange={this.handleColor.bind(
                                    this,
                                    "buttons_icon_background_color"
                                  )}
                                />
                              </div>
                            ) : null}
                          </div>
                          {/* <div className="col-md-2 mt-3" id="buttons_icon_background_color_show"></div> */}
                          <div className="col-md-6 mt-5">
                            <span id="hint_color">
                              <a
                                href="assets/images/icons_color.png"
                                target="_blank"
                              >
                                {t.whatwillitaffect[lang]}
                              </a>
                            </span>

                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              name="buttons_icon_fill_color"
                              id="buttons_icon_fill_color"
                              onClick={() =>
                                this.togglePicker(
                                  "buttons_icon_fill_color",
                                  false
                                )
                              }
                              value={this.state.colors.buttons_icon_fill_color}
                              className="form-control"
                            />
                            <label
                              forHtml="buttons_icon_fill_color"
                              className={
                                this.state.colors.buttons_icon_fill_color !== ""
                                  ? "show"
                                  : ""
                              }
                            >
                              {t.iconcolor[lang]}
                            </label>
                            {this.state.displayColorPicker
                              .buttons_icon_fill_color ? (
                              <div class="pop-over">
                                <div
                                  class="pop-over-cover"
                                  onClick={this.closePicker.bind(
                                    this,
                                    "buttons_icon_fill_color"
                                  )}
                                >
                                  Select
                                </div>
                                <div
                                  class="pop-over-cover"
                                  onClick={this.pickerCancel.bind(
                                    this,
                                    "buttons_icon_fill_color"
                                  )}
                                >
                                  cancel
                                </div>
                                <SketchPicker
                                  color={
                                    this.state.colors.buttons_icon_fill_color
                                  }
                                  onChange={this.handleColor.bind(
                                    this,
                                    "buttons_icon_fill_color"
                                  )}
                                />
                              </div>
                            ) : null}
                          </div>
                          {/* <div className="col-md-2 mt-5 mb-5" id="buttons_icon_fill_color_show"></div> */}
                          <div className="col-md-6 mt-5">
                            <span id="hint_color">
                              <a
                                href="assets/images/icons_text.png"
                                target="_blank"
                              >
                                {t.whatwillitaffect[lang]}
                              </a>
                            </span>

                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              name="buttons_text_color"
                              id="buttons_text_color"
                              onClick={() =>
                                this.togglePicker("buttons_text_color", false)
                              }
                              value={this.state.colors.buttons_text_color}
                              className="form-control"
                            />
                            <label
                              forHtml="buttons_text_color"
                              className={
                                this.state.colors.buttons_text_color !== ""
                                  ? "show"
                                  : ""
                              }
                            >
                              {t.icontextcolor[lang]}
                            </label>
                            {this.state.displayColorPicker
                              .buttons_text_color ? (
                              <div class="pop-over">
                                <div
                                  class="pop-over-cover"
                                  onClick={this.closePicker.bind(
                                    this,
                                    "buttons_text_color"
                                  )}
                                >
                                  {t.Select[lang]}
                                </div>
                                <div
                                  class="pop-over-cover"
                                  onClick={this.pickerCancel.bind(
                                    this,
                                    "buttons_text_color"
                                  )}
                                >
                                  cancel
                                </div>
                                <SketchPicker
                                  color={this.state.colors.buttons_text_color}
                                  onChange={this.handleColor.bind(
                                    this,
                                    "buttons_text_color"
                                  )}
                                />
                              </div>
                            ) : null}
                          </div>
                          {/* <div className="col-md-2 mt-5 mb-5" id="buttons_text_color_show"> </div> */}
                          <div className="col-md-12 mt-5 text-center">
                            <button
                              class="mb-2 mr-2 btn-hover-shine btn btn-wide btn-light"
                              type="button"
                              onClick={() =>
                                this.move("#tab-eg15-2", "#social-info-btn")
                              }
                            >
                              {t.Previous[lang]}
                            </button>
                            <button
                              class="mb-2 mr-2 btn-hover-shine btn btn-wide btn-dark"
                              type="button"
                              onClick={() =>
                                this.move("#tab-eg15-4", "#card-content-btn")
                              }
                            >
                              {t.Next[lang]}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane show"
                        id="tab-eg15-4"
                        role="tabpanel"
                      >
                        <div className="form-row">
                          <div className="col-md-12">
                            <p className="helper-text">
                              {t.CardSectionsInformation1[lang]} <br />
                              {t.CardSectionsInformation2[lang]}
                            </p>
                            <hr />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-12">
                            {/* <span forHtml="about_us" className="col-form-label btn-span"> */}
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              className="width-20"
                              type="text"
                              placeholder={this.state.about_us.label}
                              onChange={this.onChangeL.bind(
                                this,
                                "about_us",
                                "label"
                              )}
                              value={t.AboutUs[lang]}
                            />
                            {/* </span> */}
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleFields.bind(
                                  this,
                                  "about_us"
                                )}
                                checked={this.state.checked.about_us}
                              />
                            </div>
                            <div className="position-relative form-group">
                              <textarea
                                name="about_us"
                                id="about_us"
                                className="form-control pull-left"
                                placeholder={t.AboutUs[lang]}
                                value={this.state.about_us.text}
                                onChange={this.onChangeL.bind(
                                  this,
                                  "about_us",
                                  "text"
                                )}
                                rows="8"
                              ></textarea>
                            </div>
                          </div>

                          {/* end qr code */}
                          <div className="col-md-12">
                            {/* <span forHtml="about_us" className="col-form-label btn-span"> */}
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              // value={this.state.our_vision.label}
                              placeholder={t.OurVision[lang]}
                              className="width-20"
                              onChange={this.onChangeL.bind(
                                this,
                                "our_vision",
                                "label"
                              )}
                              value={t.OurVision[lang]}
                            />
                            {/* </span> */}
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleFields.bind(
                                  this,
                                  "our_vision"
                                )}
                                checked={this.state.checked.our_vision}
                              />
                            </div>
                            <div className="position-relative form-group">
                              <textarea
                                name="our_vision"
                                id="our_vision"
                                className="form-control pull-left"
                                onChange={this.onChangeL.bind(
                                  this,
                                  "our_vision",
                                  "text"
                                )}
                                placeholder={t.OurVision_p[lang]}
                                value={this.state.our_vision.text}
                                rows="8"
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            {/* <span forHtml="about_us" className="col-form-label btn-span"> */}
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              // value={this.state.our_application.label}
                              placeholder={t.OurApplication[lang]}
                              className="width-20"
                              onChange={this.handleAppLinkText("label")}
                              value={t.OurApplication[lang]}
                            />
                            {/* </span> */}
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleFields.bind(
                                  this,
                                  "our_application"
                                )}
                                checked={this.state.checked.our_application}
                              />
                            </div>
                            <div className="position-relative form-group">
                              <textarea
                                name="our_application"
                                id="our_application"
                                className="form-control pull-left"
                                onChange={this.handleAppLinkText("text")}
                                value={this.state.our_application.text}
                                placeholder={t.OurApplication_p[lang]}
                                rows="8"
                              ></textarea>
                            </div>
                            <div
                              className="position-relative form-group mt-5"
                              data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                type="text"
                                onChange={this.handleAppLinkText("google_url")}
                                value={this.state.our_application.google_url}
                                className="form-control"
                              />
                              <label
                                htmlFor="our_application"
                                className={
                                  this.state.our_application.google_url !== ""
                                    ? "show"
                                    : ""
                                }
                              >
                                {t.googleplayurl[lang]}
                              </label>
                            </div>
                            <ReactTooltip />
                            <div
                              className="position-relative form-group  mt-5"
                              data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                type="text"
                                onChange={this.handleAppLinkText("apple_url")}
                                value={this.state.our_application.apple_url}
                                className="form-control"
                              />
                              <label
                                htmlFor="our_application"
                                className={
                                  this.state.our_application.apple_url !== ""
                                    ? "show"
                                    : ""
                                }
                              >
                                {t.ApplestoreURL[lang]}
                              </label>
                            </div>
                            <ReactTooltip />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-md-12">
                            {/* <span forHtml="success-story" className="col-form-label btn-span"> */}
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              id="success_story"
                              placeholder={t.SuccessStories[lang]}
                              onChange={this.onChange}
                              value={
                                this.state.heading_story !== ""
                                  ? this.state.heading_story
                                  : t.SuccessStories[lang]
                              }
                              className="width-20"
                              name="heading_story"
                              // onChange={this.handleStoryText(
                              // 	0
                              // )}
                            />
                            {/* </span> */}
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleFields.bind(
                                  this,
                                  "our_stories"
                                )}
                                checked={this.state.checked.our_stories}
                              />
                            </div>
                            <button
                              className="d-block btn-mob"
                              type="button"
                              onClick={this.addStory}
                            >
                              <FontAwesomeIcon icon={faPlusSquare} />
                            </button>
                          </div>
                          <div className="col-md-12">
                            {this.state.our_stories.map((story, index) =>
                              index !== 0 ? (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="col-sm-12 m-0 p-0 text-center">
                                      <div>
                                        <button
                                          style={{
                                            "text-align": "right",
                                          }}
                                          type="button"
                                          className="close-btn"
                                          onClick={this.handleStoryDelete(
                                            index
                                          )}
                                        >
                                          <FontAwesomeIcon
                                            icon={faMinusSquare}
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-sm-12 m-0 p-0 d-inline-block text-center">
                                      <div key={index}>
                                        <textarea
                                          className="form-control pull-left"
                                          value={story}
                                          onChange={this.handleStoryText(index)}
                                          placeholder={t.SuccessStories_p[lang]}
                                          rows="8"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </div>
                        </div>
                        <hr />

                        {/* For survey */}
                        <div className="form-row">
                          <div className="col-md-12">
                            {/* <span forHtml="success-story" className="col-form-label btn-span"> */}
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              id="success_story"
                              onChange={this.onChange}
                              value={
                                this.state.heading_survey !== ""
                                  ? this.state.heading_survey
                                  : t.Survey[lang]
                              }
                              name="heading_survey"
                              placeholder={t.Survey[lang]}
                              className="width-20"
                            />
                            <div
                              className="position-relative form-group mt-5"
                              data-tip="Please Enter Survey Title"
                            >
                              <input
                                type="text"
                                class="form-control"
                                placeholder={t.Enter_Survey_Title[lang]}
                                value={
                                  this.state.survey && this.state.survey.length
                                    ? this.state.survey[0].title
                                    : t.Enter_Survey_Title[lang]
                                }
                                onChange={this.handleSurveytitle(0)}
                              />
                            </div>
                            {/* </span> */}
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleFields.bind(
                                  this,
                                  "survey"
                                )}
                                checked={this.state.checked.survey}
                              />
                            </div>
                            <button
                              className="d-block btn-mob"
                              type="button"
                              onClick={this.addsurvey}
                            >
                              <FontAwesomeIcon icon={faPlusSquare} />
                            </button>
                          </div>
                          <div className="col-md-12">
                            {this.state.survey &&
                              this.state.survey.map((story, index) =>
                                index !== 0 ? (
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="col-sm-12 m-0 p-0 text-center">
                                        <div>
                                          <button
                                            style={{
                                              "text-align": "right",
                                            }}
                                            type="button"
                                            className="close-btn"
                                            onClick={this.handleSurveyDelete(
                                              index
                                            )}
                                          >
                                            <FontAwesomeIcon
                                              icon={faMinusSquare}
                                            />
                                          </button>
                                        </div>
                                      </div>
                                      <div className="col-sm-12 m-0 p-0 d-inline-block text-center">
                                        <div key={index}>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t.Enter_Option[lang]}
                                            value={story}
                                            onChange={this.handlesurveyText(
                                              index,
                                              "text"
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                          </div>
                        </div>

                        <hr></hr>

                        {/* End survey */}
                        <div className="form-row">
                          <div className="col-md-12">
                            {/* <span forHtml="success-story" className="col-form-label btn-span"> */}
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              placeholder={t.ActivityHour[lang]}
                              onChange={this.onChange}
                              value={
                                this.state.heading_active_hour !== ""
                                  ? this.state.heading_active_hour
                                  : t.ActivityHour[lang]
                              }
                              name="heading_active_hour"
                              className="width-20"
                              // onChange={this.handleActivityText(
                              // 	0
                              // )}
                            />
                            {/* </span> */}
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleFields.bind(
                                  this,
                                  "activity_hour"
                                )}
                                checked={this.state.checked.activity_hour}
                              />
                            </div>
                            <button
                              className="d-block btn-mob"
                              type="button"
                              onClick={this.addActivity}
                            >
                              <FontAwesomeIcon icon={faPlusSquare} />
                            </button>
                          </div>

                          <div className="col-md-12">
                            {this.state.activity_hour.map((one, index) =>
                              index !== 0 ? (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="col-sm-12 m-0 p-0 text-center">
                                      <div>
                                        <button
                                          style={{
                                            "text-align": "right",
                                          }}
                                          type="button"
                                          className="close-btn"
                                          onClick={this.handleActivityDelete(
                                            index
                                          )}
                                        >
                                          <FontAwesomeIcon
                                            icon={faMinusSquare}
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-sm-12 m-0 p-0 d-inline-block text-center">
                                      <div key={index}>
                                        <textarea
                                          className="form-control pull-left"
                                          value={one}
                                          onChange={this.handleActivityText(
                                            index
                                          )}
                                          rows="8"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </div>
                        </div>

                        <hr />

                        <div className="form-row">
                          <div className="col-md-12">
                            {/* <span forHtml="articles" className="col-form-label btn-span"> */}
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              placeholder={t.Articles[lang]}
                              onChange={this.onChange}
                              value={
                                this.state.heading_article !== ""
                                  ? this.state.heading_article
                                  : t.Articles[lang]
                              }
                              name="heading_article"
                              className="width-20"
                              // onChange={this.handleLinkText(
                              // 	"articles",
                              // 	0,
                              // 	"label"
                              // )}
                            />
                            {/* </span> */}
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleFields.bind(
                                  this,
                                  "articles"
                                )}
                                checked={this.state.checked.articles}
                              />
                            </div>
                            <button
                              className="d-block btn-mob"
                              type="button"
                              onClick={() => this.addLinks("articles")}
                            >
                              <FontAwesomeIcon icon={faPlusSquare} />
                            </button>
                          </div>
                          <div className="col-md-12">
                            {this.state.articles.map((article, index) =>
                              index !== 0 ? (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="col-sm-1 p-0 m-0 d-inline-block text-center">
                                      <button
                                        className="close-btn"
                                        type="button"
                                        onClick={this.handleLinkDelete(
                                          "articles",
                                          index
                                        )}
                                      >
                                        <FontAwesomeIcon icon={faMinusSquare} />
                                      </button>
                                      <div>{index}</div>
                                    </div>
                                    <div className="col-sm-11 p-0 m-0 d-inline-block">
                                      <div key={index}>
                                        <div className="position-relative form-group">
                                          <input
                                            onKeyPress={(e) => {
                                              e.key === "Enter" &&
                                                e.preventDefault();
                                            }}
                                            type="text"
                                            onChange={this.handleLinkText(
                                              "articles",
                                              index,
                                              "title"
                                            )}
                                            value={article.title}
                                            className="form-control"
                                          />
                                          <label
                                            htmlFor="links-title"
                                            className={
                                              article.title !== "" ? "show" : ""
                                            }
                                          >
                                            {t.ArticleTitle[lang]}
                                          </label>
                                        </div>
                                        <div
                                          className="position-relative form-group mt-5"
                                          data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                                        >
                                          <input
                                            onKeyPress={(e) => {
                                              e.key === "Enter" &&
                                                e.preventDefault();
                                            }}
                                            type="text"
                                            onChange={this.handleLinkText(
                                              "articles",
                                              index,
                                              "link"
                                            )}
                                            value={article.link}
                                            className="form-control"
                                          />
                                          <label
                                            htmlFor="links-link"
                                            className={
                                              article.link !== "" ? "show" : ""
                                            }
                                          >
                                            {t.ArticleLink[lang]}
                                          </label>
                                        </div>
                                        <ReactTooltip />
                                        <hr />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </div>

                          {/* ----------------tiktok start---------------------      */}

                          <div className="col-md-6">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleButtonsFields.bind(
                                  this,
                                  "tiktok"
                                )}
                                checked={this.state.checked.tiktok}
                              />
                            </div>
                            <div
                              className="position-relative form-group"
                              data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChangeB}
                                value={this.state.tiktok}
                                name="tiktok"
                                id="tiktok"
                                type="text"
                                className="form-control custom-color"
                              />
                              <label
                                forHtml="tiktok"
                                className={
                                  this.state.tiktok !== "" ? "show" : ""
                                }
                              >
                                {t.Tiktok[lang]}
                              </label>
                            </div>
                            <ReactTooltip />
                          </div>

                          {/* ----------------tiktok ends---------------------      */}
                        </div>

                        <hr />

                        <div className="form-row">
                          <div className="col-md-12">
                            {/* <span forHtml="employees" className="col-form-label btn-span"> */}
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              placeholder={t.Employees[lang]}
                              onChange={this.onChange}
                              value={
                                this.state.heading_employee !== ""
                                  ? this.state.heading_employee
                                  : t.Employees[lang]
                              }
                              name="heading_employee"
                              className="width-20 emp"
                              // onChange={this.handleLinkText(
                              // 	"employees",
                              // 	0,
                              // 	"label"
                              // )}
                            />
                            {/* </span> */}
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleFields.bind(
                                  this,
                                  "employees"
                                )}
                                checked={this.state.checked.employees}
                              />
                            </div>
                            <button
                              className="d-block btn-mob"
                              type="button"
                              onClick={() => this.addLinks("employees")}
                            >
                              <FontAwesomeIcon icon={faPlusSquare} />
                            </button>
                          </div>
                          <div className="col-md-12">
                            {this.state.employees.map((employee, index) =>
                              index !== 0 ? (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="col-sm-1 p-0 m-0 d-inline-block text-center">
                                      <button
                                        className="close-btn"
                                        type="button"
                                        onClick={this.handleLinkDelete(
                                          "employees",
                                          index
                                        )}
                                      >
                                        <FontAwesomeIcon icon={faMinusSquare} />
                                      </button>
                                      <div>{index}</div>
                                    </div>
                                    <div className="col-sm-11 p-0 m-0 d-inline-block">
                                      <div key={index}>
                                        <div className="position-relative form-group">
                                          <input
                                            onKeyPress={(e) => {
                                              e.key === "Enter" &&
                                                e.preventDefault();
                                            }}
                                            type="text"
                                            onChange={this.handleLinkText(
                                              "employees",
                                              index,
                                              "title"
                                            )}
                                            value={employee.title}
                                            className="form-control"
                                          />
                                          <label
                                            htmlFor="links-title"
                                            className={
                                              employee.title !== ""
                                                ? "show"
                                                : ""
                                            }
                                          >
                                            {t.EmployeeTitle[lang]}
                                          </label>
                                        </div>
                                        <div
                                          className="position-relative form-group mt-5"
                                          data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                                        >
                                          <input
                                            onKeyPress={(e) => {
                                              e.key === "Enter" &&
                                                e.preventDefault();
                                            }}
                                            type="text"
                                            onChange={this.handleLinkText(
                                              "employees",
                                              index,
                                              "link"
                                            )}
                                            value={employee.link}
                                            className="form-control"
                                          />
                                          <label
                                            htmlFor="links-link"
                                            className={
                                              employee.link !== "" ? "show" : ""
                                            }
                                          >
                                            {t.EmployeeLink[lang]}
                                          </label>
                                        </div>
                                        <ReactTooltip />
                                        <hr />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </div>
                        </div>
                        <hr />

                        <div className="form-row">
                          <div className="col-md-12">
                            {/* <span forHtml="employees" className="col-form-label btn-span"> */}
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              placeholder={t.Recommendations[lang]}
                              onChange={this.onChange}
                              value={
                                this.state.heading_recommandation !== ""
                                  ? this.state.heading_recommandation
                                  : t.Recommendations[lang]
                              }
                              name="heading_recommandation"
                              className="width-20"
                              // onChange={this.handleLinkText(
                              // 	"recommendations",
                              // 	0,
                              // 	"label"
                              // )}
                            />
                            {/* </span> */}
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleFields.bind(
                                  this,
                                  "recommendations"
                                )}
                                checked={this.state.checked.recommendations}
                              />
                            </div>
                            {/* <button className="d-block btn-mob" type="button" onClick={ () => this.addLinks("recommendations") }>
                                <FontAwesomeIcon icon={faPlusSquare} />
                            </button> */}
                          </div>
                          <div className="col-md-12">
                            {this.state.recommendations.map((rec, index) => (
                              <div className="row">
                                <div className="col-md-12">
                                  {/* <div className="col-sm-1 p-0 m-0 d-inline-block text-center">
                                      <button className="close-btn" type="button" onClick={this.handleLinkDelete("recommendations",index)}>
                                          <FontAwesomeIcon icon={faMinusSquare} />
                                      </button> 
                                      <div></div> 
                                  </div> */}
                                  <div className="col-sm-12 p-0 m-0 d-inline-block">
                                    <div key={index}>
                                      {/* <div className="position-relative form-group">
                                            <input
onKeyPress={e => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                                                type="text"
                                                onChange={this.handleLinkText("recommendations",index, "title")}
                                                value={rec.title}
                                                className="form-control"
                                                />
                                            <label htmlFor="links-title" className={(rec.title !== '') ? 'show' : ''}>{t.RecommendationTitle[lang]}</label>
                                          </div> */}
                                      <div
                                        className="position-relative form-group mt-5"
                                        data-tip="Please Use complete urls i.e 'https://www.example.com/'"
                                      >
                                        <input
                                          onKeyPress={(e) => {
                                            e.key === "Enter" &&
                                              e.preventDefault();
                                          }}
                                          type="text"
                                          onChange={this.handleLinkText(
                                            "recommendations",
                                            index,
                                            "link"
                                          )}
                                          value={rec.link}
                                          className="form-control"
                                        />
                                        <label
                                          htmlFor="links-link"
                                          className={
                                            rec.link !== "" ? "show" : ""
                                          }
                                        >
                                          {t.RecommendationLink[lang]}
                                        </label>
                                      </div>
                                      <ReactTooltip />
                                      <hr />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>

                          {/* for qr code */}

                          <div className="col-md-8">
                            {/* <span forHtml="about_us" className="col-form-label btn-span"> */}
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              style={{ marginBottom: "40px" }}
                              type="text"
                              placeholder={t.Generate_QR_Code[lang]}
                              onChange={this.onChange}
                              value={
                                this.state.heading_qrcode !== ""
                                  ? this.state.heading_qrcode
                                  : t.Generate_QR_Code[lang]
                              }
                              name="heading_qrcode"
                              // onChange={this.onChangeL.bind(
                              // 	this,
                              // 	"qr_code",
                              // 	"label"
                              // )}
                            />
                            <small
                              className="heb-text-align"
                              style={displayBlock}
                            >
                              {" "}
                              {
                                "QR code will be created per your card URL and will appear in your card."
                              }
                            </small>
                            {/* </span> */}
                          </div>
                          <div className="col-md-4">
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleFields.bind(
                                  this,
                                  "qr_code"
                                )}
                                checked={this.state.checked.qr_code}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              // name="leave_a_message_label"
                              onChange={this.onChange}
                              value={
                                this.state.heading_leave_msg !== ""
                                  ? this.state.heading_leave_msg
                                  : t.leaveamessage[lang]
                              }
                              name="heading_leave_msg"
                              id="leave_a_message_label"
                              placeholder={t.leaveamessage[lang]}
                              className="width-20"
                              onChange={this.onChange}
                            />
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleFields.bind(
                                  this,
                                  "leave_a_message"
                                )}
                                checked={this.state.checked.leave_a_message}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div
                              className="position-relative form-group"
                              data-tip={t.req[lang]}
                            >
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChange}
                                value={this.state.leave_a_message}
                                placeholder={t.Email[lang]}
                                name="leave_a_message"
                                id="leave_a_message"
                                type="email"
                                className="form-control"
                              />
                              <small style={displayBlock}>
                                {" "}
                                {t.leaveamessage1[lang]}
                              </small>
                            </div>
                            <ReactTooltip />
                          </div>
                        </div>
                        <hr />
                        <div className="form-row">
                          <div className="col-md-10 mt-4 mb-3">
                            <span id="hint_color_t">
                              <a
                                href="assets/images/content_background.png"
                                target="_blank"
                              >
                                {t.whatwillitaffect[lang]}
                              </a>
                            </span>
                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              name="content_background_color"
                              id="content_background_colorcontent_background_color"
                              onClick={() =>
                                this.togglePicker(
                                  "content_background_color",
                                  false
                                )
                              }
                              value={this.state.colors.content_background_color}
                              className="form-control"
                            />
                            <label
                              forHtml="content_background_color"
                              className={
                                this.state.colors.content_background_color !==
                                ""
                                  ? "show"
                                  : ""
                              }
                            >
                              {t.cntentbackcolor[lang]}
                            </label>
                            {this.state.displayColorPicker
                              .content_background_color ? (
                              <div class="pop-over">
                                <div
                                  class="pop-over-cover"
                                  onClick={this.closePicker.bind(
                                    this,
                                    "content_background_color"
                                  )}
                                >
                                  Select
                                </div>
                                <div
                                  class="pop-over-cover"
                                  onClick={this.pickerCancel.bind(
                                    this,
                                    "content_background_color"
                                  )}
                                >
                                  cancel
                                </div>
                                <SketchPicker
                                  color={
                                    this.state.colors.content_background_color
                                  }
                                  onChange={this.handleColor.bind(
                                    this,
                                    "content_background_color"
                                  )}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="col-md-10 mt-4 mb-3"
                            id="content_background_color_show"
                          ></div>

                          <div className="col-md-10 mt-4 mb-3">
                            <span id="hint_color_t">
                              <a
                                href="assets/images/content_button_color.png"
                                target="_blank"
                              >
                                {t.whatwillitaffect[lang]}
                              </a>
                            </span>

                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              name="content_button_background_color"
                              id="content_button_background_color"
                              onClick={() =>
                                this.togglePicker(
                                  "content_button_background_color",
                                  false
                                )
                              }
                              value={
                                this.state.colors
                                  .content_button_background_color
                              }
                              className="form-control"
                            />
                            <label
                              forHtml="content_button_background_color"
                              className={
                                this.state.colors
                                  .content_button_background_color !== ""
                                  ? "show"
                                  : ""
                              }
                            >
                              {t.cntentbtnkcolor[lang]}
                            </label>
                            {this.state.displayColorPicker
                              .content_button_background_color ? (
                              <div class="pop-over">
                                <div
                                  class="pop-over-cover"
                                  onClick={this.closePicker.bind(
                                    this,
                                    "content_button_background_color"
                                  )}
                                >
                                  Select
                                </div>
                                <div
                                  class="pop-over-cover"
                                  onClick={this.pickerCancel.bind(
                                    this,
                                    "content_button_background_color"
                                  )}
                                >
                                  cancel
                                </div>
                                <SketchPicker
                                  color={
                                    this.state.colors
                                      .content_button_background_color
                                  }
                                  onChange={this.handleColor.bind(
                                    this,
                                    "content_button_background_color"
                                  )}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="col-md-10 mt-4 mb-3"
                            id="content_button_background_color_show"
                          ></div>

                          <div className="col-md-10 mt-4 mb-3">
                            <span id="hint_color">
                              <a
                                href="assets/images/content_text_color.png"
                                target="_blank"
                              >
                                {t.whatwillitaffect[lang]}
                              </a>
                            </span>

                            <input
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              type="text"
                              name="content_text_color"
                              id="content_text_color"
                              onClick={() =>
                                this.togglePicker("content_text_color", false)
                              }
                              value={this.state.colors.content_text_color}
                              className="form-control"
                            />
                            <label
                              forHtml="content_text_color"
                              className={
                                this.state.colors.content_text_color !== ""
                                  ? "show"
                                  : ""
                              }
                            >
                              {t.texticoncolor[lang]}
                            </label>
                            {this.state.displayColorPicker
                              .content_text_color ? (
                              <div class="pop-over">
                                <div
                                  class="pop-over-cover"
                                  onClick={this.closePicker.bind(
                                    this,
                                    "content_text_color"
                                  )}
                                >
                                  Select
                                </div>
                                <div
                                  class="pop-over-cover"
                                  onClick={this.pickerCancel.bind(
                                    this,
                                    "content_text_color"
                                  )}
                                >
                                  cancel
                                </div>
                                <SketchPicker
                                  color={this.state.colors.content_text_color}
                                  onChange={this.handleColor.bind(
                                    this,
                                    "content_text_color"
                                  )}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="col-md-2 mt-4 mb-3"
                            id="content_text_color_show"
                          ></div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-12 text-center">
                            <button
                              class="mb-2 mr-2 btn-hover-shine btn btn-wide btn-light"
                              type="button"
                              onClick={() =>
                                this.move("#tab-eg15-3", "#card-info-btn")
                              }
                            >
                              {t.Previous[lang]}
                            </button>
                            <button
                              class="mb-2 mr-2 btn-hover-shine btn btn-wide btn-dark"
                              type="button"
                              onClick={() =>
                                this.move("#tab-eg15-5", "#card-links-btn")
                              }
                            >
                              {t.Next[lang]}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane show"
                        id="tab-eg15-5"
                        role="tabpanel"
                      >
                        <div className="form-row">
                          <div className="col-md-12">
                            <p className="helper-text">
                              {t.CardContent1[lang]} <br />{" "}
                              {t.CardContent2[lang]}
                            </p>
                            <hr />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-12">
                            <div className="image-boxed-model">
                              <span
                                forHtml="business-image"
                                className="col-form-label btn-span"
                              >
                                {t.GalleryImage[lang]}
                              </span>
                              <div className="d-inline float-right">
                                <Switch
                                  onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  className="switch-create-card"
                                  offColor="#212123"
                                  onColor="#00adb4"
                                  onChange={this.handleCardToggleFields.bind(
                                    this,
                                    "images"
                                  )}
                                  checked={this.state.checked.images}
                                />
                              </div>
                            </div>
                            <ImageUploader
                              withIcon={true}
                              buttonText={t.Chooseimages[lang]}
                              onChange={this.getAImages.bind(this)}
                              imgExtension={[
                                ".jpg",
                                ".gif",
                                ".png",
                                ".gif",
                                ".jpeg",
                              ]}
                              maxFileSize={5242880}
                              defaultImages={this.state.defaultImages}
                              inputRef={(iu) => (this.imageUploader = iu)}
                              withPreview
                            />
                          </div>
                          <div className="col-md-12">
                            <p className="helper-text">{t.videourl[lang]}</p>
                            <div className="d-inline float-right">
                              <Switch
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                className="switch-create-card"
                                offColor="#212123"
                                onColor="#00adb4"
                                onChange={this.handleCardToggleFields.bind(
                                  this,
                                  "card_video"
                                )}
                                checked={this.state.checked.card_video}
                              />
                            </div>
                            <div className="position-relative form-group">
                              <input
                                onKeyPress={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                onChange={this.onChange}
                                value={this.state.card_video}
                                name="card_video"
                                id="card_video"
                                type="text"
                                className="form-control"
                              />
                              <label
                                forHtml="card_video"
                                className={
                                  this.state.card_video !== "" ? "show" : ""
                                }
                              >
                                {t.VideoLink[lang]}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-12 text-center">
                            <button
                              class="mb-2 mr-2 btn-hover-shine btn btn-wide btn-light"
                              type="button"
                              onClick={() =>
                                this.move("#tab-eg15-4", "#card-content-btn")
                              }
                            >
                              {t.Previous[lang]}
                            </button>
                            <button
                              class="mb-2 mr-2 btn-hover-shine btn btn-wide btn-dark"
                              type="button"
                              onClick={this.editCard}
                            >
                              {t.Update[lang]}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = () => {
  return {
    getConfigurations: (token) => settingactionCreater.getConfigurations(token),
    getCard: (token, id) => actionCreater.getCard(token, id),
    slugs: (token) => actionCreater.getSlugs(token),
    editCard: (token, id, params) => actionCreater.editCard(token, id, params),
  };
};

export default connect(null, mapDispatchToProps)(EditCard);
