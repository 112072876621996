import React from "react";
import { Link } from "react-router-dom";

const Footer  = props => { 
return <div className="app-wrapper-footer">
<div className="app-footer">
    <div className="app-footer__inner">
        <div className="app-footer-left">
            <ul className="nav">
                <li className="nav-item">
                    
                </li>
                <li className="nav-item">
                    
                </li>
            </ul>
        </div>
        <div className="app-footer-right">
            <ul className="nav">
                <li className="nav-item">
                    
                </li>
                <li className="nav-item">
                    <a href="https://easy-card.co/" className="nav-link" target="_blank">
                        © {props.copyright} 2020 By Easy Card  |  {props.allright}.
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
</div>   ;

}
export default Footer;
