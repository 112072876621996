let countries = [
    {en:"Austria",il:"אוֹסְטְרֵיָה"},
    {en:"Belgium",il:"בלגיה"},
    {en:"Bulgaria",il:"בולגריה"},
    {en:"Croatia",il:"קרואטיה"},
    {en:"Cyprus",il:"קַפרִיסִין"},
    {en:"Czech Republic",il:"הרפובליקה הצ'כית"},
    {en:"Denmark",il:"דנמרק"},
    {en:"Estonia",il:"אסטוניה"},
    {en:"Finland",il:"פינלנד"},
    {en:"France (inc. French Territories)",il:"צרפת (כולל השטחים הצרפתיים)"},
    {en:"Germany",il:"גֶרמָנִיָה"},
    {en:"Gibraltar",il:"גיברלטר"},
    {en:"Greece",il:"יָוָן"},
    {en:"Guadeloupe",il:"גוואדלופ"},
    // {en:"Guiana",il:"גויאנה"},
    {en:"Hungary",il:"הונגריה"},
    {en:"Iceland",il:"אִיסלַנד"},
    {en:"Ireland",il:"אירלנד"},
    {en:"Italy (inc.Vatican City)",il:"איטליה (כולל עיריית הוותיקן)"},
    {en:"Latvia",il:"לטביה"},
    {en:"Liechtenstein",il:"ליכטנשטיין"},
    {en:"Lithuania",il:"ליטא"},
    {en:"Luxembourg",il:"לוקסמבורג"},
    {en:"Malta",il:"מלטה"},
    {en:"Monaco",il:"מונקו"},
    {en:"Netherlands",il:"הולנד"},
    {en:"Norway",il:"נורווגיה"},
    {en:"Poland",il:"פּוֹלִין"},
    {en:"Portugal (inc. Madeira)",il:"פורטוגל (כולל מדיירה)"},
    {en:"Romania",il:"רומניה"},
    {en:"Slovakia",il:"סלובקיה"},
    {en:"Slovenia",il:"סלובניה"},
    {en:"Spain (inc. Balearic Islands, Canary Islands)",il:"ספרד (כולל האיים הבלאריים, האיים הקנריים)"},
    {en:"Sweden",il:"שבדיה"},
    // {en:"Reunion (French Territories)",il:"ראוניון (השטחים הצרפתיים)"},
    // {en:"Martinique",il:"מרטיניק"},
    // {en:"Mayotte",il:"מיוט"},
    // {en:"Saint Barthelemy (St Barts)",il:"סנט ברתלמי (סנט בארטס)"},
    {en:"United Kingdom",il:"בריטניה"},
    {en:"Faroe Islands",il:"איי פרו"},
    // {en:"San Marino",il:"סן מרינו"},
    {en:"Switzerland",il:"שוויץ"},
    {en:"Turkey",il:"טורקיה"},
    {en:"Afghanistan",il:"אפגניסטן"},
    {en:"Albania",il:"אלבניה"},
    {en:"Andorra",il:"אנדורה"},
    // {en:"Anguilla",il:"אנגווילה"},
    // {en:"Antigua and Barbuda",il:"אנטיגואה וברבודה"},
    {en:"Argentina",il:"ארגנטינה"},
    {en:"Armenia",il:"אַרְמֶנִיָה"},
    {en:"Aruba",il:"ארובה"},
    {en:"Australia",il:"אוֹסטְרַלִיָה"},
    {en:"Bahrain",il:"בחריין"},
    {en:"Bangladesh",il:"בנגלדש"},
    {en:"Barbados",il:"ברבדוס"},
    {en:"Bermuda",il:"ברמודה"},
    // {en:"Bonaire",il:"בונייר"},
    {en:"Bosnia and Herzegovina",il:"בוסניה והרצגובינה"},
    {en:"Brazil",il:"בְּרָזִיל"},
    {en:"British Virgin Islands",il:"איי בתולה בריטיים"},
    {en:"Cambodia",il:"קמבודיה"},
    {en:"Canada",il:"קנדה"},
    {en:"Cayman Islands",il:"איי קיימן"},
    {en:"Chile",il:"צ'ילה"},
    {en:"China",il:"חרסינה"},
    {en:"Colombia",il:"קולומביה"},
    {en:"Costa Rica",il:"קוסטה ריקה"},
    // {en:"Curacao",il:"קוראסאו"},
    {en:"Democratic Republic of Congo",il:"הרפובליקה הדמוקרטית של קונגו"},
    {en:"Dominica",il:"דומיניקה"},
    {en:"Dominican Republic",il:"הרפובליקה הדומיניקנית"},
    {en:"Ecuador",il:"אקוודור"},
    {en:"Egypt",il:"מִצְרַיִם"},
    {en:"El Salvador",il:"אל סלבדור"},
    {en:"Fiji",il:"פיג'י"},
    {en:"Georgia",il:"ג'ורג'יה"},
    {en:"Ghana",il:"גאנה"},
    {en:"Grenada",il:"גרנדה"},
    {en:"Guam",il:"גואם"},
    {en:"Guatemala",il:"גואטמלה"},
    {en:"Guyana",il:"גיאנה"},
    {en:"Haiti",il:"האיטי"},
    {en:"Honduras",il:"הונדורס"},
    {en:"Hong Kong",il:"הונג קונג"},
    {en:"India",il:"הוֹדוּ"},
    {en:"Indonesia",il:"אִינדוֹנֵזִיָה"},
    {en:"Israel",il:"ישראל"},
    {en:"Jamaica",il:"ג'מייקה"},
    {en:"Japan",il:"יפן"},
    {en:"Jordan",il:"יַרדֵן"},
    {en:"Kazakhstan",il:"קזחסטן"},
    {en:"Kenya",il:"קניה"},
    {en:"Kuwait",il:"כווית"},
    {en:"Laos",il:"לאוס"},
    {en:"Lesotho",il:"לסוטו"},
    {en:"Macau",il:"מקאו"},
    {en:"Macedonia",il:"מוּקדוֹן"},
    {en:"Malaysia",il:"מלזיה"},
    {en:"Mexico",il:"מקסיקו"},
    {en:"Moldova",il:"מולדובה"},
    {en:"Mongolia",il:"מונגוליה"},
    {en:"Montenegro",il:"מונטנגרו"},
    // {en:"Montserrat",il:"מונטסראט"},
    {en:"Morocco",il:"מָרוֹקוֹ"},
    {en:"Mozambique",il:"מוזמביק"},
    {en:"Myanmar (Burma)",il:"מיאנמר (בורמה)"},
    {en:"New Zealand",il:"ניו זילנד"},
    {en:"Nicaragua",il:"ניקרגואה"},
    {en:"Oman",il:"עומאן"},
    {en:"Pakistan",il:"פקיסטן"},
    // {en:"Palestine",il:"פלשתינה"},
    {en:"Panama",il:"פנמה"},
    {en:"Papua New Guinea",il:"פפואה גינאה החדשה"},
    {en:"Paraguay",il:"פרגוואי"},
    {en:"Peru",il:"פרו"},
    {en:"Philippines",il:"הפיליפינים"},
    {en:"Puerto Rico",il:"פוארטו ריקו"},
    {en:"Qatar",il:"קטאר"},
    {en:"Russia",il:"רוּסִיָה"},
    // {en:"Saba / Sint Eustatius (Netherland Antilles)",il:"סבא / סינט אוסטאטיוס (האנטילים ההולנדיים)"},
    {en:"Saudi Arabia",il:"ערב הסעודית"},
    {en:"Serbia (Yugoslavia)",il:"סרביה (יוגוסלביה)"},
    {en:"Singapore",il:"סינגפור"},
    // {en:"Sint Maarten",il:"סנט מארטן"},
    {en:"South Africa",il:"דרום אפריקה"},
    {en:"South Korea",il:"דרום קוריאה"},
    {en:"South Sudan",il:"דרום סודן"},
    {en:"Sri Lanka",il:"סרי לנקה"},
    // {en:"St Lucia",il:"סנט לוסיה"},
    // {en:"St Vincent",il:"סנט וינסנט"},
    // {en:"St.Kitts and Nevis",il:"סנט קיטס ונוויס"},
    // {en:"Suriname",il:"סורינאם"},
    {en:"Swaziland",il:"סווזילנד"},
    {en:"Taiwan",il:"טייוואן"},
    {en:"Tanzania",il:"טנזניה"},
    {en:"Thailand",il:"תאילנד"},
    {en:"Tonga",il:"טונגה"},
    // {en:"Trinidad and Tobago",il:"טרינידד וטובגו"},
    // {en:"Turks and Caicos Islands",il:"איי טורקס וקאיקוס"},
    {en:"Ukraine",il:"אוקראינה"},
    {en:"United Arab Emirates",il:"איחוד האמירויות הערביות"},
    {en:"Uruguay",il:"אורוגוואי"},
    // {en:"US Virgin Islands",il:'איי הבתולה של ארה"ב'},
    {en:"USA",il:'ארה"ב'},
    {en:"Uzbekistan",il:"אוזבקיסטן"},
    // {en:"Vanuatu",il:"ונואטו"},
    {en:"Vietnam",il:"וייטנאם"},
    {en:"Western Samoa",il:"מערב סמואה"},
]

export default countries