import React, { Component } from "react";
// import EditCard from "./EditCard";
// import DeleteModal from "../Common/Modals/DeleteModal";
import { connect } from "react-redux";
import * as qs from "query-string";
import { Col, Input, InputGroup, InputGroupAddon, Button } from "reactstrap";
import SimplePagination from "../Common/SimplePagination";
import * as actions from "../../Store/Actions/type";
import * as actionCreater from "../../Store/Actions/CardActions";
import { getSearchUrlFromState } from "../../util/functions";
import T from "../../Helpers/Translations";
import { Link, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import APIModel from "../../Models/APIModel";
import axios from "axios";
import Cookie from "cookie-universal";
import $ from "jquery";
const cookies = Cookie();

class Cards extends Component {
  state = {
    ...this.props.user,
    card_title: "",
    updated_at: "",
    theme_id: "",
    status: "",
    user_id: "",
    user_name: "",
    host: APIModel.HOST,
    page: 0,
    totalPages: 0,
    process: true,
    modal_show: false,
    currentBalance: "",
    userActiveSubscription: {},
  };

  get = (search) => {
    this.setState({
      process: true,
    });
    let { get, dispatch, user, errorHandler } = this.props;

    get(user.auth.access_token, search)
      .then((res) => {
        this.setState({
          page: res.data.meta.current_page,
          totalPages: res.data.meta.last_page,
        });

        dispatch({
          type: actions.GET_CARDS,
          payload: res.data.data,
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          process: false,
        });
      });
  };

  changeStatus = (id, sta, v) => {
    let easycardUser = localStorage.getItem("easycardUser");
    let userProfile = JSON.parse(easycardUser);
    let currentBalance = userProfile["amount"];

    if (
      currentBalance === null ||
      currentBalance === "" ||
      currentBalance === 0 ||
      currentBalance <= 0 ||
      currentBalance === undefined
    ) {
      localStorage.removeItem("card_activate");
      localStorage.setItem("card_activate", id);
      this.props.history.push("/subcriptions");
    }

    let { user, changeStatus, dispatch, alertify, errorHandler } = this.props;

    sta = sta == 1 ? 0 : 1;
    changeStatus(user.auth.access_token, id, sta)
      .then((res) => {
        dispatch({
          type: actions.CHANGE_STATUS,
          payload: id,
        });
        this.get();
        // alertify.success(res.data.message);
      })
      .catch(errorHandler);
  };

  published = (id, sta, v) => {
    let { user, changeStatus, dispatch, alertify, errorHandler } = this.props;
    let easycardUser = localStorage.getItem("easycardUser");
    let userProfile = JSON.parse(easycardUser);
    let access_token = user.auth.access_token;
    sta = sta == 1 ? 0 : 1;
    return axios
      .post(this.state.host + "card/publish/" + id + "/" + sta, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => {
        alertify.success(response.data.message);
        this.props.history.push("/");
      })
      .catch(errorHandler);
  };

  unpublished = (id, sta, v) => {
    let { user, changeStatus, dispatch, alertify, errorHandler } = this.props;
    let easycardUser = localStorage.getItem("easycardUser");
    let userProfile = JSON.parse(easycardUser);
    let user_id = userProfile["id"];
    let access_token = user.auth.access_token;
    sta = sta == 1 ? 0 : 1;
    let params = { unpublished: "unpublished" };

    return axios
      .post(this.state.host + "card/status/" + id + "/" + sta, params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => {
        alertify.success(response.data.message);
        this.props.history.push("/");
      })
      .catch(errorHandler);

    // let { user, changeStatus, dispatch, alertify, errorHandler } = this.props;
    // sta = (sta == 1) ? 0 : 1;
    // changeStatus(user.auth.access_token, id, sta)
    //   .then(res => {
    //     dispatch({
    //       type: actions.CHANGE_STATUS,
    //       payload: id
    //     });
    //     this.get();
    //     alertify.success(res.data.message);
    //   })
    //   .catch(errorHandler);
  };

  componentDidMount() {
    let search = this.props.location.search;
    const params = qs.parse(search);
    for (let key in params) {
      this.setState({
        [key]: params[key],
      });
    }
    this.get(search);
    this.getUserActiveSubscription();

    let state = this.props.location.state;
    if (state != undefined && state.modal) {
      this.setState({
        modal_show: true,
      });
      console.log(state);
    }
  }

  getUserActiveSubscription = () => {
    let { user, changeStatus, dispatch, alertify, errorHandler } = this.props;

    let easycardUser = localStorage.getItem("easycardUser");
    let userProfile = JSON.parse(easycardUser);
    let user_id = userProfile["id"];
    let access_token = user.auth.access_token;

    return axios
      .get(this.state.host + "admin/subscription/" + user_id, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => {
        this.setState({ userActiveSubscription: response.data.data });
      })
      .catch(this.globalErrorHandler)
      .finally();
  };

  next = () => {
    let next = this.state.page + 1;
    if (next <= this.state.totalPages) {
      let search = getSearchUrlFromState(this.state);
      this.get(search + "page=" + next);
    }
  };

  previous = () => {
    let previous = this.state.page - 1;
    if (previous > 0) {
      let search = getSearchUrlFromState(this.state);
      this.get(search + "page=" + previous);
    }
  };

  renderBody = () => {
    const { cards } = this.props;
    console.clear();
    console.log(cards.length);
    return cards.map((m) => {
      let a_card_link = {
        pathname: "/edit-card/" + m.id,
        query: m.id,
      };
      let lang = $("html").attr("lang").toUpperCase();
      let t = T[0];

      return (
        <tr key={m.id}>
          <td className="text-center text-muted">#{m.id}</td>
          <td>
            <div className="widget-content p-0">
              <div className="widget-content-wrapper">
                <div className="widget-content-left flex2">
                  {/* <div className="widget-heading">
																			{
																				m.user_name
																			}
																		</div> */}
                  <div className="widget-heading">{m.card_title}</div>
                </div>
              </div>
            </div>
          </td>
          <td className="text-center">
            {APIModel.PORTAL + "/"}
            {m.slug}
          </td>
          <td className="text-center">
            <button type="button" className="toggle-sw-p">
              <div className={"badge badge-success"}>
                {m.status === "1" ? t.aActive[lang] : t.aInActive[lang]}
              </div>
            </button>
          </td>
          <td className="text-center">
            {(m.status === "1" && m.publish_status === null) ||
            m.publish_status == "1" ? (
              <button
                type="button"
                className="toggle-sw"
                onClick={this.unpublished.bind(this, m.id, m.status)}
              >
                <div
                  className={"badge badge-info"}
                  data-tip={"Click to unpublish card"}
                >
                  {t.apublished[lang]}
                </div>
              </button>
            ) : (
              <button
                type="button"
                className="toggle-sw"
                onClick={this.published.bind(this, m.id, m.status)}
              >
                <div
                  className={"badge badge-info"}
                  data-tip={"Click to publish card"}
                >
                  {t.aunpublished[lang]}
                </div>
              </button>
            )}
          </td>
          <td className="text-center">
            <div className={"badge badge-primary"}>
              {m.sub == "" ? t.anosubscription[lang] : ""}

              {m.sub == "monthly" ? t.monthly[lang] : ""}

              {m.sub == "yearly" ? t.annually[lang] : ""}
            </div>
          </td>

          <td className="text-center">
            <Link
              to={"/edit-card/" + m.id}
              className="btn btn-danger btn-sm mr-2"
            >
              {t.aEdit[lang]}
            </Link>
            {m.status == "1" && m.publish_status == "1" ? (
              <a
                target="_blank"
                href={APIModel.PORTAL_URL + m.slug}
                className="btn btn-primary btn-sm card-detail-btn"
              >
                {t.View[lang]}
              </a>
            ) : (
              // <a
              //   target="_blank"
              //   href={"http://localhost:3001/#/" + m.slug}
              //   className="btn btn-primary btn-sm card-detail-btn"
              // >
              //   View
              // </a>
              <a
                target="_blank"
                href={
                  APIModel.PORTAL_URL + "card/" + m.user_id + "/view/" + m.slug
                }
                className="btn btn-primary btn-sm card-detail-btn"
              >
                {t.View[lang]}
              </a>
              // <a
              //   target="_blank"
              //   href={
              //     "http://localhost:3001/#/card/" +
              //     m.user_id +
              //     "/view/" +
              //     m.slug
              //   }
              //   className="btn btn-primary btn-sm card-detail-btn"
              // >
              //   View
              // </a>
            )}
            <button
              type="button"
              className="toggle-sw"
              onClick={this.changeStatus.bind(this, m.id, m.status)}
            >
              <div
                className={m.status === "0" ? "badge badge-success" : ""}
                data-tip={"Click to Activate"}
              >
                {m.status === "0" ? t.aactivate[lang] : ""}
              </div>
              <ReactTooltip />
            </button>
          </td>
        </tr>
      );
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  filter = () => {
    let search = getSearchUrlFromState(this.state);
    this.get(search);
  };

  deleteCard(id) {
    let { user, deleteCard, dispatch, alertify, errorHandler } = this.props;

    deleteCard(user.auth.access_token, id)
      .then((res) => {
        dispatch({
          type: actions.DELETE_CARD,
          payload: id,
        });
        alertify.success(res.data.message);
      })
      .catch(errorHandler);
  }

  render() {
    const { cards } = this.props;
    let lang = $("html").attr("lang").toUpperCase();
    let t = T[0];
    if (cards.length != 0) {
      let cc = this.state.process ? "hide-it" : "main-card mb-3 card";
      return (
        <>
          {this.state.modal_show == true && (
            <>
              <div
                style={{
                  backgroundColor: "rgb(0 0 0 / 55%)",
                  position: "absolute",
                  zIndex: 999,
                  top: -400,
                  left: 0,
                  right: 0,
                  bottom: -55,
                }}
              ></div>
              {/*  */}
              {/* <div style={{ backgroundColor: "rgb(0 0 0 / 55%)", position: 'absolute', zIndex: 999, top: 0, left: -200, right: -200, bottom: 0 }}></div> */}
              <div
                style={{
                  position: "absolute",
                  zIndex: 1000,
                  borderRadius: 6,
                  padding: 15,
                  top: 175,
                  left: "40%",
                  marginLeft: -150,
                  width: 600,
                  backgroundColor: "white",
                  color: "black",
                  border: "8px solid #91c216",
                }}
              >
                {/* <FaRegWindowClose  style={{ fontSize: '20px', marginLeft: '540px', marginTop: '-8px', verticalAlign: 'top',color: '#aaaaaa'}}/> */}
                <span class="close">
                  {" "}
                  <svg
                    onClick={() => this.setState({ modal_show: false })}
                    className="close"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-x-lg"
                    viewBox="0 0 16 16"
                    style={{
                      verticalAlign: "top",
                      float: "right",
                      color: "#aaaaaa",
                    }}
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                </span>
                {/* <FaRegCheckCircle  style={{ fontSize: '100px', marginLeft: '235px', marginBottom: '7px', verticalAlign: 'middle'}} /> */}
                <img
                  src="https://portal.easy-card.co/assets/images/tickimg.PNG"
                  alt="not found"
                  style={{
                    marginTop: "-4px",
                    marginLeft: "230px",
                    height: "100px",
                  }}
                />
                &nbsp; &nbsp;
                <p>
                  <center>
                    <b>{t.UserModalTitle[lang]}</b>
                  </center>
                </p>{" "}
                &nbsp;
                {lang == "EN" ? <p>{t.UserModalT1[lang]}</p> : " "}
                {lang == "HE" ? (
                  <p style={{ textAlign: "right" }}>{t.UserModalT1[lang]}</p>
                ) : (
                  " "
                )}
                {lang == "AR" ? (
                  <p style={{ textAlign: "right" }}>{t.UserModalT1[lang]}</p>
                ) : (
                  " "
                )}
                &nbsp; &nbsp;
                <p>{t.UserModalT2[lang]}</p>
                {lang == "EN" ? <p>{t.UserModalT2[lang]}</p> : " "}
                {lang == "HE" ? (
                  <p style={{ textAlign: "right" }}>{t.UserModalT2[lang]}</p>
                ) : (
                  " "
                )}
                {lang == "AR" ? (
                  <p style={{ textAlign: "right" }}>{t.UserModalT2[lang]}</p>
                ) : (
                  " "
                )}
                {lang == "EN" ? <p>{t.UserModalT3[lang]}</p> : " "}
                {lang == "HE" ? (
                  <p style={{ textAlign: "right" }}>{t.UserModalT3[lang]}</p>
                ) : (
                  " "
                )}
                {lang == "AR" ? (
                  <p style={{ textAlign: "right" }}>{t.UserModalT3[lang]}</p>
                ) : (
                  " "
                )}
                {lang == "EN" ? (
                  <p>
                    {t.UserModalT4[lang]} <b>WelPromCo!@123Q</b>{" "}
                    {t.UserModalT5[lang]}
                  </p>
                ) : (
                  " "
                )}
                {lang == "HE" ? (
                  <p style={{ textAlign: "right" }}>
                    {t.UserModalT4[lang]} <b>WelPromCo!@123Q</b>{" "}
                    {t.UserModalT5[lang]}
                  </p>
                ) : (
                  " "
                )}
                {lang == "AR" ? (
                  <p style={{ textAlign: "right" }}>
                    {t.UserModalT4[lang]} <b>WelPromCo!@123Q</b>{" "}
                    {t.UserModalT5[lang]}
                  </p>
                ) : (
                  " "
                )}
                <hr />
                <div style={{ width: "100%", textAlign: "center" }}>
                  <p>
                    <a
                      className="btn btn-lg"
                      onClick={() => this.setState({ modal_show: false })}
                      style={{
                        backgroundColor: "#91c216",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      {t.GoIt[lang]}
                    </a>
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    <a
                      style={{
                        backgroundColor: "#d9dadc",
                        color: "black",
                        cursor: "pointer",
                      }}
                      className="btn btn-lg"
                      onClick={() => this.setState({ modal_show: false })}
                      href="https://ctrlreply.com/"
                      // style={{ color: "white", cursor: 'pointer' }}
                    >
                      Download Controlled Reply App
                      {/* {t.GoIt[lang]} */}
                    </a>
                  </p>
                </div>
              </div>
            </>
          )}
          <div>
            <div>
              {this.state.process ? (
                <div className="the-loader">
                  <Loader
                    type="Triangle"
                    color="#00adb4"
                    height={100}
                    width={100}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={cc}>
              <div className="card-header">
                {t.ManageCards[lang]}
                <div className="btn-actions-pane-right">
                  <div role="group" className="btn-group-sm btn-group"></div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>{t.cardname[lang]}</th>
                      <th className="text-center">{t.curl[lang]}</th>
                      <th className="text-center">{t.astate[lang]}</th>
                      <th className="text-center">{t.astatus[lang]}</th>
                      <th className="text-center">{t.asubscription[lang]}</th>
                      <th className="text-center">{t.aActions[lang]}</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderBody()}</tbody>
                </table>
              </div>
              <SimplePagination
                next={this.next}
                previous={this.previous}
                nextl={t.Next[lang]}
                previousl={t.Prev[lang]}
              />
              <div className="d-block text-center card-footer"></div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="card-header">
          {t.YOUSTILL[lang]}{" "}
          <Link
            className="btn btn-primary btn-sm"
            style={{ left: 10 }}
            to={"/create-card"}
          >
            {" "}
            {t.create[lang]}
          </Link>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    cards: state.CardReducer.cards,
    metaData: state.MetaDataReducer,
  };
};

const mapDispatchToProps = () => {
  return {
    get: (token, search) => actionCreater.getCards(token, search),
    deleteCard: (token, id) => actionCreater.deleteCard(token, id),
    changeStatus: (token, id, sta) =>
      actionCreater.changeStatus(token, id, sta),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cards);
