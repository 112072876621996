import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../Store/Actions/type";
import * as actionsCreators from "../../Store/Actions/AuthActions";
import alertify from "alertifyjs";
import { errorHandler } from "../../util/functions";
import $ from "jquery";

class LoginArabic extends Component {
	state = {
		email: "",
		password: "",
		error: "",
		inProgress: false
	};

	componentWillMount() {
		$("html").attr("dir", "rtl");
        $("html").attr("lang", "Ar");
		if (this.props.user) {
			this.props.history.push("/");
		}
	}

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	_handleKeyDown = (e) => {
		if (e.key === "Enter") {
			this.login();
		}
	};

	globalErrorHandler = (error) => {
		const { response } = error;
		let message;
		if (response) {
			message = response.data.message;
		} else {
			message = error.toString();
		}
		alertify.error(message);
	};

	login = () => {
		let { login, dispatch, history } = this.props;

		if (this.state) {
			if (this.state.email === undefined || this.state.email == "") {
				alertify.error("البريد الإلكتروني مطلوب");
				$("input#email").focus();
				return;
			} else if (this.state.email.length > 0) {
				let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (re.test(this.state.email)) {
				} else {
					alertify.error("Must be a valid Email Address");
					$("input#email").focus();
					return;
				}
			}

			if (
				this.state.password === undefined ||
				this.state.password == ""
			) {
				alertify.error("كلمة المرور مطلوبة");
				$("input#password").focus();
				return;
			} else if (this.state.password.length > 0) {
				if (this.state.password.length < 6) {
					alertify.error(
						"يجب أن تحتوي كلمة المرور على 6 حروف على الأقل"
					);
					$("input#password").focus();
					return;
				}
			}
		}

		this.setState({
			inProgress: true
		});

		const { email, password } = this.state;

		const params = { email, password };

		login(params)
			.then((res) => {
				dispatch({
					type: actions.LOGIN,
					payload: res.data
				});

				history.push("/");
				window.location.reload();
			})
			.catch(this.globalErrorHandler)
			.finally(() => {
				this.setState({
					inProgress: false
				});
			});
	};

	componentDidMount() {
		if (localStorage.getItem("easycardUser") === null) {
			this.props.history.push("/login/AB");
			alertify.set("notifier", "position", "top-right");
			return;
		  }
		if(localStorage.easycardUser != 'null')
		{
			this.props.history.push("/dashboard");
			alertify.set("notifier", "position", "top-right");
			return;
		}
	}

	render() {
		const { email, inProgress, password } = this.state;
		return (
			<div className="app-container">
				<div className="h-100 bg-premium-dark bg-animation">
					<div className="d-flex h-100 justify-content-center align-items-center">
						<div className="mx-auto app-login-box col-md-8">
							<div className="app-logo-inverse mx-auto mb-3"></div>
							<div className="modal-dialog w-100 mx-auto">
								<div className="modal-content">
									<div className="text-center bg-white pt-3">
										<img
											src="assets/images/easy-card.png"
											alt=""
										/>
									</div>
									<div className="modal-body">
										<div className="h5 modal-title text-center">
											<h4 className="mt-2">
												<div>مرحبا بعودتك,</div>
												<span>
                                                الرجاء تسجيل الدخول في حسابك أدناه
												</span>
											</h4>
										</div>
										<form className="">
											<div className="form-row">
												<div className="col-md-12">
													<div className="position-relative form-group">
														<input
															type="email"
															className="form-control"
															name="email"
															id="inputEmail1"
															onChange={
																this.onChange
															}
															value={email}
															aria-describedby="emailHelp"
															placeholder="كتب البريد الإلكتروني"
															required
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="position-relative form-group">
														<input
															id="inputPassword"
															name="password"
															onChange={
																this.onChange
															}
															onKeyDown={
																this
																	._handleKeyDown
															}
															value={password}
															placeholder="اكتب كلمة المرور"
															required
															minLength="6"
															type="password"
															className="form-control"
														/>
													</div>
												</div>
											</div>
										</form>
										<div className="divider"></div>
										<h6 className="mb-0">
                                        لا يوجد حساب{" "}
											<Link
												to={"/register/Ar"}
												className="text-primary"
											>
												تسجل الآن
											</Link>
										</h6>
									</div>
									<div className="modal-footer clearfix">
										<div className="float-left">
											<Link
												to={"/ForgotPassword/Ar"}
												className="btn-lg btn btn-link"
											>
												استعادة كلمة المرور
											</Link>
										</div>
										<div className="float-right">
											<button
												className="btn btn-primary btn-lg"
												value={
													inProgress
														? "Please wait..."
														: "Login"
												}
												disabled={inProgress}
												onClick={this.login}
											>
												تسجيل الدخول إلى لائحة التحكم
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="text-center text-white opacity-8 mt-3">
                            حقوق التأليف والنشر © Easy Card 2020
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.AuthReducer.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch: dispatch,
		login: (data) => actionsCreators.login(data)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginArabic);
