import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../Store/Actions/type";
import FileBase64 from "react-file-base64";
import * as actionCreater from "../../Store/Actions/UserActions";
import Languages from "../../Helpers/Languages";
import T from "../../Helpers/Translations";
import SelectSearch from "react-select-search";
import $ from "jquery";
import Loader from "react-loader-spinner";
import alertify from "alertifyjs";

class Profile extends Component {
  state = {
    language: "",
    language_val: "",
    match: 0,
    password: "",
    password_again: "",
    files: [],
    avatar: null,
    about: "",
    process: true,
    redirect: false,
  };

  getFiles(files) {
    this.setState({ file_1: files });
  }

  get = () => {
    this.setState({
      process: true,
    });
    let { get, dispatch, user, errorHandler } = this.props;

    get(user.auth.access_token, user.id)
      .then((res) => {
        const userData = res.data.data;

        dispatch({
          type: actions.GET_PROFILE,
          payload: userData,
        });

        let profile = res.data[0];

        let a_phone = JSON.parse(profile.alt_phone);
        if (a_phone == null) {
          a_phone = { alt_mobile: "" };
        }

        let social = JSON.parse(profile._socials);
        if (social == null) {
          social = { website: "" };
        }

        this.setState({
          profile: res.data[0],
          id: profile.id,
          name: profile.name,
          position: profile.position,
          specialization: profile.specialization,
          language: profile.language,
          about: profile.about ? profile.about : "",
          email: profile.email,
          phone: profile.phone,
          alt_mobile: a_phone.alt_mobile ? a_phone.alt_mobile : "",
          office_number: a_phone.office_number ? a_phone.office_number : "",
          fax: a_phone.fax ? a_phone.fax : "",
          whatsapp: a_phone.whatsapp ? a_phone.whatsapp : "",
          website: social.website ? social.website : "",
          waze: social.waze ? social.waze : "",
          facebook: social.facebook ? social.facebook : "",
          instagram: social.instagram ? social.instagram : "",
          twitter: social.twitter ? social.twitter : "",
          linkdin: social.linkdin ? social.linkdin : "",
          google_plus: social.google_plus ? social.google_plus : "",
          youtube: social.youtube ? social.youtube : "",
          address: profile.address ? profile.address : "",
          total_active_cards: profile.total_active_cards,
          total_cards: profile.total_cards,
          avatar: profile.avatar,
        });

        let s = this;
        Languages.filter(function (item) {
          if (item.value === s.state.language) {
            s.setState({
              language_val: item.name,
            });
          }
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          process: false,
        });
      });
  };

  renderRedirect = () => {
    return <Redirect to="/logout" />;
  };

  editUser = () => {
    //debugger;
    let { editUser, dispatch, alertify, user, errorHandler } = this.props;
    if (this.state) {
      if (
        this.state.language_val == "Choose Your Language" ||
        this.state.language_val == undefined
      ) {
        alertify.error("Please select Language");
        $("input#name").focus();
        return;
      }

      if (this.state.password === undefined || this.state.password == "") {
        // alertify.error("Password is Required");
        // $("input#password").focus();
        // return;
      } else if (this.state.password.length > 0) {
        if (this.state.password.length < 6) {
          alertify.error("Password must be atleast 6 characters long.");
          $("input#password").focus();
          return;
        }
      }

      if (this.state.password === undefined || this.state.password == "") {
        // alertify.error("Password is Required");
        // $("input#password").focus();
        // return;
      } else if (this.state.password.length > 0) {
        if (this.state.match === 0) {
          alertify.error("Your Password does not match");
          $("input#passwordAgain").focus();
          return;
        }
      }
    }

    let formVal = $("#profile-form").serializeArray();
    let valid = 0;
    let only_letters = /^[A-Za-z\s]+$/;
    let only_alpha_numerics = /^[a-zA-Z]+[a-zA-Z0-9\s]+[a-zA-Z]\n$/;
    let only_numbers = /^[0-9]+$/;
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let urlformat =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    let minLength = 6;
    let maxLength = 15;
    let message = "Form is invalid";

    formVal.forEach(function (item) {
      $("#" + item.name)
        .next()
        .hide();

      if (item.name === "name" && item.value.length === 0) {
        $("#" + item.name)
          .next()
          .show();
        $("html, body").animate(
          { scrollTop: $(".app-page-title").offset().top },
          500,
          function () {
            $("#" + item.name).focus();
            valid++;
          }
        );
      }

      //alphabets

      // if(item.name === 'position' || item.name === 'specialization'){
      //   if(item.value.length > 0){
      //     if(!item.value.match(only_letters)){
      //       $('#'+item.name).next().show();
      //       valid++;
      //       $('html, body').animate({ scrollTop: $('.app-page-title').offset().top }, 500, function(){
      //         $('#'+item.name).focus();
      //       });
      //     };
      //   }
      // }

      // Length
      if (item.name === "password" && item.value.length > 0) {
        if (item.value.length >= minLength && item.value.length <= maxLength) {
          // do nothing
        } else {
          $("#password").next().show();
          valid++;
          // $("html, body").animate(
          // 	{ scrollTop: $(".app-page-title").offset().top },
          // 	500,
          // 	function () {
          // 		$("#" + item.name).focus();
          // 	}
          // );
        }
      }

      // Phone
      // if(item.name === 'phone' || item.name === 'alt_mobile' || item.name === 'office_number' || item.name === 'fax' || item.name === 'whatsapp'){
      //   if(item.value.length > 0){
      //     if(!item.value.match(only_numbers)){
      //       valid++;
      //       $('#'+item.name).next().show();
      //       $('html, body').animate({ scrollTop: $('.app-page-title').offset().top }, 500, function(){
      //         $('#'+item.name).focus();
      //       });
      //     };
      //   }
      // }

      // Urls
      // if(item.name === 'website' || item.name === 'waze' || item.name === 'facebook' || item.name === 'linkdin' || item.name === 'google_plus' || item.name === 'twitter' || item.name === 'instagram' || item.name === 'youtube' ){
      //   if(item.value.length > 0){
      //     if(!item.value.match(urlformat)){
      //       valid++;
      //       $('#'+item.name).next().show();
      //       $('html, body').animate({ scrollTop: $('.app-page-title').offset().top }, 500, function(){
      //         $('#'+item.name).focus();
      //       });
      //     };
      //   }
      // }
    });

    if (valid > 0) {
      this.props.alertify.error(message);
      return;
    }

    this.setState({
      process: true,
    });

    let {
      id,
      name,
      language,
      password,
      email,
      phone,
      // file_1,
      //  address,
      // website, waze, facebook, linkdin, twitter, google_plus, instagram, youtube,
      // position, specialization, about,
      // alt_mobile, office_number, fax, whatsapp,
    } = { ...this.state };

    // let avatar = (file_1) ? file_1.base64 : "";

    const params = {
      name,
      password,
      language,
      email,
      phone,
      // avatar,
      // address,
      // alt_mobile, office_number, fax, whatsapp,
      // website, waze, facebook, linkdin, twitter, google_plus, instagram, youtube,
      // position, specialization, about
    };

    editUser(user.auth.access_token, id, params)
      .then((res) => {
        const userData = res.data.data;

        dispatch({
          type: actions.EDIT_USER,
          payload: userData,
        });

        dispatch({
          type: actions.EDIT_USER_PROFILE,
          payload: userData,
        });

        this.setState({
          avatar: userData.avatar,
        });

        if (params.password !== "") {
          this.props.history.push("/logout");
        }

        alertify.success(res.data.message);

        if (this.props.user.lanaguage !== userData.language) {
          window.location.reload(false);
        }
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          process: false,
        });
      });
  };

  globalErrorHandler = (error) => {
    const { response } = error;
    let message;
    if (response) {
      message = response.data.message;
    } else {
      message = error.toString();
    }
    alertify.error(message);
  };

  componentDidMount() {
    this.get();
    alertify.set("notifier", "position", "top-right");
  }

  onChange = (e) => {
    if (e.target.name !== "email") {
      this.setState({
        [e.target.name]: e.target.avatar ? e.target.avatar[0] : e.target.value,
      });
      if (
        (e.target.name === "password_again" || e.target.name === "password") &&
        e.target.value !== ""
      ) {
        if (
          e.target.value === this.state.password ||
          this.state.password_again === e.target.value
        ) {
          this.setState({ match: 1 });
        } else {
          this.setState({ match: 0 });
        }
      }
    }
  };

  changeLang = (e) => {
    this.setState({ language: e.value, language_val: e.name });
  };

  render() {
    let lang = $("html").attr("lang").toUpperCase();
    let t = T[0];
    let cc = "";
    let ff = this.state.process ? "hide-it" : "";
    return (
      <div>
        <div>
          {this.state.process ? (
            <div class="the-loader">
              <Loader
                type="Triangle"
                color="#00adb4"
                height={100}
                width={100}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={ff}>
          {/* <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading" >
                  <div className="page-title-icon" style={{'width':'70px','height':'65px','padding':'0px'}}>
                    <img src={this.state.avatar} alt="" style={{'width':'70px','height':'65px'}} />
                  </div>
                  <div className="text-left">{this.state.name}</div>
                </div>
              </div>
            </div> */}
          <div className="row  px-4">
            <div className="col-xl-12 col-md-12 col-sm-10 mx-auto">
              <div className="bg-white shadow rounded overflow-hidden">
                <div className="bg-light p-4 d-flex justify-content-end text-center"></div>
                <div className="">
                  <div className="main-card pb-3 card">
                    <div className="card-body">
                      <form onSubmit={this.editUser} id="profile-form">
                        <div className="position-relative row form-group">
                          <label
                            htmlFor="name"
                            className="col-sm-2 col-form-label"
                          >
                            {t.Name[lang]}
                          </label>
                          <div className="col-sm-10">
                            <input
                              name="name"
                              id="name"
                              onChange={this.onChange}
                              value={this.state.name}
                              placeholder={t.Name_p[lang]}
                              type="text"
                              className="form-control"
                              required
                            />
                            <small className="form-error-mode">
                              {t.Name_v[lang]}
                            </small>
                          </div>
                        </div>
                        {/* <div className="position-relative row form-group">
                                <label htmlFor="position" className="col-sm-2 col-form-label">{t.Position[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="position" id="position" onChange={this.onChange} value={this.state.position ? this.state.position : ''} placeholder={t.Position_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.NANA[lang]}</small>
                                </div>
                              </div> */}
                        {/* <div className="position-relative row form-group">
                                <label htmlFor="specialization" className="col-sm-2 col-form-label">{t.Specialization[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="specialization" id="specialization" onChange={this.onChange} value={this.state.specialization ? this.state.specialization : ''} placeholder={t.Specialization_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.NANA[lang]}</small>  
                                </div>
                              </div> */}
                        <div className="position-relative row form-group">
                          <label
                            htmlFor="language"
                            className="col-sm-2 col-form-label"
                          >
                            {t.SelectLanguage[lang]}
                          </label>
                          <div className="col-sm-10">
                            <SelectSearch
                              onChange={(e) => this.changeLang(e)}
                              className="select-search-box"
                              options={Languages}
                              value={this.state.language_val}
                              name="language"
                              placeholder={
                                this.state.language_val
                                  ? this.state.language_val
                                  : t.ChooseYourLanguage[lang]
                              }
                            />
                            <small className="form-error-mode">
                              {t.PleaseSelectaLanguage[lang]}
                            </small>
                            <small> {t.profiletext[lang]} </small>
                          </div>
                        </div>
                        {/* <div className="position-relative row form-group">
                                  <label htmlFor="about" className="col-sm-2 col-form-label">{t.About[lang]}</label>
                                  <div className="col-sm-10">
                                    <textarea name="about" id="about" className="form-control" onChange={this.onChange} value={this.state.about ? this.state.about : ''} rows="6"></textarea>
                                  </div>
                              </div> */}
                        <hr />

                        <div className="position-relative row form-group">
                          <label
                            htmlFor="email"
                            className="col-sm-2 col-form-label"
                          >
                            {t.Email[lang]}
                          </label>
                          <div className="col-sm-10">
                            <input
                              disabled
                              name="email"
                              id="email"
                              value={this.state.email}
                              placeholder={t.Email_p[lang]}
                              type="email"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="position-relative row form-group">
                          <label
                            htmlFor="password"
                            className="col-sm-2 col-form-label"
                          >
                            {t.Password[lang]}
                          </label>

                          <div className="col-sm-10">
                            <p className="warning-label-password-change">
                              {t.Password_t1[lang]} <br></br>
                            </p>
                            <input
                              name="password"
                              id="password"
                              onChange={this.onChange}
                              placeholder={t.Password_p[lang]}
                              type="password"
                              className="form-control"
                            />
                            <small className="form-error-mode">
                              {t.Password_v[lang]}
                            </small>
                          </div>
                        </div>
                        <div className="position-relative row form-group">
                          <label
                            htmlFor="re-password"
                            className="col-sm-2 col-form-label"
                          >
                            {t.RenterPassword[lang]}
                            <i className={cc}></i>
                          </label>
                          <div className="col-sm-10">
                            <input
                              name="password_again"
                              id="password_again"
                              onChange={this.onChange}
                              value={this.state.password_again}
                              placeholder={t.Password_p2[lang]}
                              type="password"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="position-relative row form-group">
                          <label
                            htmlFor="phone"
                            className="col-sm-2 col-form-label"
                          >
                            {t.Phone[lang]}
                          </label>
                          <div className="col-sm-10">
                            <input
                              name="phone"
                              id="phone"
                              value={this.state.phone}
                              placeholder={t.Phone_p[lang]}
                              type="text"
                              className="form-control"
                              disabled
                            />
                            <small className="form-error-mode">
                              {t.ONA[lang]}
                            </small>
                          </div>
                        </div>
                        {/* <div className="position-relative row form-group">
                                <label htmlFor="alt_phone" className="col-sm-2 col-form-label">{t.AlternativeNumber[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="alt_mobile" id="alt_mobile" onChange={this.onChange} value={(this.state.alt_mobile) ? this.state.alt_mobile : ''} placeholder={t.AlternativeNumber_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.ONA[lang]}</small>
                                </div>
                              </div>
                              <div className="position-relative row form-group">
                                <label htmlFor="office_number" className="col-sm-2 col-form-label">{t.OfficeNumber[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="office_number" id="office_number" onChange={this.onChange} value={this.state.office_number } placeholder={t.OfficeNumber_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.ONA[lang]}</small>  
                                </div>
                              </div>
                              <div className="position-relative row form-group">
                                <label htmlFor="fax_number" className="col-sm-2 col-form-label">{t.FaxNumber[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="fax" id="fax" onChange={this.onChange} value={this.state.fax} placeholder={t.FaxNumber_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.ONA[lang]}</small>
                                </div>
                              </div>
                              <div className="position-relative row form-group">
                                <label htmlFor="whatsapp_number" className="col-sm-2 col-form-label">{t.WhatsappNumber[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="whatsapp" id="whatsapp" onChange={this.onChange} value={this.state.whatsapp} placeholder={t.WhatsappNumber_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.ONA[lang]}</small>
                                </div>
                              </div>
                              <hr/>

                              <div className="position-relative row form-group">
                                  <label htmlFor="address" className="col-sm-2 col-form-label">{t.Address[lang]}</label>
                                  <div className="col-sm-10"><textarea name="address" id="address" className="form-control" onChange={this.onChange} value={this.state.address ? this.state.address : ''}></textarea></div>
                              </div>
                              <div className="position-relative row form-group">
                                <label htmlFor="website" className="col-sm-2 col-form-label">{t.Website[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="website" id="website" onChange={this.onChange} value={this.state.website}  placeholder={t.Website_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.VURL[lang]}</small> 
                                </div>
                              </div>
                              <div className="position-relative row form-group">
                                <label htmlFor="waze" className="col-sm-2 col-form-label">{t.Waze[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="waze" id="waze" onChange={this.onChange} value={ this.state.waze} placeholder={t.Waze_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.VURL[lang]}</small>
                                </div>
                              </div>
                              <div className="position-relative row form-group">
                                <label htmlFor="facebook" className="col-sm-2 col-form-label">{t.Facebook[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="facebook" id="facebook" onChange={this.onChange} value={ this.state.facebook } placeholder={t.FaxNumber_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.VURL[lang]}</small>
                                </div>
                              </div>
                              <div className="position-relative row form-group">
                                <label htmlFor="linkdin" className="col-sm-2 col-form-label">{t.Linkdin[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="linkdin" id="linkdin" onChange={this.onChange} value={ this.state.linkdin} placeholder={t.Linkdin_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.VURL[lang]}</small>
                                </div>
                              </div>
                              <div className="position-relative row form-group">
                                <label htmlFor="twitter" className="col-sm-2 col-form-label">{t.Twitter[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="twitter" id="twitter" onChange={this.onChange} value={ this.state.twitter} placeholder={t.Twitter_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.VURL[lang]}</small>
                                </div>
                              </div>
                              <div className="position-relative row form-group">
                                <label htmlFor="google_plus" className="col-sm-2 col-form-label">{t.GooglePlus[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="google_plus" id="google_plus" onChange={this.onChange} value={ this.state.google_plus } placeholder={t.GooglePlus_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.VURL[lang]}</small>
                                </div>
                              </div>
                              <div className="position-relative row form-group">
                                <label htmlFor="instagram" className="col-sm-2 col-form-label">{t.Instagram[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="instagram" id="instagram" onChange={this.onChange} value={ this.state.instagram } placeholder={t.Instagram_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.VURL[lang]}</small>
                                </div>
                              </div>
                              <div className="position-relative row form-group">
                                <label htmlFor="youtube" className="col-sm-2 col-form-label">{t.Youtube[lang]}</label>
                                <div className="col-sm-10">
                                  <input name="youtube" id="youtube" onChange={this.onChange} value={ this.state.youtube } placeholder={t.Youtube_p[lang]} type="text" className="form-control" />
                                  <small className="form-error-mode">{t.VURL[lang]}</small>
                                </div>
                              </div>
                              <hr/> */}
                        {/* <div className="position-relative row form-group">
                                <label htmlFor="avatar" className="col-sm-2 col-form-label">{t.Avatar[lang]}</label>
                                  <div className="col-sm-10" id="avatar-placeholder">
                                        <FileBase64
                                          multiple={false}
                                          className="form-control-file"
                                          onDone={this.getFiles.bind(this)}
                                        />
                                      <small className="form-text text-muted">{t.Avatar_p[lang]}</small>
                                  </div>
                              </div> */}
                        <hr />
                        <div className="position-relative row form-check">
                          <div className="col-sm-10 offset-sm-2">
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={this.editUser}
                            >
                              {t.Update[lang]}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* End profile widget */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
  };
};
const mapDispatchToProps = () => {
  return {
    get: (token, id) => actionCreater.getProfile(token, id),
    editUser: (token, id, data) => actionCreater.editUser(token, id, data),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
