import React, { Component } from "react";
import { Link } from "react-router-dom";
import alertify from "alertifyjs";
import { errorHandler } from "../../util/functions";
import $ from "jquery";
import APIModel from "../../Models/APIModel";
import axios from "axios";
class Forgotrenterpasword extends Component {
  state = {
    token: "",
    password: "",
    error: "",
    inProgress: false
  };

  componentWillMount() {
    if (this.props.user) {
      this.props.history.push("/");
    }

    let pageURL = window.location.href;
    let id = pageURL.substr(pageURL.lastIndexOf("/") + 1);
    this.setState({ token: id });
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  globalErrorHandler = error => {
    const { response } = error;
    let message;
    if (response) {
      message = response.data.message;
    } else {
      message = error.toString();
    }
    alertify.error(message);
  };

  resetpass = () => {
    const { password, token } = this.state;
    const params = { password, token };
    axios
      .post(APIModel.HOST + "forgot/enterforgotpass", params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then(res => {
        if (res.data.message == "Password Reset successfully") {
          alertify.success(res.data.message);
          this.props.history.push("/");
        }
        if (
          res.data.message ==
          "Link is expired please request for password again"
        ) {
          alertify.error(res.data.message);
        }
      })
      .catch(this.globalErrorHandler)
      .finally(() => {
        this.setState({
          inProgress: false
        });
      });
  };

  componentDidMount() {
    alertify.set("notifier", "position", "top-right");
  }

  render() {
    const { inProgress, password } = this.state;
    return (
      <div className="app-container">
        <div className="h-100 bg-premium-dark bg-animation">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <div className="mx-auto app-login-box col-md-8">
              <div className="app-logo-inverse mx-auto mb-3"></div>
              <div className="modal-dialog w-100 mx-auto">
                <div className="modal-content">
                  <div className="text-center bg-white pt-3">
                    <img src="assets/images/easy-card.png" alt="" />
                  </div>
                  <div className="modal-body">
                    <div className="h5 modal-title text-center">
                      <h4 className="mt-2">
                        <div>Recover Your Account,</div>
                        <span>Please Enter Your New Password.</span>
                      </h4>
                    </div>
                    <form className="">
                      <div className="form-row">
                        <div className="col-md-12">
                          <div className="position-relative form-group">
                            <input
                              id="inputPassword"
                              name="password"
                              onChange={this.onChange}
                              onKeyDown={this._handleKeyDown}
                              value={password}
                              placeholder="Enter New Password"
                              required
                              minLength="6"
                              type="password"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="divider"></div>
                    <h6 className="mb-0">
                      No account?{" "}
                      <Link to={"/register"} className="text-primary">
                        Sign up now
                      </Link>
                    </h6>
                  </div>
                  <div className="modal-footer clearfix">
                    <div className="float-right">
                      <button
                        className="btn btn-primary btn-lg"
                        value={inProgress ? "Please wait..." : "Login"}
                        disabled={inProgress}
                        onClick={this.resetpass}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center text-white opacity-8 mt-3">
                Copyright © Easy Card 2020
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Forgotrenterpasword;
