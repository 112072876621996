import APIModel from "../../Models/APIModel";
import axios from "axios";

export const getUsers = (token, search) => {
  return axios.get(APIModel.HOST + "users" + search, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};

export const getProfile = (token, id) => {
  return axios.get(APIModel.HOST + "user/profile/" + id, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};

export const userDetail = (token, id) => {
  return axios.get(APIModel.HOST + "admin/users/" + id, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};


export const createUser = (token, params) => {
  return axios.post(APIModel.HOST + "users", params, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};

export const editUser = (token, id, data) => {
  return axios.post(APIModel.HOST + "users/" + id, data, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};


export const deleteUser = (token, id) => {
  return axios.delete(APIModel.HOST + "users/" + id, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};

export const getPrices = () => {
  return axios.get(APIModel.HOST + "prices", {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  });
};

export const getTestimonials = () => {
  return axios.get(APIModel.HOST + "testimonials", {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  });
};
