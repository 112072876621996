const Validator = (params) => {
	var errors = [];
	//debugger;
	// if(params.email.length === 0 || params.email === ""){errors.push('#email-error')};
	if (params.leave_a_message.length > 0) {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(params.leave_a_message)) {
		} else {
			errors.push("#email-error");
		}
	}
	if (params.language.length === 0 || params.language === "") {
		errors.push("#language-error");
	}
	if (params.card_title.length === 0 || params.card_title === "") {
		errors.push("#card-title-error");
	}

	params.employees.map((answer, i) => {
		//debugger;
		if (answer.title === "" || answer.link === "") {
			errors.push("#employess_error");
		}
	});
	params.employees.map((answer, i) => {
		//debugger;
		if (answer.title === null || answer.link === null) {
			errors.push("#employess_error");
		}
	});
	params.articles.map((answers, i) => {
		//debugger;
		if ( answers.link === null || answers.title === null) {
			errors.push("#articles_error");
		}
	});

	params.our_stories.map((answer, i) => {
		if(answer == "")
		{
			errors.push("#stories_error");
		}
	});
	params.articles.map((answers, i) => {
		//debugger;
		if ( answers.link === "" || answers.title === "") {
			errors.push("#articles_error");
		}
	});


	return errors;
};

export default Validator;
